import { EmployerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';
import { APIClient } from '../types';
import { ContactSearchResponse } from 'common.model/src/types/common/contact/ContactSearchResponse';
import {
	GetPlanActiveParticipantsTrendRequest,
	GetFilingContactsRequest,
	GetEmployerFilingYearsRequest,
	AddSelectedContactToNewListRequest,
	GetEmployerSummaryRequest,
	GetEmployerInsightsRequest,
	GetEmployerBenefitFilingYearsRequest,
	AddSelectedToExistingListRequest,
	AddSelectedToExistingListResponse,
	AddSelectedContactToNewListResponse,
	SearchEmployerResult,
	EmployerSearchEmployerHqRequest,
	GetEmployerPoliciesSummaryRequest,
	GetEmployerPoliciesSummaryResponse,
	GetPoliciesInsuranceProductsRequest,
	GetPoliciesInsuranceProductsResponse,
	GetFormattedPoliciesRequest,
	GetFormattedPoliciesResponse,
	GetServiceProviderFeesResponse,
	GetServiceProviderFeesRequest,
	GetKeyPartnersResponse,
	GetKeyPartnersRequest,
	GetFilingYearsResponse,
	GetFilingYearsRequest,
} from 'common.model/src/types/services/employer-service/EmployerService';
import { IEmployerFilingContact } from 'common.model/src/types/services/employer-service/IEmployerFilingContact';
import { IHistoricalPlan } from 'common.model/src/types/services/employer-service/IHistoricalPlan';
import { ApiBase, IApiResponse, IMutationResponse } from '../ApiBase';
import { EmployerFilters } from 'common.model/src/types/common/search/filters/CompanyFilters';
import { EntityLocation } from 'common.model/src/types/master/location';
import { BreakdownResponse } from 'common.model/src/types/common/search/Breakdowns';
import { IEmployerSearch } from 'common.model/src/types/services/employer-service/IEmployerSearch';
import { GetContactRequest } from 'common.model/src/types/common/contact/GetContactRequest';
import { GetContactResponse } from 'common.model/src/types/common/contact/GetContactResponse';
import { BulkGetContactResponse } from 'common.model/src/types/common/contact/BulkGetContactResponse';
import { IEmployerInsights } from 'common.model/src/types/services/employer-service/IEmployerInsights';
import { MutationOptions, QueryObserverOptions, UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { BulkGetContactRequest } from 'common.model/src/types/common/contact/BulkGetContactRequest';
import { MatchCompanyWebsiteResponse } from 'common.model/src/types/common/company/CompanyWebsiteMatch';
import { MatchCompanyWebsiteRequest } from 'common.model/src/types/common/company/CompanyWebsiteMatch';

export interface IEmployerApi {
	useSearch: (req: EmployerFilters) => Promise<SearchEmployerResult>;
	useSearchEmployerHq: (req: EmployerSearchEmployerHqRequest) => IApiResponse<EntityLocation[]>;
	useSearchResultsBreakdown: (req: EmployerFilters) => IApiResponse<BreakdownResponse>;
	useGetEmployerSummary: (req: GetEmployerSummaryRequest) => IApiResponse<IEmployerSearch>;
	useGetEmployerInsights: (req: GetEmployerInsightsRequest, options?: QueryObserverOptions<IEmployerInsights>) => IApiResponse<IEmployerInsights>;
	useGetEmployerBenefitFilingYears: (req: GetEmployerBenefitFilingYearsRequest, options?: QueryObserverOptions<number[]>) => IApiResponse<number[]>;
	useGetEmployerFilingYears: (req: GetEmployerFilingYearsRequest, options?: QueryObserverOptions<string[]>) => IApiResponse<string[]>;
	useSearchContacts: (req: EmployerContactFilters) => Promise<ContactSearchResponse>;
	useSearchContactsBreakdown: (req: EmployerContactFilters) => IApiResponse<BreakdownResponse>;
	useGetContact: (req: GetContactRequest, options?: MutationOptions<GetContactResponse>) => IMutationResponse<GetContactResponse>;
	useGetBulkContact: (
		options?: UseMutationOptions<BulkGetContactResponse, unknown, BulkGetContactRequest<EmployerContactFilters>>,
	) => UseMutationResult<BulkGetContactResponse>;
	useAddSelectedToExistingList: (
		options: UseMutationOptions<AddSelectedToExistingListResponse, unknown, AddSelectedToExistingListRequest>,
	) => UseMutationResult<AddSelectedToExistingListResponse, unknown, AddSelectedToExistingListRequest>;
	useAddSelectedToNewList: (
		options: UseMutationOptions<AddSelectedContactToNewListResponse, unknown, AddSelectedContactToNewListRequest>,
	) => UseMutationResult<AddSelectedContactToNewListResponse, unknown, AddSelectedContactToNewListRequest>;
	useGetFilingContacts: (req: GetFilingContactsRequest, options?: QueryObserverOptions<IEmployerFilingContact[]>) => IApiResponse<IEmployerFilingContact[]>;
	useGetPlanActiveParticipantsTrend: (req: GetPlanActiveParticipantsTrendRequest) => IApiResponse<IHistoricalPlan[]>;
	useGetEmployerPoliciesSummary: <TParsed = GetEmployerPoliciesSummaryResponse>(
		req: GetEmployerPoliciesSummaryRequest,
		options?: UseQueryOptions<GetEmployerPoliciesSummaryResponse, unknown, TParsed>,
	) => UseQueryResult<TParsed, unknown>;
	useGetPoliciesInsuranceProducts: (req: GetPoliciesInsuranceProductsRequest) => IApiResponse<GetPoliciesInsuranceProductsResponse>;
	useGetFillingYears: <TParsed = GetFilingYearsResponse>(
		req: GetFilingYearsRequest,
		options?: UseQueryOptions<GetFilingYearsResponse, unknown, TParsed>,
	) => UseQueryResult<TParsed, unknown>;
	getFormattedPolicies: (req: GetFormattedPoliciesRequest) => Promise<GetFormattedPoliciesResponse>;
	getServiceProviderFees: (req: GetServiceProviderFeesRequest) => Promise<GetServiceProviderFeesResponse>;
	useGetKeyPartners: <TParsed = GetKeyPartnersResponse>(
		req: GetKeyPartnersRequest,
		options?: UseQueryOptions<GetKeyPartnersResponse, unknown, TParsed>,
	) => UseQueryResult<TParsed, unknown>;
	useMatchEmployerWebsites: <TParsed = MatchCompanyWebsiteResponse>(
		req: MatchCompanyWebsiteRequest,
		options: UseQueryOptions<MatchCompanyWebsiteResponse, unknown, TParsed>,
	) => UseQueryResult<TParsed, unknown>;
}

export class EmployerApi extends ApiBase implements IEmployerApi {
	constructor(
		readonly client: APIClient,
		loggedInUser: string,
	) {
		super(client, loggedInUser, 'employer');
	}

	useSearch = (req: EmployerFilters): Promise<SearchEmployerResult> => this.post('search', req);

	useSearchEmployerHq = (req: EmployerSearchEmployerHqRequest): IApiResponse<EntityLocation[]> => this.callAuthenticated('searchEmployerHq', req);

	useSearchResultsBreakdown = (req: EmployerFilters): IApiResponse<BreakdownResponse> => this.callAuthenticated('searchResultsBreakdown', req);

	/*
		//MISSING DATA FROM API
		number_of_contacts: string;
		employer_linkedin_url: string;
		employer_zip_code: string;
		employer_street_address: string;
		employee_sentiment_score: number;
	*/
	useGetEmployerSummary = (req: GetEmployerSummaryRequest): IApiResponse<IEmployerSearch> => this.callAuthenticated('getEmployerSummary', req);

	useGetEmployerInsights = (req: GetEmployerInsightsRequest, options?: QueryObserverOptions<IEmployerInsights>): IApiResponse<IEmployerInsights> =>
		this.callAuthenticated('getEmployerInsights', req, options);

	useGetEmployerBenefitFilingYears = (req: GetEmployerBenefitFilingYearsRequest, options?: QueryObserverOptions<number[]>): IApiResponse<number[]> =>
		this.callAuthenticated('getEmployerBenefitFilingYears', req, options);

	useSearchContacts = async (req: EmployerContactFilters): Promise<ContactSearchResponse> => this.post('searchContacts', req);

	useSearchContactsBreakdown = (req: EmployerContactFilters): IApiResponse<BreakdownResponse> => this.callAuthenticated('searchContactsBreakdown', req);

	useGetContact = (req: GetContactRequest, options?: MutationOptions<GetContactResponse>): IMutationResponse<GetContactResponse> =>
		this.mutateAuthenticated('getContact', req, options);

	useGetBulkContact = (
		options: UseMutationOptions<BulkGetContactResponse, unknown, BulkGetContactRequest<EmployerContactFilters>>,
	): UseMutationResult<BulkGetContactResponse, unknown> => this.mutate('getBulkContact', options);

	useAddSelectedToExistingList = (options: UseMutationOptions<AddSelectedToExistingListResponse, unknown, AddSelectedToExistingListRequest>) =>
		this.mutate<AddSelectedToExistingListResponse, AddSelectedToExistingListRequest>('addSelectedToExistingList', options);

	useAddSelectedToNewList = (options: UseMutationOptions<AddSelectedContactToNewListResponse, unknown, AddSelectedContactToNewListRequest>) =>
		this.mutate<AddSelectedContactToNewListResponse, AddSelectedContactToNewListRequest>('addSelectedToNewList', options);

	useGetFilingContacts = (req: GetFilingContactsRequest, options?: QueryObserverOptions<IEmployerFilingContact[]>): IApiResponse<IEmployerFilingContact[]> =>
		this.callAuthenticated('getFilingContacts', req, options);

	useGetPlanActiveParticipantsTrend = (req: GetPlanActiveParticipantsTrendRequest): IApiResponse<IHistoricalPlan[]> =>
		this.callAuthenticated('getPlanActiveParticipantsTrend', req);

	useGetEmployerFilingYears = (req: GetEmployerFilingYearsRequest, options?: QueryObserverOptions<string[]>): IApiResponse<string[]> =>
		this.callAuthenticated('getEmployerFilingYears', req, options);

	useGetEmployerPoliciesSummary = <TParsed = GetEmployerPoliciesSummaryResponse>(
		req: GetEmployerPoliciesSummaryRequest,
		options: UseQueryOptions<GetEmployerPoliciesSummaryResponse, unknown, TParsed>,
	) => this.query('getEmployerPoliciesSummary', req, options);

	useGetPoliciesInsuranceProducts = (req: GetPoliciesInsuranceProductsRequest): IApiResponse<GetPoliciesInsuranceProductsResponse> =>
		this.callAuthenticated('getPoliciesInsuranceProducts', req);

	useGetFormattedPolicies = <TParsed = GetFormattedPoliciesResponse>(
		req: GetFormattedPoliciesRequest,
		options: UseQueryOptions<GetFormattedPoliciesResponse, unknown, TParsed>,
	) => this.query('getFormattedPolicies', req, options);

	getFormattedPolicies = (req: GetFormattedPoliciesRequest): Promise<GetFormattedPoliciesResponse> => this.post('getFormattedPolicies', req);
	getServiceProviderFees = (req: GetServiceProviderFeesRequest): Promise<GetServiceProviderFeesResponse> => this.post('getServiceProviderFees', req);
	useGetFillingYears = <TParsed = GetFilingYearsResponse>(req: GetFilingYearsRequest, options: UseQueryOptions<GetFilingYearsResponse, unknown, TParsed>) =>
		this.query('getFillingYears', req, options);
	useGetKeyPartners = <TParsed = GetKeyPartnersResponse>(req: GetKeyPartnersRequest, options: UseQueryOptions<GetKeyPartnersResponse, unknown, TParsed>) =>
		this.query('getKeyPartners', req, options);
	useMatchEmployerWebsites = <TParsed = MatchCompanyWebsiteResponse>(
		req: MatchCompanyWebsiteRequest,
		options: UseQueryOptions<MatchCompanyWebsiteResponse, unknown, TParsed>,
	) => this.query('matchEmployerWebsites', req, options);
}
