import React from 'react';
import styled from 'styled-components';
import { Paper, SecondarySpanLarge, TerciarySpanLarge } from 'atoms/StyledComponents';
import { terciaryColor } from 'designTokens/colors';
import { Tab, Tabs } from 'molecules/Tabs';

const Header = styled.div`
	height: 77px;
	border-bottom: 0.4px solid #e8e8e8;
	padding-left: 22px;
	padding-right: 22px;
	line-height: 77px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Content = styled.div`
	padding-left: 22px;
	padding-right: 22px;
	padding-top: 37px;
`;

const Footer = styled.div`
	height: 40px;
`;

const ClearDiv = styled.div`
	&:hover {
		text-decoration: underline;
		color: ${() => terciaryColor};
		cursor: pointer;
	}
`;

export interface FilterBarTabProps {
	tabNames: string[];
	selectedTab: string;
	setSelectedTab: (tab: string) => void;
}

export interface FilterBarProps {
	onClear: () => void;
	children?: React.ReactNode;
	tabs?: FilterBarTabProps;
}

export const FilterBar = (props: FilterBarProps) => {
	return (
		<Paper style={{ minHeight: '600px' }}>
			{props.tabs ? (
				<div style={{ padding: '30px' }}>
					<Tabs value={props.tabs.tabNames.indexOf(props.tabs.selectedTab)} onChange={(e, v) => props.tabs.setSelectedTab(props.tabs.tabNames[v])}>
						{props.tabs.tabNames.map((tabName, index) => (
							<Tab key={tabName} label={tabName} selected={tabName == props.tabs.selectedTab} />
						))}
					</Tabs>
				</div>
			) : null}
			<Header>
				<SecondarySpanLarge style={{ fontWeight: 700 }}>Filter</SecondarySpanLarge>
				<ClearDiv onClick={props.onClear}>
					<TerciarySpanLarge style={{ marginRight: '6px' }}>Clear</TerciarySpanLarge>
				</ClearDiv>
			</Header>
			<Content>{props.children}</Content>
			<Footer />
		</Paper>
	);
};
