/**
 *
 * Asynchronously loads the component for ListsDetailPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ListsDetailPageController = lazyLoad(
	() => import('./index'),
	(module) => module.ListsDetailPageController,
);
