import React from 'react';
import ButtonMaterialUi from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import DeleteIcon from '@material-ui/icons/Delete';

export interface DeleteButtonProps {
	title?: string;
	count?: number;
	disabled?: boolean;
	loading?: boolean;
	onClick?: () => void;
}

const Button = styled(ButtonMaterialUi)`
	border-radius: 9px !important;
	height: 40px;
	padding: 0 18px !important;
	background-color: ${(props) => (props.disabled ? '#00000010' : 'transparent')} !important;
	border: ${(props) => (props.disabled ? '1px solid transparent' : '1px solid #ff2b2b')} !important;
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')} !important;
	box-shadow: none !important;

	.MuiButton-label {
		font-family: 'Roboto';
		font-size: 14px;
		color: ${(props) => (props.disabled ? '#00000055' : '#ff2b2b')};
		text-transform: capitalize;
		font-weight: 700;
	}
`;

const StyledCircularProgress = styled(CircularProgress)`
	width: 24px !important;
	height: 24px !important;
	color: #ff2b2b !important;
`;

export const DeleteButton = ({ title, count, disabled, loading, onClick, ...rest }: DeleteButtonProps) => {
	return (
		<Button
			variant="outlined"
			className="me-2"
			style={{ border: 'none' }}
			disabled={disabled}
			onClick={onClick}
			startIcon={<DeleteIcon style={!disabled ? { color: '#ff2b2b' } : {}} />}
			endIcon={loading ? <StyledCircularProgress /> : null}
			{...rest}
		>
			{title}
		</Button>
	);
};
