import { useParams } from 'react-router-dom';
import { useContext, useMemo } from 'react';
import { FormattingUtils } from 'ui-components';
import { APIContext } from 'new-beginning/services/api/Api.context';

export const useHeader = () => {
	const { employerId, employer_master_id } = useParams();
	const { employerApi } = useContext(APIContext);
	const query = employerApi.useGetEmployerSummary({ employer_master_id: employerId || employer_master_id });
	const name = useMemo<string | undefined>(
		() => query?.data?.employer_master_name && FormattingUtils.formatUpperCasing(query?.data.employer_master_name),
		[query.data],
	);
	return { loading: query.loading, name };
};
