import { useContext, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useAmplifyToken, TokenManager } from './useAmplifyToken';
import { LoggedInUserContext, UserContextType } from 'new-beginning/context/LoggedInUser';

interface BaseHeaders {
	Authorization: TokenManager['amplifyToken'];
	bf_login_as_tenant: string | null;
	'Content-Type': string | null;
}

export interface BaseApiReponse {
	loading: boolean;
	requestErrorMessage: string | null;
	requestApiSuccess: boolean | null;
}

export interface BasicApiRquestState extends BaseApiReponse {
	setLoading: Dispatch<SetStateAction<boolean>>;
	setRequestErrorMessage: Dispatch<SetStateAction<string | null>>;
	setApiRequestSuccess: Dispatch<SetStateAction<boolean | null>>;
	baseHeaders: BaseHeaders;
	tokenLoading: boolean;
}

export const useBasicApiRequestState = (defaultLoadingState: boolean = false): BasicApiRquestState => {
	const [loading, setLoading] = useState<boolean>(false);
	const [tokenRetries, setTokenRetries] = useState<number>(0);
	const [requestErrorMessage, setRequestErrorMessage] = useState<string | null>(null);
	const [requestApiSuccess, setApiRequestSuccess] = useState<boolean | null>(null);
	const { authUser }: UserContextType = useContext(LoggedInUserContext);

	const { amplifyToken, refreshToken, tokenLoading }: TokenManager = useAmplifyToken();
	const [baseHeaders, setBaseHeaders] = useState<BaseHeaders>({
		bf_login_as_tenant: authUser?.tenantIdOverride,
		'Content-Type': null,
		Authorization: amplifyToken,
	});

	useEffect(() => {
		if (amplifyToken) {
			setBaseHeaders({
				bf_login_as_tenant: authUser?.tenantIdOverride || null,
				'Content-Type': 'application/json',
				Authorization: amplifyToken,
			});
		}
	}, [amplifyToken, authUser?.tenantIdOverride]);

	useEffect(() => {
		if (amplifyToken && requestApiSuccess === false && !tokenLoading && tokenRetries <= 3) {
			setTokenRetries(tokenRetries + 1);
			refreshToken();
		}
	}, [tokenLoading, tokenRetries, requestApiSuccess, amplifyToken]);

	return {
		loading,
		tokenLoading,
		baseHeaders,
		setLoading,
		requestApiSuccess,
		setApiRequestSuccess,
		requestErrorMessage,
		setRequestErrorMessage,
	};
};
