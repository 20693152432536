export enum UserType {
	BENEFIT_ADMIN,
	TENANT_ADMIN,
	STANDARD_USER,
	TRIAL_USER,
}

export class UserTypeUtils {
	public static BENEFITADMIN = 'benefitflowAdmin';

	public static TENANTADMIN = 'clientAdmin';

	public static TRIAL = 'trial';

	public static ENTERPRISE = 'enterprise';

	public static UserTypes = [UserTypeUtils.BENEFITADMIN, UserTypeUtils.TENANTADMIN, UserTypeUtils.TRIAL, UserTypeUtils.ENTERPRISE];

	public static AUTOGENERATED_GROUP = 'Autogenerated group';

	public static parseUserType(groups: string[] = []) {
		if (groups.includes(UserTypeUtils.BENEFITADMIN)) {
			return UserType.BENEFIT_ADMIN;
		} else if (groups.includes(UserTypeUtils.TENANTADMIN)) {
			return UserType.TENANT_ADMIN;
		} else if (groups.includes(UserTypeUtils.TRIAL)) {
			return UserType.TRIAL_USER;
		} else {
			return UserType.STANDARD_USER;
		}
	}

	public static getUserTypeName(userType: UserType) {
		return UserType[userType];
	}

	public static getTenantId(groups: string[] = []) {
		if (groups.length === 0) {
			return null;
		}

		var tenants = groups?.filter((x) => {
			return (
				x !== UserTypeUtils.BENEFITADMIN && x !== UserTypeUtils.TENANTADMIN && x !== UserTypeUtils.TRIAL && !x.startsWith(UserTypeUtils.AUTOGENERATED_GROUP)
			);
		});
		if (tenants.length > 1) {
			var msg = `Cant have more than 1 client group`;
			console.log(msg);
			throw msg;
		}
		return tenants[0];
	}
}
