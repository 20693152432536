import React from 'react';
import { Row, Col } from 'react-bootstrap';
import BackButton from 'atoms/Buttons/BackButton';
import { HeaderSpan } from 'atoms/StyledComponents';
import { Box } from '@benefitflow/designsystem';

interface Props {
	title: string;
	onBack: () => void;
	filterBar: React.ReactElement;
	summaryTile: React.ReactElement;
	mainContent: React.ReactElement;
}

const Component = ({ title, onBack, filterBar, summaryTile, mainContent }: Props) => {
	return (
		<Box mx="md" mt="lg">
			<Row style={{ marginBottom: '15px' }}>
				<Col lg={4} xl={3}>
					<div style={{ paddingBottom: '10px' }}>
						<BackButton onBack={onBack} />
					</div>
					{filterBar}
				</Col>

				<Col lg={8} xl={9} style={{ paddingRight: '0' }}>
					<HeaderSpan>{title}</HeaderSpan>
					<div style={{ marginTop: '10px', marginBottom: '20px' }}>{summaryTile}</div>

					{mainContent}
				</Col>
			</Row>
		</Box>
	);
};

export default Component;
