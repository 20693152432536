import { useCallback } from 'react';

import { mask } from '../logic/mask';

export const useOnSearch = ({ setSearch, search: current }: { search: string; setSearch: (search: string) => void }) => {
	const onSearch = useCallback(
		(search) => {
			setSearch(mask(search, current));
		},
		[current, setSearch],
	);

	return onSearch;
};
