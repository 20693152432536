import {
	CheckExportStatusRequest,
	CheckExportStatusResponse,
	CreateExportRequest,
	CreateExportResponse,
	DeleteTenantRequest,
	DownloadExportRequest,
} from 'common.model/src/types/services/data-export-service/DataExportService';
import { ApiBase, IApiResponse, IMutationResponse } from '../ApiBase';
import { APIClient } from '../types';
import { MutationOptions, QueryObserverOptions } from '@tanstack/react-query';
import ExportType from 'common.model/src/types/services/data-export-service/ExportType';

export interface IDataExportApi {
	useDeleteTenant: (req: DeleteTenantRequest, options?: MutationOptions<void>) => IMutationResponse<void>;
	useCreateExport: (
		{ exportParams, exportType }: CreateExportRequest,
		options?: MutationOptions<CreateExportResponse>,
	) => IMutationResponse<CreateExportResponse>;
	useCheckExportStatus: (
		{ exportId }: CheckExportStatusRequest,
		options?: QueryObserverOptions<CheckExportStatusResponse>,
	) => IApiResponse<CheckExportStatusResponse>;
	useDownloadExport: ({ exportId }: DownloadExportRequest, options?: QueryObserverOptions<string>) => IApiResponse<string>;
	useGetDataExportAccess: (options?: QueryObserverOptions<ExportType[]>) => IApiResponse<ExportType[]>;
}

export class DataExportApi extends ApiBase implements IDataExportApi {
	constructor(
		readonly client: APIClient,
		loggedInUser: string,
	) {
		super(client, loggedInUser, 'data-export');
	}

	useDeleteTenant = (req: DeleteTenantRequest, options?: MutationOptions<void>): IMutationResponse<void> =>
		this.mutateAuthenticated('deleteTenant', req, options);

	useCreateExport = (
		{ exportParams, exportType }: CreateExportRequest,
		options?: MutationOptions<CreateExportResponse>,
	): IMutationResponse<CreateExportResponse> => this.mutateAuthenticated('createExport', { exportType, exportParams }, options);

	useCheckExportStatus = (
		{ exportId }: CheckExportStatusRequest,
		options?: QueryObserverOptions<CheckExportStatusResponse>,
	): IApiResponse<CheckExportStatusResponse> => this.callAuthenticated('checkExportStatus', { exportId }, options);

	useDownloadExport = ({ exportId }: DownloadExportRequest, options?: QueryObserverOptions<string>): IApiResponse<string> =>
		this.callAuthenticated('downloadExport', { exportId }, options);

	useGetDataExportAccess = (options?: QueryObserverOptions<ExportType[]>): IApiResponse<ExportType[]> =>
		this.callAuthenticated('getDataExportAccess', {}, options);
}
