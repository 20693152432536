import { useContext, useMemo } from 'react';

import { ListDetailsContext } from '../../../ListDetails.context';

export const useOptions = ({ search }: { search: string }) => {
	const {
		options: { jobTitles },
	} = useContext(ListDetailsContext);
	const options = useMemo(
		() =>
			jobTitles
				?.filter((data) => data.formattedOption.toLowerCase().includes(search.toLowerCase()))
				.map((data) => ({ label: data.formattedOption, value: data.option, data })) ?? [],
		[jobTitles, search],
	);

	return options;
};
