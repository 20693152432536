import { ReactElement, useState } from 'react';
import classnames from 'classnames';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { useTenantTeamInfo, TenantTeamResponse, TenantUser } from 'new-beginning/hooks/useTenantTeamInfo';
import { TeamHeader } from './TeamHeader';
import { UserTable } from './UserTable';
import { ConfirmDeleteUserModal } from './ConfirmDeleteUserModal';
import { InviteUserModalProps } from './InviteUserModal';
import { EditUserAttributesModal } from './EditUserAttributesModal';

// TODO: This Route/Page needs to be accessible by Tenant Admins beyond BF
// TODO: This component needs to listen to the current Tenant for BF Admin Users
// TODO: This component needs to Validate/block certain operations (i.e delete only for admin, can't delete yourself.)

export const TeamView = (): ReactElement => {
	const { loading: teamDataLoading, tenantTeamInfo, refetchTeamData }: TenantTeamResponse = useTenantTeamInfo();
	const [showDataLoader, setShowLoader] = useState<boolean>(false);

	// State for adding, editing, and deleting Users
	const [showInviteUser, setShowInviteUser] = useState<boolean>(false);
	const [userToEdit, setUserToEdit] = useState<TenantUser | null>(null);
	const [userToDelete, stageUserToDelete] = useState<TenantUser | null>(null);

	const refetchAfterSave = () => {
		setShowLoader(true);
		refetchTeamData();
	};
	// TODO: Handle display of Error states for all Hooks
	return (
		<>
			<div className={classnames('page-container', 'team-page-container')}>
				<div className={classnames('h-75', 'w-100', 'mx-0')}>
					<TeamHeader showAddUser={() => setShowInviteUser(true)} tenantTeam={tenantTeamInfo} />
					<UserTable tenantUsers={tenantTeamInfo?.users} setUserToEdit={setUserToEdit} setUserToDelete={stageUserToDelete} loading={teamDataLoading} />
				</div>
			</div>
			<InviteUserModalProps isVisible={showInviteUser} cancelInvite={() => setShowInviteUser(false)} refetchData={refetchAfterSave} />
			<EditUserAttributesModal
				isVisible={userToEdit !== null}
				userToUpdate={userToEdit}
				cancelEdits={() => setUserToEdit(null)}
				refetchData={refetchAfterSave}
			/>
			<ConfirmDeleteUserModal
				isVisible={userToDelete !== null}
				userToDelete={userToDelete}
				cancelDeletion={() => stageUserToDelete(null)}
				refetchData={refetchAfterSave}
			/>
			<Snackbar open={showDataLoader} autoHideDuration={850} onClose={() => setShowLoader(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
				<SnackbarContent
					message={
						<div className={classnames('d-flex', 'align-items-center')}>
							<div className="text-primary">
								<span className={classnames('spinner-border', 'spinner-border-md', 'mx-2')} />
							</div>
							<div className="mx-3">Refreshing Team Data</div>
						</div>
					}
				/>
			</Snackbar>
		</>
	);
};
