import { useMemo, useState } from 'react';
import { Avatar, Box, Typography } from '@benefitflow/designsystem';

import { TextOverflowTooltip } from '../../../../../../../../../../shared/TextOverflowTooltip';

export const Clickable = ({ imageSrc, label, onClick }: { imageSrc?: string; label: string; onClick?: () => void }) => {
	const textColor = useMemo(() => (onClick ? ('primary-500' as const) : ('neutral-700' as const)), [onClick]);
	const cursor = useMemo(() => (onClick ? ('pointer' as const) : undefined), [onClick]);
	const [hovered, setHovered] = useState(false);

	return (
		<Box
			cursor={cursor}
			onMouseOut={() => setHovered(false)}
			onMouseOver={() => setHovered(true)}
			display="flex"
			onClick={onClick}
			gap="sm"
			alignItems="center"
		>
			<Avatar size="small" src={imageSrc} fallbackIcon="business" variant="square" />
			<TextOverflowTooltip label={label}>
				{(overflowed) => (
					<Typography.Text
						ellipsedCallback={overflowed}
						whiteSpace="nowrap"
						ellipsis
						decoration={hovered && onClick ? 'underline' : undefined}
						decorationColor="primary-500"
						color={textColor}
					>
						{label}
					</Typography.Text>
				)}
			</TextOverflowTooltip>
		</Box>
	);
};
