import './style.scss';
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { SlideDown } from 'react-slidedown';
import { ChevronUp, ChevronDown } from 'react-iconly';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Document } from 'react-iconly';
import Skeleton from '@material-ui/lab/Skeleton';
import { terciaryColor } from 'designTokens/colors';
import { SecondarySpanMedium } from 'atoms/StyledComponents';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		link_hover: {
			'&:hover': {
				textDecoration: 'underline !important',
				color: terciaryColor,
				cursor: 'pointer',
			},
		},
	}),
);

export interface ListDetailSummaryCardProps {
	className?: any;
	listName?: string;
	description?: string;
	numContacts?: number;
}

export const ListDetailSummaryCard = ({ className, listName, description, numContacts }: ListDetailSummaryCardProps) => {
	const classes = useStyles();
	const [showBrokerInfo, setShowBrokerInfo] = useState(false);
	const handleCloseBrokerInfo = () => setShowBrokerInfo(false);
	const handleShowBrokerInfo = () => setShowBrokerInfo(true);

	return (
		<div className={`brocker-card ${className}`} style={{ padding: '25px' }}>
			<Row className="align-items-center">
				<Col sm={2}>
					<div className="card-img">{<Document set="light" primaryColor="lightgrey" size={75} />}</div>
				</Col>
				<Col sm={10}>
					{/* <Col sm={10}> */}
					<div className="card-detail">
						<div>
							{!listName ? <Skeleton style={{ display: 'inline-block' }} variant="rect" width={300} /> : <h3 className="bd-name">{listName}</h3>}
							{/* <span className="bd-address">{description}</span> */}
						</div>
						<div className="broker-stats">
							{!numContacts ? (
								<Skeleton style={{ display: 'inline-block' }} variant="rect" width={100} />
							) : (
								<SecondarySpanMedium style={{ fontWeight: 700 }}>{numContacts} Contacts</SecondarySpanMedium>
							)}
							{/* <span style={{ marginTop: "7px" }}>{numOffices} Office Locations</span> */}
							{/* <span>{totalClients} Clients</span> */}
							{/* <span>{numContacts} Contacts</span> */}
						</div>
					</div>
					<div style={{ paddingTop: '15px' }}>
						{showBrokerInfo ? (
							<a onClick={handleCloseBrokerInfo} style={{ fontWeight: 300 }}>
								See more
								<ChevronUp set="light" style={{ marginLeft: '2px' }} size={20} />
							</a>
						) : (
							<a onClick={handleShowBrokerInfo} style={{ fontWeight: 300 }}>
								See more
								<ChevronDown set="light" style={{ marginLeft: '2px' }} size={20} />
							</a>
						)}
					</div>
				</Col>
			</Row>
			<Row>
				<Col sm={2} />
				<Col sm={10}>
					<div className="c-content">
						<SlideDown className={'my-dropdown-slidedown'} style={{ overflow: 'hidden' }}>
							{showBrokerInfo ? (
								<div style={{ marginTop: '20px' }}>
									<p className="panel-desc" style={{ marginTop: '20px', whiteSpace: 'pre-line' }}>
										{description}
									</p>
								</div>
							) : null}
						</SlideDown>
					</div>
				</Col>
			</Row>
		</div>
	);
};
