import React from 'react';
import { EmployeeSentimentTab } from 'new-beginning/components/pages/EmployerProfile/Tabs/components/EmployeeSentimentTab/EmployeeSentimentTab';
import { Summary } from 'new-beginning/components/pages/EmployerProfile/Summary/Summary';
// import { BenefitsTab } from '../BenefitsTab';
import { HistoryTab } from '../HistoryTab';
import { Box, Typography } from '@benefitflow/designsystem';
import { EmployerContactCardList } from '../ContactsTab/organisms/EmployerContactCardList';
import { logo } from 'assets/index';
import { Benefits } from '../../../../../new-beginning/components/pages/EmployerProfile/Tabs/components/Benefits/Benefits';

export interface EmployerExportPageProps {
	employerMasterId: string;
}

export const EmployerExportPage = (props: EmployerExportPageProps) => {
	return (
		<Box>
			<div style={{ margin: '1rem', textAlign: 'center' }}>
				<img src={logo} style={{ height: '24px', display: 'inline-block' }} />
			</div>
			<Summary />
			<div style={{ position: 'relative', margin: '1rem' }}>
				<div style={{ paddingTop: '32px' }}>
					<Typography.Display size="sm" style={{ paddingBottom: '16px' }} color="neutral-700">
						Benefits
					</Typography.Display>
					<Benefits />
					<div className="page-break" />

					<Typography.Display size="sm" style={{ paddingBottom: '16px' }} color="neutral-700">
						History
					</Typography.Display>
					<HistoryTab employerMasterId={props.employerMasterId} />
					<div className="page-break" />

					<Typography.Display size="sm" style={{ paddingBottom: '16px' }} color="neutral-700">
						Employee Sentiment
					</Typography.Display>
					<EmployeeSentimentTab />
					<div className="page-break" />

					<Typography.Display size="sm" style={{ paddingBottom: '16px' }} color="neutral-700">
						Contacts
					</Typography.Display>
					<EmployerContactCardList employerMasterId={props.employerMasterId} />
				</div>
			</div>
		</Box>
	);
};
