import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';

export const useValues = () => {
	const {
		contactFilters: { jobTitles },
	} = useSelector(selectListsDetailPage);

	const values = useMemo(
		() => [
			...jobTitles.include.map((companyName) => ({ value: companyName, label: companyName, type: 'include' as const })),
			...jobTitles.exclude.map((companyName) => ({ value: companyName, label: companyName, type: 'exclude' as const })),
		],
		[jobTitles],
	);

	return values;
};
