import { useMemo } from 'react';
import { Box, Card, Table } from '@benefitflow/designsystem';

import { Years } from '../Years/Years';
import { useServiceProviders } from './hooks';
import { ServiceProvidersTable } from './components';
import { useEmployerProfileBenefitsTabStore } from '../../Benefits.store';

export const ServiceProviders = () => {
	const health = useServiceProviders('health');
	const retirement = useServiceProviders('retirement');
	const { year } = useEmployerProfileBenefitsTabStore();
	const isEmpty = useMemo(() => !health.isLoading && !retirement.isLoading && !health?.data?.length && !retirement?.data?.length, [health, retirement]);
	const empty = useMemo(
		() => <Table.Empty style={{ height: '20rem' }} showBorder icon="duotone-building-magnifying-glass" label={`Data not reported in ${year.value} filings`} />,
		[year.value],
	);

	return (
		<Card title="Service Providers" extra={<Years />} defaultPadding={false}>
			<Box display="flex" gap="lg" m="lg">
				{isEmpty ? (
					empty
				) : (
					<>
						<ServiceProvidersTable title="Health Plan" {...health} />
						<ServiceProvidersTable title="Retirement Plan" {...retirement} />
					</>
				)}
			</Box>
		</Card>
	);
};
