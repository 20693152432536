import React from 'react';

export default class FormattingUtils {
	private static THOUSAND = 1000;

	private static MILLION = FormattingUtils.THOUSAND * FormattingUtils.THOUSAND;

	private static BILLION = FormattingUtils.THOUSAND * FormattingUtils.MILLION;

	public static formatFinancial(rawNum) {
		const isNegative = rawNum < 0;
		const num = isNegative ? rawNum * -1 : rawNum;
		const withCommas = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		return isNegative ? <span style={{ color: '#FF3D68' }}>(${withCommas})</span> : '$' + withCommas;
	}

	public static formatAbbrevFinancialThousands(rawNum) {
		const rawNumTwo = parseFloat(rawNum);
		if (isNaN(rawNumTwo)) {
			return '-';
		}
		const isNegative = rawNum < 0;
		const num = isNegative ? rawNum * -1 : rawNum;
		const rounded = FormattingUtils.roundToPlaces(num / FormattingUtils.THOUSAND, 0);
		const formatted = rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		return isNegative ? <span style={{ color: '#FF3D68' }}>(${formatted})</span> : '$' + formatted;
	}

	public static formatPercentFinancial(rawNum) {
		const rawNumTwo = parseFloat(rawNum);
		if (isNaN(rawNumTwo)) {
			return '-';
		}
		const isNegative = rawNumTwo < 0;
		const num = isNegative ? rawNumTwo * -1 : rawNumTwo;
		const formatted = FormattingUtils.roundToPlaces(num * 100, 1).toString() + '%';
		return isNegative ? <span style={{ color: '#FF3D68' }}>({formatted})</span> : formatted;
	}

	public static formatNumberThousands(rawNum, decimalPlaces = 1) {
		const num = parseInt(rawNum);
		if (isNaN(num)) {
			return 0;
		}
		return FormattingUtils.roundToPlaces(num / FormattingUtils.THOUSAND, decimalPlaces).toString() + 'K';
	}

	public static formatNumberMillions(rawNum, decimalPlaces = 1) {
		const num = parseInt(rawNum);
		if (isNaN(num)) {
			return 0;
		}
		return FormattingUtils.roundToPlaces(num / FormattingUtils.MILLION, decimalPlaces).toString() + 'M';
	}

	public static formatNumberAbbrev(rawNum, decimalPlaces = 1, addDecimal = true, prefix = '') {
		const num = parseInt(rawNum);
		if (isNaN(num)) {
			return 0;
		}
		let formattedNum = '';
		let suffix = '';
		let isNegative = num < 0;
		const nonNegativeNum = isNegative ? num * -1 : num;
		if (nonNegativeNum >= FormattingUtils.BILLION) {
			formattedNum = FormattingUtils.roundToPlaces(nonNegativeNum / FormattingUtils.BILLION, decimalPlaces).toString();
			suffix = 'B';
		} else if (nonNegativeNum >= FormattingUtils.MILLION) {
			formattedNum = FormattingUtils.roundToPlaces(nonNegativeNum / FormattingUtils.MILLION, decimalPlaces).toString();
			suffix = 'M';
		} else if (nonNegativeNum >= FormattingUtils.THOUSAND) {
			formattedNum = FormattingUtils.roundToPlaces(nonNegativeNum / FormattingUtils.THOUSAND, decimalPlaces).toString();
			suffix = 'K';
		} else {
			return (isNegative ? '-' : '') + prefix + nonNegativeNum;
		}

		if (formattedNum.split('.').length == 1 && addDecimal) {
			formattedNum = formattedNum + '.0';
		}
		return (isNegative ? '-' : '') + prefix + formattedNum + suffix;
	}

	public static formatPercent(rawNum) {
		const num = parseFloat(rawNum);
		if (isNaN(num)) {
			return 0;
		}
		return FormattingUtils.roundToPlaces(num * 100, 1).toString() + '%';
	}

	private static roundToPlaces(num, places) {
		return Math.round(num * Math.pow(10, places)) / Math.pow(10, places);
	}

	public static formatUpperCasing(str) {
		if (!str || str.length < 2) {
			return str;
		}
		return str
			.split(' ')
			.map((x) => (x ? x[0]?.toUpperCase() + x.slice(1)?.toLowerCase() : x))
			.join(' ');
	}

	public static capitalizeFirstLetter(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	public static formatCommas(rawNum) {
		const num = parseInt(rawNum);
		if (isNaN(num)) {
			return rawNum;
		}
		return num.toLocaleString('en-US');
	}

	public static formatYYYY_MM_DDtoMM_DD(YYYY_MM_DD: string) {
		const [year, month, day] = YYYY_MM_DD.split('-');
		const monthName = new Date(`${month}/${day}/${year}`).toLocaleString('default', { month: 'short' });

		return `${monthName}-${day}`;
	}

	public static formatMM_DD_YYYYtoMM_DD(MM_DD_YYYY: string) {
		const [monthNumber, day, year] = MM_DD_YYYY.split('/');
		const month = new Date(MM_DD_YYYY).toLocaleString('default', { month: 'short' });

		return `${month}-${day}`;
	}
}
