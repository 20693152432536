import React from 'react';

import { Chart as ChartJS, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import './style.scss';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export interface BarsChartProps {
	className?: any;
	title?: string;
	data?: any;
	loading?: boolean;
}

const SampleData = {
	datasets: [
		{
			label: '',
			data: [0, 33966, 34935, 36045, 37651, 38561, 0, 0],
		},
		{
			label: '',
			data: [0, 0, 0, 0, 0, 0, 39768, 40707],
		},
		{
			label: '',
			data: [958, 2137, 2181, 2258, 2348, 2415, 2481, 3361],
		},
		{
			label: '',
			data: [15961, 0, 0, 0, 0, 0, 0, 0],
		},
		{
			label: '',
			data: [0, 0, 0, 0, 0, 0, 0, 0],
		},
		{
			label: '',
			data: [0, 0, 0, 0, 0, 0, 0, 0],
		},
	],
	labels: ['2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020'],
};

export const BarsChart = ({ className, title, data, loading }: BarsChartProps) => {
	return (
		<div className={`bars-chart-bloc ${className}`}>
			<div className={`chart-content ${loading ? 'loading' : ''}`}>
				<Bar
					data={loading ? SampleData : data}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						plugins: {
							legend: {
								display: true,
								position: 'right',
							},
							title: {
								display: false,
							},
							tooltip: {
								enabled: !loading,
							},
						},
						scales: {
							x: {
								stacked: true,
								ticks: {
									display: true,
								},
								grid: {
									display: false,
								},
							},
							y: {
								stacked: true,
								ticks: {
									color: '#AFBDCF',
								},
							},
						},
					}}
				/>
			</div>
		</div>
	);
};
