enum ExportStatus {
	Accepted = 'ACCEPTED',
	InProgress = 'IN_PROGRESS',
	Complete = 'COMPLETE',
	Downloaded = 'DOWNLOADED',
	Failed = 'FAILED',
	Unknown = 'UNKNOWN',
}

export default ExportStatus;
