import { Box, Typography } from '@benefitflow/designsystem';

export const Empty = () => {
	return (
		<Box display="flex" justifyContent="start" alignItems="center">
			<Box height="xl" />
			<i>
				<Typography.Text size="md" color="neutral-400" weight="regular">
					Data not reported
				</Typography.Text>
			</i>
		</Box>
	);
};
