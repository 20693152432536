import { useState } from 'react';
import classnames from 'classnames';
import { BaseModal } from 'new-beginning/components/shared/Modals/BaseModal';
import { useInviteUser, InviteUserResponse } from 'new-beginning/hooks/useInviteUser';

interface InviteUserModalProps {
	cancelInvite: () => void;
	isVisible: boolean;
	refetchData: () => void;
}

export interface NewUser {
	email: string;
	fullName: string;
	permissionLevel: '' | 'admin' | 'trial' | 'standard';
}
type FormKey = keyof NewUser;

export const InviteUserModalProps = ({ cancelInvite, refetchData, isVisible }: InviteUserModalProps) => {
	const [newUser, setNewUser] = useState<NewUser>({
		email: '',
		fullName: '',
		permissionLevel: '',
	});
	const setFormValue = (key: FormKey, value: string) => {
		setNewUser({
			...newUser,
			[key]: value,
		});
	};

	const unsetUser = () => setNewUser({ email: '', fullName: '', permissionLevel: '' });
	const cancelUnset = () => {
		unsetUser();
		cancelInvite();
	};

	const { loading, requestErrorMessage, requestApiSuccess, addUserToTeam }: InviteUserResponse = useInviteUser();
	const submitInvite = () => {
		return addUserToTeam(newUser)
			.then((res) => {
				refetchData();
				cancelUnset();
			})
			.catch((err) => {
				console.error('Error Creating: ', err);
			})
			.finally(() => {
				unsetUser();
			});
	};
	const disabledSubmit = !(newUser.email && newUser.fullName && newUser.permissionLevel) || loading;

	// TODO: Add CreditAllocation Field to this From and the backend /user/createUser method
	return (
		<BaseModal isVisible={isVisible} headerText="Invite teammates" cancelAction={cancelUnset} className="base-form-modal">
			<div className={classnames('primary-content', 'd-flex', 'flex-column', 'justify-content-even', 'align-items-start', 'px-2', 'm-3')}>
				<div className={classnames('text-center', 'text-wrap', 'invite-subtext')}>
					<p>We'll email new members instructions and a link to join this account.</p>
				</div>
				<div className={classnames('modal-form-container', 'w-100', 'd-flex', 'flex-column', 'my-4', 'ms-4')}>
					<div className={classnames('input-group', 'username', 'd-flex', 'flex-column')}>
						<label htmlFor="userName">Full Name</label>
						<input type="test" name="userName" id="userName" value={newUser.fullName} onChange={(e) => setFormValue('fullName', e?.target?.value)} />
					</div>
					<div className={classnames('input-group', 'email', 'd-flex', 'flex-column')}>
						<label htmlFor="userEmail">Email</label>
						<input type="email" name="userEmail" id="userEmail" value={newUser.email} onChange={(e) => setFormValue('email', e?.target?.value)} />
					</div>
					<div className={classnames('input-group', 'permissions', 'd-flex', 'flex-column')}>
						<label htmlFor="permissionLevel">Role</label>
						<div className="dropdown-container">
							<select
								name="permissionLevel"
								id="permissionLevel"
								value={newUser.permissionLevel}
								onChange={(e) => setFormValue('permissionLevel', e?.target?.value)}
							>
								<option value="">Choose one</option>
								<option value="standard">Standard User</option>
								<option value="admin">Admin</option>
								<option value="trial">Trial</option>
							</select>
						</div>
					</div>
				</div>
				<div className={classnames('button-container', 'w-100', 'd-flex', 'justify-content-end', 'mt-2', 'mb-3')}>
					<button type="button" onClick={cancelUnset} disabled={loading} className={classnames('btn', 'btn-lg', 'btn-outline-bf-blue', 'mx-2')}>
						<span>Cancel</span>
					</button>
					<button
						type="button"
						onClick={submitInvite}
						disabled={disabledSubmit}
						className={classnames('btn', ' btn-lg', 'btn-primary', 'mx-2', { disabled: disabledSubmit })}
					>
						{!loading && <span>Send Invites</span>}
						{loading && (
							<span>
								<span className={classnames('spinner-border', 'spinner-border-sm')} />
								<span className={classnames('ms-2')}>Inviting</span>
							</span>
						)}
					</button>
				</div>
			</div>
		</BaseModal>
	);
};
