import { useCallback, useContext, useMemo } from 'react';

import { ContactDTO } from '../../../../../../queries';
import { ContactUtils } from '../../../../../../utilities';
import { useContactsListStore } from '../../../ContactsList.store';
import { BrokerContactCardV2 } from '../../../../Search/BrokerContactCardV2/BrokerContactCardV2';
import { useOnPurchaseContactSuccess } from '../../../../../../hooks/useOnPurchaseContactSuccess';
import { EmployerContactCardV2 } from '../../../../Search/EmployerContactCardV2/EmployerContactCardV2';
import { onBrokerClickCallback } from '../../../../../../utilities/ContactUtils/onBrokerClickCallback';
import { onEmployerClickCallback } from '../../../../../../utilities/ContactUtils/onEmployerClickCallback';
import { APIContext } from 'new-beginning/services/api/Api.context';
import { GetContactResponse } from 'common.model/src/types/common/contact/GetContactResponse';

export const Row = ({ contact }: { contact: ContactDTO }) => {
	const { brokerContactApi, employerApi } = useContext(APIContext);
	const { checkedMap, toggleCheckMap, tab, addPurchasedContact } = useContactsListStore();
	const checked = useMemo(() => checkedMap[contact.id] ?? false, [checkedMap, contact]);
	const toggle = useCallback(() => toggleCheckMap(contact.id), [toggleCheckMap, contact]);
	const isPurchasable = useMemo(() => ContactUtils.isContactPurchasable(contact), [contact]);
	const isPurchased = useMemo(() => ContactUtils.isContactPurchased(contact), [contact]);
	const onSuccess = useOnPurchaseContactSuccess({
		callback: (data: GetContactResponse) => {
			addPurchasedContact({
				id: data?.res?.profile_url,
				email: data?.res?.work_email,
				phone: data?.res?.mobile_phone_1,
			});
		},
	});

	const { mutate: onGetBrokerContactMutate, loading: brokerContactLoading } = brokerContactApi.useGetContact(
		{ profile_url: contact.linkedinUrl },
		{
			onSuccess,
		},
	);

	const { mutate: onGetEmployerContactMutate, loading: employerContactLoading } = employerApi.useGetContact(
		{ profile_url: contact.linkedinUrl },
		{
			onSuccess,
		},
	);

	const onBrokerClick = useCallback(() => {
		onBrokerClickCallback(contact);
	}, [contact]);
	const onEmployerClick = useCallback(() => {
		onEmployerClickCallback(contact);
	}, [contact]);

	return tab === 'broker' ? (
		<BrokerContactCardV2
			purchasable={isPurchasable}
			purchased={isPurchased}
			gettingContact={brokerContactLoading}
			onGetContact={() => onGetBrokerContactMutate()}
			onBrokerClick={onBrokerClick}
			checked={checked}
			toggle={toggle}
			variant="search"
			{...contact}
		/>
	) : (
		<EmployerContactCardV2
			{...contact}
			purchasable={isPurchasable}
			purchased={isPurchased}
			gettingContact={employerContactLoading}
			onEmployerClick={onEmployerClick}
			onGetContact={() => onGetEmployerContactMutate()}
			checked={checked}
			toggle={toggle}
			variant="search"
		/>
	);
};
