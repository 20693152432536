import React, { useContext } from 'react';
import Title from 'atoms/Title';
import { BarsChart } from 'molecules/charts/BarsChart';
import { MultipleLineChart } from 'molecules/charts/MultipleLineChart';
import { APIContext } from 'new-beginning/services/api/Api.context';
import EmployerDataUtils from 'app/containers/EmployerPage/EmployerDataUtils';
import ParseUtils from 'utils/ParseUtils';
// import './style.scss';

export interface HistoryTabProps {
	employerMasterId: string;
}

export const HistoryTab = (props: HistoryTabProps) => {
	const { brokerApi, employerApi } = useContext(APIContext);
	const historicalEmployerPolicies = brokerApi?.useGetHistoricalEmployerPolicies({ employer_master_id: props.employerMasterId });
	const planActiveParticipantsTrend = employerApi?.useGetPlanActiveParticipantsTrend({ employer_master_id: props.employerMasterId });

	const historicalBrokerCommissions = EmployerDataUtils.getBarChartConfig(
		historicalEmployerPolicies?.data,
		(x) => x.master_broker_name,
		(x) => (x ? ParseUtils.coalesceFloat(x.ins_broker_comm_pd_amt) + ParseUtils.coalesceFloat(x.ins_broker_fees_pd_amt) : 0),
	);

	const historicalCarrierCommissions = EmployerDataUtils.getBarChartConfig(
		historicalEmployerPolicies?.data,
		(x) => x.carrier_master_name,
		(x) => (x ? ParseUtils.coalesceFloat(x.carrier_premiums) : 0),
	);

	const activeParticipantsTrend = EmployerDataUtils.getMultiLineChartConfig(
		planActiveParticipantsTrend?.data,
		(x) => x.plan_name,
		(x) => (x ? parseFloat(x.active_participants) : 0),
	);

	return (
		<div>
			<Title className="md" text="Employees" />
			<div style={{ paddingBottom: '75px' }}>
				<MultipleLineChart loading={planActiveParticipantsTrend?.loading} data={activeParticipantsTrend} />
			</div>
			<div style={{ paddingBottom: '75px' }}>
				<Title className="md" text="Broker Commissions" />
				<BarsChart loading={historicalEmployerPolicies?.loading} data={historicalBrokerCommissions} />
			</div>
			<div style={{ paddingBottom: '75px' }}>
				<Title className="md" text="Carrier Premiums" />
				<BarsChart loading={historicalEmployerPolicies?.loading} data={historicalCarrierCommissions} />
			</div>
		</div>
	);
};
