/**
 *
 * Asynchronously loads the component for ClientsPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ClientsPageController = lazyLoad(
	() => import('./index'),
	(module) => module.ClientsPageController,
);
