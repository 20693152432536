import { Navigate, Outlet } from 'react-router-dom';

export const AdminRoute = ({ isAuthed, isAdmin, loadingAuth }) => {
	if (loadingAuth) {
		return null;
	} else if (!isAuthed) {
		return <Navigate to="/Login" replace={true} />;
	}
	return isAdmin ? <Outlet /> : <Navigate to="/Search" replace={true} />;
};
