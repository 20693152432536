export type StringListMethod = (value: string[]) => void;

export const createStringListMethods =
	<FilterType>(filterName: keyof FilterType, set) =>
	(value: string[]) => {
		set((state) => ({
			filters: {
				...state.filters,
				[filterName]: value,
			},
		}));
	};
