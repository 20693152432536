// An Enum to Describe the domain to which a SourceField or TargetField belongs for the CRM Integration
//  For example, a user-defined CUSTOM_CONSTANT source_field to write to CRM Objects
//  versus a BENEFIT_FLOW source_field which will be derived from enriched Data

export enum CrmIntegrationFieldDomainType {
	BENEFIT_FLOW = 'BENEFIT_FLOW',
	SALESFORCE = 'SALESFORCE',
	SALESFORCE_CONSTANT = 'SALESFORCE_CONSTANT',
	CUSTOM_CONSTANT = 'CUSTOM_CONSTANT',
}
