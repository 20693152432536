import { useEffect } from 'react';
import { BrokerProfileTabs } from '../BrokerProfile.types';
import { useBrokerProfileStore } from '../BrokerProfile.store';
import { useBrokersStore } from '../../Search/Brokers/Brokers.store';
import { useSearchParams } from 'react-router-dom';

export const useClearState = () => {
	const { setTab, clients } = useBrokerProfileStore();
	const { clearFilters } = useBrokersStore();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		// Clear filters if the employer param is set. This indicates we are coming from the employer search or profile pages
		if (searchParams.get('employer')) {
			setTab(BrokerProfileTabs.CLIENTS);
			clearFilters();
			clients.rowExpandMap.clear();
		}
	}, [searchParams, clearFilters, clients.rowExpandMap.clear]);

	useEffect(() => {
		return () => {
			setTab(BrokerProfileTabs.CLIENTS);
			clients.rowExpandMap.clear();
		};
	}, [setTab, clients.rowExpandMap.clear]);
};
