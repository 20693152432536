import { useEffect } from 'react';

import { Tabs } from '../EmployerProfile.types';
import { useEmployerProfileStore } from '../EmployerProfile.store';

export const useClearState = () => {
	const { setCurrentTab } = useEmployerProfileStore();

	useEffect(() => {
		return () => {
			setCurrentTab(Tabs.BENEFITS);
		};
	}, [setCurrentTab]);
};
