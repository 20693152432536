import { TenantAttributes } from 'common.model/src/db/models';
import { ApiBase, IApiResponse, IMutationResponse } from '../ApiBase';
import { APIClient } from '../types';
import { ITeamInfo } from 'common.model/src/types/services/tenant-service/ITeamInfo';
import { CreateTenantRequest, DeleteTenantRequest, UpdateTenantRequest } from 'common.model/src/types/services/tenant-service/TenantService';

export interface ITenantApi {
	useGetAllTenants: () => IApiResponse<TenantAttributes[]>;
	useGetTenant: () => IApiResponse<TenantAttributes>;
	useGetTenantTeamInfo: () => IApiResponse<ITeamInfo>;
	useCreateTenant: (req: CreateTenantRequest) => IMutationResponse<string>;
	useUpdateTenant: (req: UpdateTenantRequest) => IMutationResponse<string>;
	useDeleteTenant: (req: DeleteTenantRequest) => IMutationResponse<string>;
}

export class TenantApi extends ApiBase implements ITenantApi {
	constructor(
		readonly client: APIClient,
		loggedInUser: string,
	) {
		super(client, loggedInUser, 'tenant');
	}

	useGetAllTenants = (): IApiResponse<TenantAttributes[]> => this.callAuthenticated('getAllTenants', {});

	useGetTenant = (): IApiResponse<TenantAttributes> => this.callAuthenticated('getTenant', {});

	useGetTenantTeamInfo = (): IApiResponse<ITeamInfo> => this.callAuthenticated('getTenantTeamInfo', {});

	useCreateTenant = (req: CreateTenantRequest): IMutationResponse<string> => this.mutateAuthenticated('createTenant', req);

	useUpdateTenant = (req: UpdateTenantRequest): IMutationResponse<string> => this.mutateAuthenticated('updateTenant', req);

	useDeleteTenant = (req: DeleteTenantRequest): IMutationResponse<string> => this.mutateAuthenticated('deleteTenant', req);
}
