import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';
import { listsDetailPageActions } from 'app/containers/ListsPage/containers/ListsDetailPage/slice';

export const useOnDelete = () => {
	const dispatch = useDispatch();
	const { contactFilters } = useSelector(selectListsDetailPage);

	const onDelete = useCallback(
		({ type, value }: { value: string; type: 'include' | 'exclude' }) =>
			() => {
				dispatch(
					listsDetailPageActions.updateContactFilters({
						...contactFilters,
						jobTitles: {
							include: type === 'include' ? contactFilters.jobTitles.include.filter((jobTitle) => jobTitle !== value) : contactFilters.jobTitles.include,
							exclude: type === 'exclude' ? contactFilters.jobTitles.exclude.filter((jobTitle) => jobTitle !== value) : contactFilters.jobTitles.exclude,
						},
					}),
				);
			},
		[dispatch, listsDetailPageActions, contactFilters],
	);

	return onDelete;
};
