import cn from 'classnames';
import { SalesforceAccountDiffJobItemInstance } from 'common.model/src/db/models';

interface NewAccountParentAssignRowSetProps {
	accountDiffInserts: SalesforceAccountDiffJobItemInstance[];
}

export const NewAccountParentAssignRowSet = ({ accountDiffInserts }: NewAccountParentAssignRowSetProps) => {
	return (
		<>
			{accountDiffInserts?.length &&
				accountDiffInserts?.map((accountToInsert) => (
					<div className={cn('affected-account-row', 'd-flex', 'align-items-center')} key={accountToInsert?.id}>
						<div className={cn('d-flex', 'justify-content-between', 'align-items-center', 'px-3', 'flex-fill')}>
							<div className={cn('d-flex', 'flex-fill', 'align-items-center')}>
								<div className={cn('d-flex', 'align-items-center')}>
									<p className={cn('bf-txt', 'txt-dark', 'txt-bolder')}>{accountToInsert?.account_name || '[Account Missing Name]'}</p>
								</div>
							</div>
						</div>
					</div>
				))}
		</>
	);
};
