import { useMemo } from 'react';
import { ChartColors } from '@benefitflow/designsystem/dist/components/Chart/Chart.types';
import { Box, Chart, Skeleton, Table, Tooltip, Typography } from '@benefitflow/designsystem';

import { SummaryProps, TABLE_HEIGHT } from './Summary.types';
import { DetailedInfoTooltip } from '../../../DetailedInfoTooltip';
import { FormattingUtils } from '../../../../../../../../../../../ui-components';
import { TextOverflowTooltip } from '../../../../../../../../../shared/TextOverflowTooltip';
import { useEmployerProfileBenefitsTabStore } from '../../../../Benefits.store';

export const Summary = ({ title, value, values, isLoading, total, colorMap }: SummaryProps) => {
	const isEmpty = useMemo(() => !isLoading && !values?.length, [values, isLoading]);
	const { year } = useEmployerProfileBenefitsTabStore();
	const tableProps = Table.use({
		skeleton: () => (
			<Table.Skeleton
				numberOfRows={5}
				columns={[
					{ height: 'xl', width: 'xl5' },
					{ height: 'xl', width: 'xl5' },
				]}
			/>
		),
		isEmpty,
		fixedHeight: TABLE_HEIGHT,
		empty: <Table.Empty style={{ height: TABLE_HEIGHT }} icon="duotone-building-magnifying-glass" label={`Data not reported in ${year.value} filings`} />,
		columns: [
			{
				title: '',
				key: 'title',
				cellAlign: 'left',
				borderRight: true,
				backgroundColor: 'neutral-100',
				render: ({ title, value }) => (
					<Box display="flex" flexDirection="column" justifyContent="center" height="full" gap="sm">
						<TextOverflowTooltip label={title}>
							{(overflowCallback) => (
								<Typography.Text whiteSpace="nowrap" ellipsis ellipsedCallback={overflowCallback} size="md" color="neutral-600">
									{title}
								</Typography.Text>
							)}
						</TextOverflowTooltip>
						<Typography.Text size="sm" color="neutral-500" weight="regular">
							{value.formatted}
						</Typography.Text>
					</Box>
				),
			},
			{
				title: '',
				key: 'title',
				cellAlign: 'left',
				render: ({ value, companyId, parts }) => (
					<Tooltip placement="bottom-start" content={<DetailedInfoTooltip title={FormattingUtils.formatUpperCasing(title)} values={parts} />}>
						{(ref) => (
							<Chart.Fill
								containerRef={ref}
								borderRadiusLeft="none"
								borderRadiusRight="md"
								height="half"
								parts={[{ value: value.raw > total * 0.025 ? value.raw : total * 0.025, color: colorMap?.[companyId] ?? ChartColors.ORANGE, data: {} }]}
								total={total}
							/>
						)}
					</Tooltip>
				),
			},
		],
		data: values ?? [],
		loading: isLoading,
		hideHeader: true,
	});

	return (
		<Box display="flex" flexDirection="column">
			{isLoading ? (
				<Box display="flex" flexDirection="column" gap="xs" mb="lg">
					<Skeleton height="sm" width="xl4" />
					<Skeleton height="sm" width="xl2" />
				</Box>
			) : (
				<Box display="flex" flexDirection="column" gap="xs" mb="lg">
					<Typography.Text color="neutral-500" size="sm" weight="regular">
						{title}
					</Typography.Text>

					{isEmpty ? (
						<i>
							<Typography.Display size="md" Tag="p" weight="regular" color="neutral-400">
								Data not reported
							</Typography.Display>
						</i>
					) : (
						<Typography.Display size="md" weight="bold">
							{value}
						</Typography.Display>
					)}
				</Box>
			)}
			<Table {...tableProps} />
		</Box>
	);
};
