import { useContext, useMemo } from 'react';

import { ListDetailsContext } from '../../../ListDetails.context';

export const useOptions = ({ search }: { search: string }) => {
	const {
		options: { companyName },
	} = useContext(ListDetailsContext);
	const options = useMemo(
		() =>
			companyName
				?.filter(({ option }) => option.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
				.map((data) => ({ label: data.formattedOption, value: data.option, data })) ?? [],
		[companyName, search],
	);

	return options;
};
