import { DeleteTenantRequest } from 'common.model/src/types/services/tenant-service/TenantService';
import { ApiBase, IApiResponse, IMutationResponse } from '../ApiBase';
import { APIClient } from '../types';
import IUser from 'common.model/src/types/services/user-service/IUser';
import {
	CreateUserRequest,
	DeleteUserRequest,
	GetUserGroupsRequest,
	UpdateUserAttributesRequest,
} from 'common.model/src/types/services/user-service/UserService';
import { UpdateUserAttributesResponse } from 'common.model/src/types/services/user-service/UserService';

export interface IUserApi {
	useDeleteTenant: (req: DeleteTenantRequest) => IMutationResponse<string>;
	useGetAllUsersForTenant: () => IApiResponse<IUser[]>;
	useGetAllUsers: () => IApiResponse<IUser[]>;
	useCreateUser: (req: CreateUserRequest) => IMutationResponse<string>;
	useUpdateUserAttributes: (req: UpdateUserAttributesRequest) => IMutationResponse<UpdateUserAttributesResponse>;
	useDeleteUser: (req: any) => IMutationResponse<string>;
	useGetUserGroups: (req: GetUserGroupsRequest) => IApiResponse<string[]>;
}

export class UserApi extends ApiBase implements IUserApi {
	constructor(
		readonly client: APIClient,
		loggedInUser: string,
	) {
		super(client, loggedInUser, 'user');
	}
	useDeleteTenant = (req: DeleteTenantRequest): IMutationResponse<string> => this.mutateAuthenticated('deleteTenant', req);

	useGetAllUsersForTenant = (): IApiResponse<IUser[]> => this.callAuthenticated('getAllUsersForTenant', {});

	useGetAllUsers = (): IApiResponse<IUser[]> => this.callAuthenticated('getAllUsers', {});

	useCreateUser = (req: CreateUserRequest): IMutationResponse<string> => this.mutateAuthenticated('createUser', req);

	useUpdateUserAttributes = (req: UpdateUserAttributesRequest): IMutationResponse<UpdateUserAttributesResponse> =>
		this.mutateAuthenticated('updateUserAttributes', req);

	useDeleteUser = (req: DeleteUserRequest): IMutationResponse<string> => this.mutateAuthenticated('deleteUser', req);

	useGetUserGroups = (req: GetUserGroupsRequest): IApiResponse<string[]> => this.callAuthenticated('getUserGroups', req);
}
