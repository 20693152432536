import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { salesforce } from 'assets/index';

interface SalesforceHeaderProps {
	reSyncFields: () => void;
}

export const SalesforceHeader = ({ reSyncFields }: SalesforceHeaderProps) => {
	const navigate = useNavigate();

	return (
		<div className={classnames('m-2', 'mb-1', 'd-flex', 'flex-row', 'justify-content-between', 'align-items-center', 'flex-wrap')}>
			<div className={classnames('d-flex', 'align-items-center')}>
				<button onClick={() => navigate(-1)} className={classnames('btn', 'btn-outline', 'btn-lg')}>
					<FaArrowLeft size={20} />
				</button>
				<img className={classnames('img-sm', 'mx-1')} src={salesforce} />
				<div>
					<p className={classnames('bf-txt', 'txt-lg', 'txt-bold', 'mx-2', 'txt-dark')}>Field Mapping Configuration</p>
				</div>
			</div>
			<div className={classnames('d-flex', 'align-items-center')}>
				<button onClick={reSyncFields} type="button" className={classnames('btn', 'btn-outline-dark')}>
					Refresh Salesforce Constants
				</button>
			</div>
		</div>
	);
};
