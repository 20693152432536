import React, { useRef, useState } from 'react';
import ButtonMaterialUi from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import styled, { ThemedStyledProps } from 'styled-components';
import GetAppIcon from '@material-ui/icons/GetApp';
import DropDownPopover from 'atoms/filters/drop/DropDownPopover';

export interface DownloadButtonProps {
	className?: string;
	title?: string;
	count?: number;
	disabled?: boolean;
	loading?: boolean;
	onClick?: () => void;
	hasOptions?: boolean;
	getDownloadMenuOptions?: (closeMenu: () => void) => any[];
}

const Button = styled(ButtonMaterialUi)<{ $isMenuOpen: boolean }>`
	border-radius: 4px !important;
	height: 40px;
	padding: 0 18px !important;
	background-color: ${(props) => (props.disabled ? '#00000010' : props.$isMenuOpen ? '#185ADB90' : '#185ADB')} !important;
	border: ${(props) => (props.disabled ? '1px solid transparent' : '1px solid #185ADB')} !important;
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')} !important;
	box-shadow: none !important;

	.MuiButton-label {
		font-family: 'Roboto';
		font-size: 14px;
		color: ${(props) => (props.disabled ? '#00000055' : '#FFFFFF')};
		text-transform: capitalize;
		font-weight: 700;
	}
`;

const StyledCircularProgress = styled(CircularProgress)`
	width: 24px !important;
	height: 24px !important;
	color: #ff2b2b !important;
`;

export const DownloadButton = ({ className, title, count, disabled, loading, onClick, hasOptions, getDownloadMenuOptions, ...rest }: DownloadButtonProps) => {
	const [isOpen, setIsOpen] = useState(false);
	let endIcon = null;
	if (loading) {
		endIcon = <StyledCircularProgress />;
	} else if (hasOptions) {
		endIcon = isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />;
	}
	const [anchor, setAnchor] = useState<HTMLElement | null>(null);
	const onOpen = (e: React.MouseEvent<HTMLElement>) => {
		setAnchor(e.currentTarget);
		setIsOpen(true);
	};
	const onClose = () => {
		setAnchor(null);
		setIsOpen(false);
	};
	const dropdownChildren = hasOptions ? getDownloadMenuOptions(onClose) : [];
	return (
		<>
			<Button
				className={className}
				variant="contained"
				color="default"
				$isMenuOpen={isOpen}
				disabled={disabled}
				onClick={hasOptions ? onOpen : onClick}
				startIcon={<GetAppIcon style={{ color: '#FFF' }} />}
				endIcon={endIcon}
				{...rest}
			>
				{title}
			</Button>
			<DropDownPopover anchorEl={anchor} children={dropdownChildren} handleMenuClose={onClose} />
		</>
	);
};
