import { useState } from 'react';
import serviceUrl from 'utils/serviceUrl';
import axios from 'axios';
import { useBasicApiRequestState, BasicApiRquestState, BaseApiReponse } from '../useBasicApiRequestState';
import { SyncAccountsRequiringParent } from 'common.model/src/db/model-decorators/type-extensions';

interface SyncAccountsRequiringParentResponce extends BaseApiReponse {
	accountResolveInstance: SyncAccountsRequiringParent;
	fetchAccountsRequiringParent: (accountDiffIds: number[], callback?: VoidFunction) => Promise<void>;
}

const getAccountToAssignUrl = `${serviceUrl}/crm-integration/getSyncAccountsRequiringParent`;
export const useAccountsRequiringParent = (listId: string): SyncAccountsRequiringParentResponce => {
	const { baseHeaders, loading, setLoading, requestApiSuccess, setApiRequestSuccess, requestErrorMessage, setRequestErrorMessage }: BasicApiRquestState =
		useBasicApiRequestState();

	const [accountResolveInstance, setSyncAccountsRequiringParent] = useState<SyncAccountsRequiringParent>({
		existingAccounts: [],
		accountInserts: [],
	});

	const fetchAccountsRequiringParent = async (accountDiffIds: number[] = [], callback = () => {}) => {
		setLoading(true);
		return axios
			.post(`${getAccountToAssignUrl}/${listId}`, { accountDiffIds }, { headers: { ...baseHeaders } })
			.then((res) => {
				const response = res?.data?.res?.data;
				setSyncAccountsRequiringParent(response);
				setApiRequestSuccess(true);
				return response;
			})
			.then((response) => {
				const hasAccountInsertsToAssign = !!response?.accountInserts?.length;

				if (hasAccountInsertsToAssign && callback) {
					callback();
				}
			})
			.catch((err: Error) => {
				setRequestErrorMessage(err.message);
				setApiRequestSuccess(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return {
		accountResolveInstance,
		fetchAccountsRequiringParent,
		loading,
		requestApiSuccess,
		requestErrorMessage,
	};
};
