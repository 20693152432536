import { useContext } from 'react';

import { useEmployerId } from '../../../../../../hooks';
import { SummaryProps } from '../components/Summary/Summary.types';
import { useEmployerProfileBenefitsTabStore } from '../../../Benefits.store';
import { FormattingUtils } from '../../../../../../../../../../ui-components';
import { APIContext } from '../../../../../../../../../services/api/Api.context';
import { GetEmployerPoliciesSummaryResponse } from 'common.model/src/types/services/employer-service/EmployerService';

export type PolicySummaryDTO = Record<'brokerCommissions' | 'carrierPremiums', Pick<SummaryProps, 'value' | 'values' | 'total'>>;

export const usePoliciesSummary = () => {
	const employerId = useEmployerId();
	const { selectedPolicies, year } = useEmployerProfileBenefitsTabStore();
	const {
		employerApi: { useGetEmployerPoliciesSummary },
	} = useContext(APIContext);

	const { isLoading, data } = useGetEmployerPoliciesSummary<PolicySummaryDTO>(
		{ employerId, year: year.value, insuranceProducts: selectedPolicies.values },
		{
			select,
			enabled: !!year.value,
		},
	);

	return {
		isLoading,
		data,
	};
};

export const select = (data: GetEmployerPoliciesSummaryResponse): PolicySummaryDTO => ({
	brokerCommissions: {
		total: data.brokerCommissions.total,
		value: `${FormattingUtils.formatNumberAbbrev(data.brokerCommissions.total, undefined, undefined, '$')}`,
		values: (() => {
			if (data.brokerCommissions.values.length < 5) return data.brokerCommissions.values.map(formatValue);
			return groupValues(data.brokerCommissions.values, 'Brokers').map(formatValue);
		})(),
	},
	carrierPremiums: {
		total: data.carrierPremiums.total,
		value: `${FormattingUtils.formatNumberAbbrev(data.carrierPremiums.total, undefined, undefined, '$')}`,
		values: (() => {
			if (data.carrierPremiums.values.length < 5) return data.carrierPremiums.values.map(formatValue);
			return groupValues(data.carrierPremiums.values, 'Carriers').map(formatValue);
		})(),
	},
});

const formatValue = ({
	name,
	value,
	parts,
}: {
	name: string;
	value: number;
	companyId: string;
	parts?: { title: string; value: string }[];
}): { title: string; value: { raw: number; formatted: string }; companyId: string; parts: { title: string; value: string }[] } => ({
	title: name,
	value: { formatted: `${FormattingUtils.formatNumberAbbrev(value, undefined, undefined, '$')}`, raw: value },
	companyId: name,
	parts: parts ? parts : [{ title: name, value: `$${FormattingUtils.formatNumberAbbrev(value)}` }],
});

const groupValues = (
	values: { name: string; value: number; companyId: string }[],
	suffix: string,
): { name: string; value: number; companyId: string; parts?: { title: string; value: string }[] }[] => {
	return [
		...values.slice(0, 4),
		{
			name: `${values.slice(4).length} Other ${suffix}`,
			value: values.slice(4).reduce((acc, cur) => acc + cur.value, 0),
			companyId: 'other',
			parts: values.slice(4).map(({ name, value }) => ({
				title: name,
				value: `${FormattingUtils.formatNumberAbbrev(value, undefined, undefined, '$')}`,
			})),
		},
	];
	return [];
};
