import { useContext, useEffect } from 'react';
import { ChartColors } from '@benefitflow/designsystem/dist/components/Chart/Chart.types';
import { LineOfBusinessCategory } from 'common.model/src/types/services/employer-service/LineOfBusinessCategory';

import { useEmployerId } from '../../../../../../hooks';
import { useEmployerProfileBenefitsTabStore } from '../../../Benefits.store';
import { APIContext } from '../../../../../../../../../services/api/Api.context';

const COLORS = [ChartColors.LIGHT_BLUE, ChartColors.GREEN, ChartColors.LIME, ChartColors.YELLOW];

export const useLoadColors = () => {
	const { brokerColorMap, carrierColorMap, year } = useEmployerProfileBenefitsTabStore();
	const employerId = useEmployerId();
	const {
		employerApi: { useGetEmployerPoliciesSummary },
	} = useContext(APIContext);
	const { data } = useGetEmployerPoliciesSummary({ employerId, year: year.value, insuranceProducts: [LineOfBusinessCategory.ALL] });
	useEffect(() => {
		if (data) {
			brokerColorMap.setValues(
				data.brokerCommissions.values.reduce((colors, { name }, index) => ({ ...colors, [name]: COLORS[index] ?? ChartColors.ORANGE }), {}),
			);
			carrierColorMap.setValues(
				data.carrierPremiums.values.reduce((colors, { name }, index) => ({ ...colors, [name]: COLORS[index] ?? ChartColors.ORANGE }), {}),
			);
		}
	}, [data, brokerColorMap.setValues, carrierColorMap.setValues, year.value]);
};
