import { useMemo } from 'react';

import { useEmployerId } from '../../../../../../hooks';
import { useEmployerProfileBenefitsTabStore } from '../../../Benefits.store';
import { useServiceProviderFeesQuery } from '../../../../../../../../../queries/useServiceProviderFeesQuery';

export const useServiceProviders = (type: 'health' | 'retirement') => {
	const employerId = useEmployerId();
	const {
		year: { value: year },
	} = useEmployerProfileBenefitsTabStore();
	const { data, isLoading, fetchNextPage, hasNextPage, isFetching } = useServiceProviderFeesQuery({
		employerId,
		year,
		type,
		options: {
			enabled: !!year,
			getNextPageParam: (lastPage) => lastPage.next,
		},
	});
	const formatted = useMemo(
		() => ({
			data: data?.pages?.flatMap((page) => page.data.providers) ?? [],
			total: data?.pages?.[0]?.data?.total ?? 0,
			count: data?.pages?.[0]?.total,
		}),
		[data],
	);

	return {
		...formatted,
		isLoading,
		fetchNextPage,
		hasNextPage,
		isFetching,
	};
};
