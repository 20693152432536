import { useContext } from 'react';

import { useEmployerId } from '../../../../../../hooks';
import { useEmployerProfileBenefitsTabStore } from '../../../Benefits.store';
import { APIContext } from '../../../../../../../../../services/api/Api.context';

export const useYears = () => {
	const employerId = useEmployerId();
	const {
		year: { setValue },
	} = useEmployerProfileBenefitsTabStore();
	const {
		employerApi: { useGetFillingYears },
	} = useContext(APIContext);
	const { data, isLoading } = useGetFillingYears(
		{ employerId },
		{
			onSuccess: (data) => {
				if (data.default) setValue(data.default);
			},
		},
	);

	return {
		isLoading,
		years: data?.years ?? [],
	};
};
