import { useRef } from 'react';

import { Filters, Header, List } from './components';
import { TabLayout } from '../../../shared/TabLayout/TabLayout';
import { useEmployersStore } from './Employers.store';
import { useEmployers, useFilterOptions } from './hooks';
import { useElementDimensions } from '../../../../hooks/useElementDimensions';
import { ClearbitFooter } from '../../../shared/ClearbitFooter/ClearbitFooter';
import { SearchBar } from '../components';

export const Employers = () => {
	const { filters, setFilters } = useEmployersStore();
	const sidebarRef = useRef<HTMLDivElement>(null);
	const { height } = useElementDimensions({ ref: sidebarRef, dependency: filters });
	const { employers, isFetching, isLoading, fetchNextPage, hasNextPage } = useEmployers();
	const { options, isLoading: filtersAreLoading } = useFilterOptions();

	return (
		<TabLayout
			sidebarRef={sidebarRef}
			filters={<Filters isLoading={filtersAreLoading} breakdowns={options?.breakdowns} />}
			list={<List hasNextPage={hasNextPage} minHeight={height} rows={employers} isFetching={isFetching} isLoading={isLoading} fetchNextPage={fetchNextPage} />}
			footer={<ClearbitFooter />}
			buttons={<Header isLoading={filtersAreLoading || isLoading} resultsCount={options?.totalHits} />}
			search={<SearchBar placeholder="Search employers" value={filters.employerNameSearch} setValue={setFilters.employerNameSearch} />}
		/>
	);
};
