import { useMemo } from 'react';
import { Box, Paper, Tooltip, Typography } from '@benefitflow/designsystem';
import { Empty } from './Empty';

export const List = ({ labels }: { labels: string[] }) => {
	const showMoreItemsButton = useMemo(() => labels.length > 3, [labels]);
	const label = useMemo(() => {
		return `${labels.slice(0, 3).join(', ')}${showMoreItemsButton ? ',' : ''}`;
	}, [labels, showMoreItemsButton]);
	const numberOfExtraItems = useMemo(() => labels.length - 3, [labels]);
	const extraItems = useMemo(() => {
		if (labels.length > 3) return labels.slice(3);
		return [];
	}, [labels]);
	if (!labels.length) return <Empty />;

	return (
		<Box display="flex" gap="xs">
			<Typography.Text>{label}</Typography.Text>
			{showMoreItemsButton && (
				<Tooltip
					behavior="hover"
					strategy="absolute"
					content={
						<Paper>
							<Box display="flex" flexDirection="column" gap="xs">
								{extraItems.map((item, index) => (
									<Typography.Text size="xs" color="neutral-600" weight="medium" whiteSpace="nowrap">
										• {item}
									</Typography.Text>
								))}
							</Box>
						</Paper>
					}
				>
					{(ref) => (
						<Box cursor="pointer" containerRef={ref}>
							<Typography.Text color="primary-500">{`+ ${numberOfExtraItems} others`}</Typography.Text>
						</Box>
					)}
				</Tooltip>
			)}
		</Box>
	);
};
