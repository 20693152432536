import { useEffect, useMemo, useState } from 'react';

export const useIsOverflowed = (containerRef: React.RefObject<HTMLDivElement>) => {
	const [scrollWidth, setScrollWidth] = useState(0);
	const [offsetWidth, setOffsetWidth] = useState(0);

	useEffect(() => {
		const resizeObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				if (entry.target) {
					setScrollWidth(entry.target.scrollWidth);
					// @ts-ignore
					setOffsetWidth(entry.target.offsetWidth);
				}
			}
		});
		if (containerRef.current) resizeObserver.observe(containerRef.current);

		return () => {
			if (containerRef.current) resizeObserver.unobserve(containerRef.current);
		};
	}, [containerRef]);
	const isOverflowed = useMemo(() => scrollWidth > offsetWidth, [scrollWidth, offsetWidth]);

	return isOverflowed;
};
