import { Dialog, Box, Paper, Typography, Button, Divider, Space } from '@benefitflow/designsystem';
import { ColoredTextArea, ColoredLine } from '../ColoredTextArea/ColoredTextArea';
import { ListActionUtils } from 'new-beginning/utilities/ListActionUtils/ListActionUtils';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CompanyWebsiteMatchType } from 'common.model/src/types/common/company/CompanyWebsiteMatch';
import { APIContext } from 'new-beginning/services/api/Api.context';

export const MAX_WEBSITES = 500;

export const BulkSearchDialog = ({
	isModalOpen,
	setIsModalOpen,
	onSetMasterIds,
}: {
	isModalOpen: boolean;
	setIsModalOpen: (isOpen: boolean) => void;
	onSetMasterIds: (masterIds: string[]) => void;
}) => {
	const [urlInput, setUrlInput] = useState<ColoredLine[]>([]);
	const [totalCompanies, setTotalCompanies] = useState(0);
	const [clearButtonHovering, setClearButtonHovering] = useState(false);
	const [masterIds, setMasterIds] = useState<string[]>([]);
	const zIndex = useMemo(() => ListActionUtils.getDialogZIndex(), []);
	const { employerApi } = useContext(APIContext);
	const { data, isLoading } = employerApi.useMatchEmployerWebsites(
		{
			websites: urlInput.map((line) => line.text),
		},
		{
			enabled: isModalOpen && urlInput.length > 0,
		},
	);
	const isInputEmpty = useMemo(() => {
		return urlInput.length === 0 || urlInput.every((line) => line.text.length === 0);
	}, [urlInput]);
	const onViewResults = useCallback(() => {
		onSetMasterIds(masterIds);
		setIsModalOpen(false);
	}, [masterIds, onSetMasterIds, setIsModalOpen]);
	useEffect(() => {
		if (data?.matchResults.length > 0) {
			let currMatches = 0;
			let numCompanies = 0;
			const masterIds = [];
			const processedInput = data.matchResults.map((result) => {
				if (result.rawWebsite) {
					numCompanies++;
				}
				const isMatch = result.matchType !== CompanyWebsiteMatchType.NotFound;
				if (isMatch) {
					currMatches++;
					masterIds.push(result.companyMasterId);
				}
				return {
					text: result.rawWebsite,
					color: isMatch ? 'neutral-400' : 'danger-500',
				};
			}) satisfies ColoredLine[];
			setUrlInput(processedInput);
			setMasterIds(masterIds);
			setTotalCompanies(numCompanies);
		}
	}, [data?.matchResults]);
	return (
		<Dialog open={isModalOpen} key="url-search-modal" zIndex={zIndex}>
			<Box style={{ width: '30rem' }} display="flex" flexDirection="column" gap="md" m="lg">
				<Paper p="none">
					<Box mx="lg" mt="lg" mb="sm">
						<Typography.Text size="lg" color="neutral-700">
							Bulk search companies by URL
						</Typography.Text>
					</Box>
					<Box mx="lg" my="sm" display="flex" flexDirection="row" alignItems="center" justifyContent="between">
						<Typography.Text size="sm" color="neutral-400">
							Enter or paste up to {MAX_WEBSITES} company URLs
						</Typography.Text>
						<Box cursor="pointer" onClick={() => setUrlInput([])}>
							<Typography.Text
								size="sm"
								color="danger-500"
								weight="bold"
								decoration={clearButtonHovering ? 'underline' : undefined}
								onMouseEnter={() => setClearButtonHovering(true)}
								onMouseLeave={() => setClearButtonHovering(false)}
							>
								Clear
							</Typography.Text>
						</Box>
					</Box>
					<ColoredTextArea placeholder="Enter your search terms here" value={urlInput} onChange={setUrlInput} maxLines={MAX_WEBSITES} />
					<Divider />
					<Box my="md" mx="lg" display="flex" flexDirection="row" alignItems="center" justifyContent="between">
						<Box display={isInputEmpty || isLoading ? 'hidden' : 'flex'}>
							<Typography.Text size="sm" color="neutral-900" weight="semi-bold">
								We matched {masterIds.length} of {totalCompanies} companies
							</Typography.Text>
						</Box>
						<Box display="flex" flexDirection="row" gap="sm" alignItems="center">
							<Box cursor="pointer">
								<Typography.Text color="primary-500" onClick={() => setIsModalOpen(false)}>
									Cancel
								</Typography.Text>
							</Box>
							<Button fetching={urlInput.length > 0 && isLoading} onClick={onViewResults}>
								View Results
							</Button>
						</Box>
					</Box>
				</Paper>
			</Box>
		</Dialog>
	);
};
