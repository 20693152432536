import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { DownloadButton, DownloadButtonProps } from 'atoms/Buttons/DownloadButton';
import { Panel } from 'atoms/Panel';
import { Table, TableProps } from 'organisms/Table';

export interface UsersTabsProps {
	download: DownloadButtonProps;
	table: TableProps;
}

export const UsersTab = (props: UsersTabsProps) => {
	const usersColumns = [
		{
			name: 'Client Name',
			selector: (row: any) => row.tenant_id,
			sortable: true,
		},
		{
			name: 'Name',
			selector: (row: any) => row.name,
			sortable: true,
		},
		{
			name: 'Email',
			selector: (row: any) => row.email,
			sortable: true,
		},
		{
			name: 'User Type',
			selector: (row: any) => row.user_type,
			sortable: true,
		},
	];

	return (
		<>
			<div style={{ textAlign: 'right', marginBottom: '1rem' }}>
				<DownloadButton title={'Export Users'} {...props.download} />
			</div>
			<div>
				<Row>
					<Col lg={{ span: 12 }} xl={{ span: 12 }}>
						<Panel className="f-h">
							<Table columns={usersColumns} {...props.table} />
						</Panel>
					</Col>
				</Row>
			</div>
		</>
	);
};
