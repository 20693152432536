import { useCallback, useMemo } from 'react';
import { Button, Typography } from '@benefitflow/designsystem';

import { useEmployerProfileBenefitsTabStore } from '../../../Benefits.store';

export const Year = ({ value }: { value: string }) => {
	const { year } = useEmployerProfileBenefitsTabStore();
	const isSelected = useMemo(() => year.value === value, [year.value, value]);
	const onClick = useCallback(() => year.setValue(value), [year, value]);

	return (
		<Button.Selectable padding="xs" isSelected={isSelected} onClick={onClick}>
			<Typography.Text>{value}</Typography.Text>
		</Button.Selectable>
	);
};
