import { useState, useEffect } from 'react';
import serviceUrl from 'utils/serviceUrl';
import axios from 'axios';
import { useBasicApiRequestState, BasicApiRquestState, BaseApiReponse } from '../useBasicApiRequestState';
import { CrmIntegrationFieldDomainType, ObjectMappingTargetType } from 'common.model/src/db/model-decorators/type-extensions';
import IField from 'common.model/src/types/services/integration/fieldMapping/IField';
import { BENEFIT_FLOW_CRM_OBJECTS } from 'common.model/src/types/services/integration/fieldMapping/BenefitFlowFields';

export interface DomainOptionHeader {
	label: string;
	isHeaderOption: boolean;
	field_domain_type: CrmIntegrationFieldDomainType;
}

export interface CrmObjectFieldOption {
	name: string;
	label: string;
	type: string;
	value?: string;
	field_domain_type: CrmIntegrationFieldDomainType;
	// Dropdown-Specific Field (Only exists on Frontend)
	isHeaderOption?: boolean;
	picklistValue?: CrmPicklistSubtype;
}

interface CrmPicklistSubtype {
	type: 'picklistOption';
	active: boolean;
	label: string;
	value: string;
}

export interface BenefitFlowField extends CrmObjectFieldOption {}

export interface CrmObjectField extends CrmObjectFieldOption {
	compoundFieldName: string;
}

export type DropDownOptionType = CrmObjectField | BenefitFlowField | Partial<CrmObjectFieldOption>;

export type GroupedOptionSet = {
	label: string;
	field_domain_type: CrmIntegrationFieldDomainType;
	options: DropDownOptionType[];
};

export enum CrmObjectTypes {
	ACCOUNT = 'ACCOUNT',
	CONTACT = 'CONTACT',
}

const decorateFieldOptions = (options: IField[] = [], groupLabel: string, field_domain_type: CrmIntegrationFieldDomainType): GroupedOptionSet[] => {
	return [{ label: groupLabel, options, field_domain_type }];
};

export const getFieldMappingOptions = (bfObjectType: ObjectMappingTargetType, sfObjectType: CrmObjectTypes, crmFields: CrmFieldsCache): GroupedOptionSet[] => {
	const relevantBenefitFlowFields = BENEFIT_FLOW_CRM_OBJECTS?.[bfObjectType] || [];

	return [
		...decorateFieldOptions(relevantBenefitFlowFields, 'BenefitFlow Fields', CrmIntegrationFieldDomainType.BENEFIT_FLOW),
		...decorateFieldOptions(crmFields?.[sfObjectType]?.picklist, 'Saleforce Constants', CrmIntegrationFieldDomainType.SALESFORCE_CONSTANT),
		{
			label: 'Custom Value',
			field_domain_type: CrmIntegrationFieldDomainType.CUSTOM_CONSTANT,
			options: [
				{
					name: 'userDefinedField',
					label: 'Custom Value',
					field_domain_type: CrmIntegrationFieldDomainType.CUSTOM_CONSTANT,
				},
			],
		},
	];
};

interface CrmFieldsCache {
	[fieldType: string]: {
		fields: CrmObjectField[];
		picklist: CrmObjectField[];
	};
}

interface CrmFieldConfiguration extends BaseApiReponse {
	crmObjectFields: CrmFieldsCache;
	clearCache: () => void;
}

const unsupportedSalesforceTypes = new Set(['formula', 'lookup', 'double', 'date', 'datetime', 'multipicklist']);
const crmObjectFieldNames = `${serviceUrl}/crm-integration/getCrmObjectFieldNames`;
export const useCrmFields = (crmObjectType: CrmObjectTypes): CrmFieldConfiguration => {
	const {
		baseHeaders,
		tokenLoading,
		loading,
		setLoading,
		requestApiSuccess,
		setApiRequestSuccess,
		requestErrorMessage,
		setRequestErrorMessage,
	}: BasicApiRquestState = useBasicApiRequestState();

	const [crmObjectFields, setCrmObjectFields] = useState<CrmFieldsCache | {}>({});
	const setCacheKey = (objecType, data) =>
		setCrmObjectFields(
			Object.assign({}, crmObjectFields, {
				[objecType]: data,
			}),
		);

	const fetchFieldNames = () => {
		setLoading(true);
		return axios
			.post(crmObjectFieldNames, { objectName: crmObjectType }, { headers: { ...baseHeaders } })
			.then((res) => {
				setApiRequestSuccess(true);
				const supportedFields = (res?.data?.res?.data?.salesforceSchema?.fields || [])
					.filter((field) => !unsupportedSalesforceTypes.has(field?.type?.toLowerCase()))
					.reduce(
						(accum, { type, name, label, soapType, picklistValues, defaultValue, extraTypeInfo, compoundFieldName }) => {
							const field: CrmObjectField = {
								type,
								name,
								label,
								compoundFieldName,
								field_domain_type: CrmIntegrationFieldDomainType.SALESFORCE,
							};
							if (!picklistValues?.length) {
								accum.fields.push(field);
							} else {
								const flattenedPicklistValues = picklistValues.map(({ label, value, active }) => ({
									...field,
									field_domain_type: CrmIntegrationFieldDomainType.SALESFORCE_CONSTANT,
									picklistValue: {
										type: 'picklistOption',
										active,
										label,
										value,
									},
								}));

								accum.picklist.push(...flattenedPicklistValues);
							}
							return accum;
						},
						{ picklist: [], fields: [] },
					);
				setCacheKey(crmObjectType, supportedFields);
			})
			.catch((err: Error) => {
				setRequestErrorMessage(err.message);
				setApiRequestSuccess(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const clearCrmFieldCache = () => {
		setCrmObjectFields({});
		fetchFieldNames();
	};

	useEffect(() => {
		if (!Object.keys(crmObjectFields).length) {
			fetchFieldNames();
		}
	}, [crmObjectFields]);

	useEffect(() => {
		if (baseHeaders?.Authorization && !crmObjectFields?.[crmObjectType]) {
			fetchFieldNames();
		} else if (!tokenLoading && !crmObjectFields?.[crmObjectType]) {
			console.warn('Cannot Fetch Client Salesforce Fields without Token');
		}
	}, [crmObjectType, baseHeaders?.Authorization]);

	return {
		crmObjectFields,
		clearCache: clearCrmFieldCache,
		loading,
		requestApiSuccess,
		requestErrorMessage,
	};
};
