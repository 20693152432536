export enum CompanyWebsiteMatchType {
	Exact,
	Cleaned,
	NotFound,
}

export type CompanyWebsiteMatchResult = {
	rawWebsite: string;
	matchType: CompanyWebsiteMatchType;
	matchedWebsite?: string;
	companyMasterId?: string;
};

export interface MatchCompanyWebsiteRequest {
	websites: string[];
}

export interface MatchCompanyWebsiteResponse {
	matchResults: CompanyWebsiteMatchResult[];
}
