import { ReactNode, useMemo } from 'react';
import { Box, Icon, Skeleton, Typography } from '@benefitflow/designsystem';
import { IconName } from '@benefitflow/designsystem/dist/components/Icon/Icon.types';

import { Empty } from './DescriptionContent/components/Empty';

export const Description = ({
	children,
	label,
	isLoading,
	isEmpty = false,
	icon,
}: {
	children?: ReactNode;
	label: string;
	isLoading: boolean;
	isEmpty?: boolean;
	icon?: `${IconName}`;
}) => {
	const content = useMemo(() => {
		if (isEmpty) return <Empty />;
		if (typeof children === 'string' || typeof children === 'number') return <Typography.Text>{children}</Typography.Text>;
		return children;
	}, [children, isEmpty]);

	if (isLoading)
		return (
			<Box display="flex" flexDirection="column" gap="xxs">
				<Skeleton height="sm" width="xl4" />
				<Skeleton height="sm" width="xl6" />
			</Box>
		);

	return (
		<Box display="flex" flexDirection="column" gap="sm" height="xl2">
			<Box display="flex" gap="xs" alignItems="center">
				{icon && <Icon color="primary-500" name={icon} size="md" />}
				<Typography.Text weight="regular" size="sm" color="neutral-500">
					{label}
				</Typography.Text>
			</Box>
			{content}
		</Box>
	);
};
