import serviceUrl from 'utils/serviceUrl';
import axios from 'axios';
import { useBasicApiRequestState, BasicApiRquestState, BaseApiReponse } from '../useBasicApiRequestState';
import { DecoratedFieldMapping, StagedDecoratedFieldMapping } from 'common.model/src/db/model-decorators/type-extensions';

interface UpdateCrmMappingResponse extends BaseApiReponse {
	updateFieldMappings: (newMappingFields: StagedDecoratedFieldMapping[], existingFieldEdits: DecoratedFieldMapping[], fieldsToDelete: number[]) => Promise<any>;
}

const saveFieldMappingUrl = `${serviceUrl}/crm-integration/saveCrmObjectFieldMappings`;
export const useUpdateCrmMappingFields = (): UpdateCrmMappingResponse => {
	const { baseHeaders, loading, setLoading, requestApiSuccess, setApiRequestSuccess, requestErrorMessage, setRequestErrorMessage }: BasicApiRquestState =
		useBasicApiRequestState();

	const updateFieldMappings = (newMappingFields, existingFieldEdits, fieldsToDelete) => {
		setLoading(true);
		setApiRequestSuccess(null);
		setApiRequestSuccess(null);

		return axios
			.post(saveFieldMappingUrl, { newMappingFields, existingFieldEdits, fieldsToDelete }, { headers: { ...baseHeaders } })
			.then((res) => {
				setApiRequestSuccess(true);
			})
			.catch((err: Error) => {
				console.error(`Failure saving FieldMapping Settings. Msg: ${err?.message}`);
				setRequestErrorMessage(err.message);
				setApiRequestSuccess(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return {
		loading,
		requestErrorMessage,
		requestApiSuccess,
		updateFieldMappings,
	};
};
