import AmplifyConfigManager from './AmplifyConfigManager';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { Amplify, Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { LoggedInUser } from 'new-beginning/context/LoggedInUser';

export default class AmplifyTokenManager {
	private static _instance;

	private constructor() {
		Amplify.configure({
			Auth: AmplifyConfigManager.getAmplifyConfig(),
		});
	}

	public static getInstance() {
		if (!AmplifyTokenManager._instance) {
			AmplifyTokenManager._instance = new AmplifyTokenManager();
		}
		return AmplifyTokenManager._instance;
	}

	public getIdToken() {
		return Auth.currentSession()
			.then((userData) => {
				const idToken = this.parseIdToken(userData);
				return idToken;
			})
			.catch((err) => {
				return Auth.federatedSignIn();
			});
	}

	public getLoggedInAuther(): Promise<CognitoUser> {
		return Auth.currentAuthenticatedUser().then((user: LoggedInUser) => {
			return user;
		});
	}

	public signIn() {
		return Auth.federatedSignIn();
	}

	public parseIdToken(cognitoUser) {
		const idToken = cognitoUser?.idToken?.jwtToken;
		const cpy = idToken?.slice();
		const decodedIdToken = jwt_decode<JwtPayload>(idToken);
		// console.log("decodedIdToken", decodedIdToken);
		return {
			idToken: cpy,
			decodedIdToken: decodedIdToken,
		};
	}

	public async logout() {
		await Auth.signOut();
	}
}
