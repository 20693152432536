import { PayloadAction } from '@reduxjs/toolkit';
import { appActions } from 'app/slice';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import { BROKER_CONTACT_TAB } from 'pages/ListsPage/pages/ListsDetailPage/page';
import { resetToInitialState } from '../../../../../utils/ReduxStateUtils';
import { ISimpleContact } from 'common.model/src/types/common/contact/ISimpleContact';
import { ContactSearchResponse } from 'common.model/src/types/common/contact/ContactSearchResponse';
import { EntityLocation } from 'common.model/src/types/master/location';
import { getDefaultBrokerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';
import { ContactTypeEnum } from 'common.model/src/types/ContactTypeEnum';

// The initial state of the ListsDetailPage container
export const initialState: ContainerState = {
	selectedListId: null,
	anonymizedListContactResults: null,
	selectedListContactResults: null,
	contactListItemsBreakdown: null,
	isContactListItemsLoading: false,
	searchBy: 'contact',

	moveFromListId: null,
	moveToListId: null,
	isMoveListItemsLoading: false,
	isMoveListModalOpen: false,

	isDeleteListItemsLoading: false,
	isDownloadListItemsLoading: false,
	isRevealSelectedListItemsLoading: false,

	contactFilters: getDefaultBrokerContactFilters(),
	contactsCheckedMap: {},

	contactsNotCheckedMap: {},
	isSelectAll: false,
	contactSearchInfinitePage: 0,
	selectedContactProfileUrl: null,
	cityStateSearchOptions: null,
	isCityStateSearchOptionsLoading: false,
	contactTypeTab: BROKER_CONTACT_TAB,
};

const listsDetailPageSlice = createSlice({
	name: 'listsDetailPage',
	initialState,
	reducers: {
		resetState(state) {
			resetToInitialState(state, initialState);
		},
		getAllListItemsForContactList(state, action: PayloadAction<any>) {
			const contactTypeTab = state.contactTypeTab;
			resetToInitialState(state, initialState);
			state.contactTypeTab = contactTypeTab;
			state.contactFilters = {
				...state.contactFilters,
				contactType: state.contactTypeTab === BROKER_CONTACT_TAB ? ContactTypeEnum.BROKER : ContactTypeEnum.EMPLOYER,
				listIds: [action.payload],
			};
			state.selectedListId = action.payload;
		},
		updateContactListItems(state, action: PayloadAction<ContactSearchResponse>) {
			state.anonymizedListContactResults = state.anonymizedListContactResults
				? state.anonymizedListContactResults.concat(action.payload.anonymizedContacts)
				: action.payload.anonymizedContacts;
			let currSelectedListContactResults = state.selectedListContactResults ? state.selectedListContactResults : {};
			state.selectedListContactResults = action.payload.selectedContacts?.reduce((a, b) => {
				a[b.profile_url] = b;
				return a;
			}, currSelectedListContactResults);
			state.isContactListItemsLoading = false;
		},
		getContact(state, action: PayloadAction<any>) {
			state.selectedContactProfileUrl = action.payload;
		},
		gotContact(state, action: PayloadAction<ISimpleContact>) {
			state.selectedListContactResults[action.payload.profile_url] = action.payload;
			state.selectedContactProfileUrl = null;
		},
		updateContactListItemsBreakdown(state, action: PayloadAction<any>) {
			state.contactListItemsBreakdown = action.payload;
		},
		updateContactCheckedMap(state, action: PayloadAction<any>) {
			state.contactsCheckedMap = action.payload;
		},
		updateContactNotCheckedMap(state, action: PayloadAction<any>) {
			state.contactsNotCheckedMap = action.payload;
		},
		setIsSelectAll(state, action: PayloadAction<any>) {
			state.isSelectAll = action.payload;
		},
		searchContactsInfinite(state) {
			state.contactSearchInfinitePage += 1;
			state.isContactListItemsLoading = true;
		},
		updateContactFilters(state, action: PayloadAction<any>) {
			const listId = state.selectedListId;
			const contactTypeTab = state.contactTypeTab;
			resetToInitialState(state, {
				...initialState,
				cityStateSearchOptions: state.cityStateSearchOptions,
				isCityStateSearchOptionsLoading: state.isCityStateSearchOptionsLoading,
			});
			state.selectedListId = listId;
			state.contactTypeTab = contactTypeTab;
			state.contactFilters = {
				...action.payload,
				contactType: state.contactTypeTab === BROKER_CONTACT_TAB ? 'BROKER' : 'EMPLOYER',
				listIds: [listId],
			};
			state.isContactListItemsLoading = true;
		},
		toggleMoveListModal(state) {
			state.isMoveListModalOpen = !state.isMoveListModalOpen;
		},
		moveListItems(state, action: PayloadAction<any>) {
			state.isMoveListModalOpen = false;
			state.moveFromListId = action.payload.fromListId;
			state.moveToListId = action.payload.toListId;
			state.isMoveListItemsLoading = true;
		},
		movedListItems(state) {
			state.contactsCheckedMap = {};
			state.isMoveListItemsLoading = false;
			state.moveFromListId = null;
			state.moveToListId = null;
		},
		deleteListItems(state) {
			state.isDeleteListItemsLoading = true;
		},
		deletedListItems(state) {
			state.isDeleteListItemsLoading = false;
		},
		moveListItemsFailed(state) {
			state.isMoveListItemsLoading = false;
		},
		downloadListItems(state) {
			state.isDownloadListItemsLoading = true;
		},
		downloadedListItems(state) {
			state.isDownloadListItemsLoading = false;
		},
		revealSelectedListItems(state) {
			state.isRevealSelectedListItemsLoading = true;
		},
		revealSelectedListItemsCompleted(state, action: PayloadAction<ISimpleContact[]>) {
			state.isRevealSelectedListItemsLoading = false;
			for (const contact of action.payload) {
				state.selectedListContactResults[contact.profile_url] = contact;
			}
		},
		searchContactLocation(state, action: PayloadAction<any>) {
			state.isCityStateSearchOptionsLoading = true;
		},
		searchContactLocationCompleted(state, action: PayloadAction<EntityLocation[]>) {
			state.isCityStateSearchOptionsLoading = false;
			state.cityStateSearchOptions = action.payload;
		},
		updateContactTypeTab(state, action: PayloadAction<number>) {
			state.contactTypeTab = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(appActions.setSelectedLoginAsTenant, (state, action) => initialState);
	},
});

export const { actions: listsDetailPageActions, reducer, name: sliceKey } = listsDetailPageSlice;
