import React from 'react';
import './style.scss';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import { Modal } from 'molecules/Modal';
import Title from 'atoms/Title';
import { TenantAttributes } from 'common.model/src/db/models';

export interface DeleteClientModalProps {
	isOpen: boolean;
	onDelete: (c: TenantAttributes) => any;
	onClose: () => any;
	client: TenantAttributes;
}

export const DeleteClientModal = ({ isOpen, onDelete, onClose, client }: DeleteClientModalProps) => {
	return (
		<Modal onClose={() => {}} isOpen={isOpen}>
			<Paper style={{ padding: '30px', minWidth: '400px' }}>
				<Container>
					<div style={{ textAlign: 'center' }}>
						<Title text={'Are you sure you want to delete this Client?'} />
					</div>
					<Row>
						<Col lg={{ span: 12 }} xl={{ span: 12 }}>
							<Form className="dev-form">
								<div className="form-content" style={{ marginBottom: '20px' }}>
									<p>All data for this Client ({client?.tenant_id}) will be deleted.</p>

									<p>This is a permanent action and cannot be undone.</p>
								</div>
								<div className="form-footer" style={{ textAlign: 'right' }}>
									<Button variant="secondary" style={{ marginRight: '12px' }} onClick={() => onClose()}>
										Cancel
									</Button>
									<Button onClick={() => onDelete(client)} variant="primary">
										Delete Client
									</Button>
								</div>
							</Form>
						</Col>
					</Row>
				</Container>
			</Paper>
		</Modal>
	);
};
