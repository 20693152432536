import { useRef, ReactNode } from 'react';
import cn from 'classnames';
import Menu from '@material-ui/core/Menu';
import { RiUserSettingsLine } from 'react-icons/ri';
import { IoChevronDownSharp } from 'react-icons/io5';

interface MenuButtonProps {
	children: ReactNode;
	isOpen: boolean;
	setIsOpen: (open: boolean) => void;
}

export const AssignMenu = ({ children, isOpen, setIsOpen }: MenuButtonProps) => {
	const anchorRef = useRef(null);

	return (
		<div>
			<button ref={anchorRef} className={cn('bf-btn', 'bf-btn-outline', 'x-pd', 'me-1')} onClick={() => setIsOpen(true)}>
				<RiUserSettingsLine className={cn('me-2')} size={20} />
				Assign
				<IoChevronDownSharp className={cn('ms-2')} size={18} />
			</button>
			<Menu id="simple-menu" anchorEl={isOpen ? anchorRef?.current : null} keepMounted open={isOpen} onClose={() => setIsOpen(false)}>
				{children}
			</Menu>
		</div>
	);
};
