import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FiEdit2 } from 'react-icons/fi';
import IconButton from '@material-ui/core/IconButton';
import { UpsertClientModal, UpsertClientModalProps } from '../UpsertClientModal';
import { Table, TableProps } from 'organisms/Table';
import DateUtils from 'utils/DateUtils';
import { FormattingUtils } from 'utils/index';
import { Panel } from 'atoms/Panel';
import { DeleteClientModal, DeleteClientModalProps } from '../DeleteClientModal';
import { FaTrashAlt } from 'react-icons/fa';

export interface ClientsTabProps {
	addClientModal: UpsertClientModalProps;
	editClientModal: UpsertClientModalProps;
	deleteClientModal: DeleteClientModalProps;
	table: TableProps;
	addClient: () => void;
	editClient: (client: any) => void;
	deleteClient: (client: any) => void;
}

export const ClientsTab = (props: ClientsTabProps) => {
	const clientColumns = [
		{
			name: 'Client Name',
			selector: (row: any) => row.tenant_id,
			sortable: true,
		},
		{
			name: 'Client Type',
			selector: (row: any) => {
				if (row.plan_type == 'TRIAL') {
					const days = DateUtils.getNumDaysBetween(new Date(), new Date(row.trial_end_date));
					return `Trial (${days} days left)`;
					// return `Trial`
				} else {
					return FormattingUtils.capitalizeFirstLetter(row.plan_type?.toLowerCase());
				}
			},
			sortable: true,
		},
		{
			name: 'Number of Licenses',
			selector: (row: any) => row.num_licenses,
			sortable: true,
		},
		{
			name: 'Credits Used',
			selector: (row: any) => row.credits_used,
			sortable: true,
		},
		{
			name: 'Total Credits',
			selector: (row: any) => row.credits,
			sortable: true,
		},
		{
			name: 'Unlimited Export',
			selector: (row: any) => (row?.allow_unlimited_bulk_export ? 'Enabled' : 'Disabled'),
			sortable: true,
		},
		{
			name: '',
			selector: (row: any) => (
				<IconButton
					onClick={() => {
						props.editClient(row);
					}}
				>
					<FiEdit2 />
				</IconButton>
			),
			sortable: true,
			width: '100px',
		},
		{
			name: '',
			selector: (row: any) => (
				<IconButton
					onClick={() => {
						props.deleteClient(row);
					}}
				>
					<FaTrashAlt size={18} />
				</IconButton>
			),
		},
	];

	return (
		<>
			<UpsertClientModal {...props.addClientModal} />
			<UpsertClientModal {...props.editClientModal} />
			<DeleteClientModal {...props.deleteClientModal} />
			<div style={{ textAlign: 'right', marginBottom: '1rem' }}>
				<Button className="float-right" onClick={props.addClient}>
					Add Client
				</Button>
			</div>
			<Row>
				<Col lg={{ span: 12 }} xl={{ span: 12 }}>
					<Panel className="f-h">
						<Table columns={clientColumns} {...props.table} />
					</Panel>
				</Col>
			</Row>
		</>
	);
};
