import { DropDownOptionType, GroupedOptionSet } from 'new-beginning/hooks/salesforce/useCrmFieldConfigurationData';
import { CrmIntegrationFieldDomainType } from 'common.model/src/types/services/integration/fieldMapping/FieldDomainType';
import { DecoratedFieldMapping, SourceOrTarget, mappingFieldMap } from 'common.model/src/db/model-decorators/type-extensions';

const { BENEFIT_FLOW, SALESFORCE, SALESFORCE_CONSTANT, CUSTOM_CONSTANT } = CrmIntegrationFieldDomainType;
const { SOURCE, TARGET } = SourceOrTarget;

// Option Setting and Label Formatting Utilities
export const getFieldMappingUpdate = (
	valueToSet: DropDownOptionType,
	sourceOrTarget: SourceOrTarget,
	picklistConstantSelected: boolean,
): Partial<DecoratedFieldMapping> => {
	const fieldToUpdate = mappingFieldMap[sourceOrTarget];

	const valueMapped: Partial<DecoratedFieldMapping> =
		valueToSet.field_domain_type === SALESFORCE_CONSTANT
			? {
					[fieldToUpdate]: {
						name: valueToSet?.name,
						field_domain_type: valueToSet?.field_domain_type,
						value: valueToSet?.picklistValue?.value,
					},
					targetField: {
						name: valueToSet?.name,
						field_domain_type: SALESFORCE,
					},
				}
			: {
					[fieldToUpdate]: {
						name: valueToSet?.name,
						field_domain_type: valueToSet?.field_domain_type,
						...(valueToSet?.value ? { value: valueToSet?.value } : {}),
					},
				};
	return valueMapped;
};

export const formatOptionLabel = (option: DropDownOptionType, sourceOrTarget: SourceOrTarget) => {
	if (option?.field_domain_type === SALESFORCE_CONSTANT) {
		return sourceOrTarget === SOURCE && option?.picklistValue
			? `"${option?.picklistValue?.label}" (${option?.label} Constant)`
			: `${option?.label} (${option?.type})`;
	} else if (option?.field_domain_type === CUSTOM_CONSTANT) {
		return `${option?.value} (${option?.type})`;
	}

	return `${option?.label} ${option?.type ? `(${option?.type})` : ''}`;
};

// Option Matching and Value Utilities
export const matchSelectedOption = (value: DropDownOptionType | null, fullOptionSet: (DropDownOptionType | GroupedOptionSet)[]): DropDownOptionType | null => {
	const selectedValue = getOptionValue(value);
	if (value?.field_domain_type === CUSTOM_CONSTANT) {
		return {
			...value,
			type: 'Custom Constant',
		};
	}

	let matchedOption = null;

	fullOptionSet.every((optionSet) => {
		const isOptionGroup = 'options' in optionSet;
		const found = isOptionGroup
			? optionSet?.options.find((opt) => getOptionValue(opt) === selectedValue)
			: getOptionValue(optionSet) === selectedValue
				? optionSet
				: false;

		matchedOption = !!found ? found : null;
		return !found;
	});

	return matchedOption;
};

export const valueGetterMethods = {
	[SALESFORCE_CONSTANT]: ({ field_domain_type, name, value, picklistValue }: DropDownOptionType) =>
		`${field_domain_type}.${name}.${picklistValue?.value ? picklistValue?.value : value}`,
	[CUSTOM_CONSTANT]: ({ field_domain_type, name, value }: DropDownOptionType) => `${field_domain_type}.${name}.${value}`,
	[SALESFORCE]: ({ field_domain_type, name }: DropDownOptionType) => `${field_domain_type}.${name}`,
	[BENEFIT_FLOW]: ({ field_domain_type, name }: DropDownOptionType) => `${field_domain_type}.${name}`,
};

export const getOptionValue = (option) => {
	const getter = option && valueGetterMethods[option.field_domain_type];
	return getter ? getter(option) : null;
};
