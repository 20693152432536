import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

export class LazyTextFieldProps {
	title: string;
	onSearch: (searchText: string) => void;
	searchText: string;
}

export const LazyTextField = (props: LazyTextFieldProps) => {
	const [searchText, setSearchText] = useState(null);
	const [timer, setTimer] = useState(null);

	const lazySearch = (searchText: string) => {
		if (!props.onSearch) {
			return;
		}
		clearTimeout(timer);
		const newTimer = setTimeout(() => {
			props.onSearch(searchText);
		}, 1000);
		setTimer(newTimer);
	};

	useEffect(() => {
		if (props.searchText && props.searchText !== searchText) {
			setSearchText(props.searchText?.slice());
		}
	}, [props.searchText]);

	return (
		<TextField
			size="small"
			value={searchText ?? ''}
			// Autofocus on textfield
			autoFocus
			placeholder="Type to search..."
			fullWidth
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon />
					</InputAdornment>
				),
			}}
			onChange={(e) => {
				setSearchText(e.target.value);
				lazySearch(e.target.value);
				e.stopPropagation();
			}}
			onKeyDown={(e) => {
				if (e.key !== 'Escape') {
					// Prevents autoselecting item while typing (default Select behaviour)
					e.stopPropagation();
				}
			}}
		/>
	);
};
