import React from 'react';
import { withStyles, Theme } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';

const StyledBackdrop = withStyles((theme: Theme) => ({
	root: {
		backdropFilter: 'blur(2px)',
	},
}))(Backdrop);

export default function CustomBackdrop(props) {
	return <StyledBackdrop open={true} />;
}
