import { useState, useEffect } from 'react';
import serviceUrl from 'utils/serviceUrl';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { useBasicApiRequestState, BasicApiRquestState, BaseApiReponse } from '../useBasicApiRequestState';
import { ListContactType, ContactListFilter } from 'common.model/src/db/model-decorators/type-extensions';
import { PaginationParams, SortKey, SortOrder } from 'common.model/src/types/Pagination';

interface ListItemResponse {
	contacts: ListContactType[];
	pagination: PaginationParams;
}

interface ListContactApiResponse {
	data: {
		res: {
			data: ListItemResponse;
		};
	};
}

export interface SetSortParams {
	sortKey: SortKey;
	sortOrder: SortOrder;
}

export interface ListContactResponse extends BaseApiReponse {
	listContacts: ListContactType[];
	refetchListContacts: () => void;
	paginationParams: PaginationParams;
	setPage: (pageNum: number) => void;
	setSortConfig: (args: SetSortParams) => void;
}

const defaultPagination = {
	offset: 0,
	limit: 50,
	sortKey: null,
	sortOrder: null,
	count: 0,
	numPages: 0,
};

interface ContactCacheEntry {
	listContactSet: ListContactType[];
	paginationParamSet: PaginationParams;
}

type ListContactCache = Record<string, ContactCacheEntry>;
type ContactFilterUrlCache = Record<ContactListFilter, string>;
const baseUrlCache = (): ContactFilterUrlCache => ({
	All: null,
	'Net-New': null,
	Updates: null,
	Synced: null,
});

const generateApiUrl = (baseUrl: string, paginationParams: PaginationParams, contactFilter: ContactListFilter): string => {
	const { offset, limit, sortKey, sortOrder } = paginationParams || {};
	const params = { ...{ offset, limit, sortKey, sortOrder, contactFilter } };
	const apiUrl = axios.getUri({ url: baseUrl, params });

	return apiUrl;
};

const listContactUrl = `${serviceUrl}/crm-integration/getListContacts`;
export const useListContacts = (listId: string): ListContactResponse => {
	const {
		baseHeaders,
		loading,
		tokenLoading,
		setLoading,
		requestApiSuccess,
		setApiRequestSuccess,
		requestErrorMessage,
		setRequestErrorMessage,
	}: BasicApiRquestState = useBasicApiRequestState();

	const apiUrlBase = `${listContactUrl}/${listId}`;
	const [searchParams] = useSearchParams();
	const [paginationParams, setPaginationParams] = useState<PaginationParams>(defaultPagination);

	const setPage = (offset) => setPaginationParams({ ...paginationParams, offset });
	const setSortConfig = (
		{ sortKey, sortOrder }: SetSortParams,
		// NOTE: Always reset page/offset to 0 when a User changes Sort Params
	) => setPaginationParams({ ...paginationParams, sortKey, sortOrder, offset: 0 });

	const contactFilter = (searchParams?.get('contactFilter') || ContactListFilter.ALL) as ContactListFilter;

	const [listContactCache, setListContactCache] = useState<ListContactCache>({});
	const [contactFilterUrlCache, setContactFilterUrlCache] = useState<ContactFilterUrlCache>(baseUrlCache());

	const initialApiUrl = generateApiUrl(apiUrlBase, paginationParams, contactFilter);
	const [currentApiUrl, setCurrentApiUrl] = useState<string>(initialApiUrl);
	const updateUrlCaches = (url: string) => {
		setCurrentApiUrl(url);
		setContactFilterUrlCache({ ...contactFilterUrlCache, [contactFilter]: url });
	};

	const setListContactsEntry = (listContactSet: ListContactType[], paginationParamSet: PaginationParams, resetCache: boolean = false) => {
		const apiUrlCacheKey = generateApiUrl(apiUrlBase, paginationParams, contactFilter);
		const cacheToUse = resetCache ? {} : listContactCache;
		const updatedCache: ListContactCache = {
			...cacheToUse,
			[apiUrlCacheKey]: {
				listContactSet,
				paginationParamSet,
			},
		};
		updateUrlCaches(apiUrlCacheKey);
		setListContactCache(updatedCache);
	};

	useEffect(() => {
		const lastApiUrlForFilter = contactFilterUrlCache?.[contactFilter];
		const lastPaginationParamsForFilter = listContactCache[lastApiUrlForFilter]?.paginationParamSet || defaultPagination;
		const paginationParamsToUse = lastApiUrlForFilter === currentApiUrl ? paginationParams : lastPaginationParamsForFilter;

		const newApiUrl = generateApiUrl(apiUrlBase, paginationParamsToUse, contactFilter);
		setPaginationParams(paginationParamsToUse);
		updateUrlCaches(newApiUrl);
	}, [contactFilter, paginationParams?.limit, paginationParams?.offset, paginationParams?.sortKey, paginationParams?.sortOrder]);

	const fetchListContacts = (forceRefetch: boolean = false) => {
		const cachedResult = listContactCache?.[currentApiUrl];
		if (!forceRefetch && !!cachedResult) {
			return;
		} else if (forceRefetch) {
			setContactFilterUrlCache(baseUrlCache());
		}
		setLoading(true);
		return axios
			.get<any, ListContactApiResponse>(currentApiUrl, {
				headers: { ...baseHeaders },
			})
			.then((res: ListContactApiResponse) => {
				const response = res?.data?.res?.data;

				setListContactsEntry(response?.contacts || [], response?.pagination, forceRefetch);
				setPaginationParams(response?.pagination);
				setApiRequestSuccess(true);
			})
			.catch((err: Error) => {
				setRequestErrorMessage(err.message);
				setApiRequestSuccess(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const refetchListContacts = () => {
		if (baseHeaders?.Authorization && !loading) {
			fetchListContacts(true);
		} else if (!tokenLoading && !loading) {
			console.warn('Cannot Fetch List Contacts without Token');
		}
	};

	useEffect(() => {
		if (baseHeaders?.Authorization && !loading) {
			fetchListContacts();
		} else if (!tokenLoading && !loading) {
			console.warn('Cannot Fetch List Contacts without Token');
		}
	}, [listId, currentApiUrl, baseHeaders?.Authorization]);

	const listContacts = listContactCache?.[currentApiUrl]?.listContactSet || [];

	return {
		listContacts,
		refetchListContacts,
		paginationParams,
		setPage,
		setSortConfig,
		loading,
		requestApiSuccess,
		requestErrorMessage,
	};
};
