import { ReactElement } from 'react';
import classnames from 'classnames';
import { TenantUser } from 'new-beginning/hooks/useTenantTeamInfo';
import { USER_TYPE_MAPPING } from 'new-beginning/constants/user';
import { FaUserEdit, FaTrashAlt } from 'react-icons/fa';

interface UserTableProps {
	tenantUsers: TenantUser[];
	setUserToEdit: (id: TenantUser | null) => void;
	setUserToDelete: (user: TenantUser) => void;
	loading: boolean;
}

export const UserTable = ({ tenantUsers, setUserToEdit, setUserToDelete, loading }: UserTableProps): ReactElement => {
	return (
		<table className={classnames('tenant-user-list', 'table', 'rounded')}>
			<thead className={classnames('user-table-header')}>
				<tr>
					<th>User</th>
					<th>Role</th>
					<th>Credits Used</th>
					<th>Credits Allocated</th>
					<th className="align-right">Actions</th>
				</tr>
			</thead>
			<tbody>
				{tenantUsers?.length ? (
					tenantUsers?.map((user: TenantUser) => (
						<tr className={classnames('rounded')} key={user?.id}>
							<td>
								<p>{user.name}</p>
								<p className={classnames('text-black-50')}>{user?.email}</p>
							</td>
							<td>{USER_TYPE_MAPPING?.[user?.user_type] || 'N/A'}</td>
							<td>
								<span className={classnames({ 'text-danger': user?.allocation_amount !== null && user?.credits_used > user?.allocation_amount })}>
									{user?.credits_used || 0}
								</span>
							</td>
							<td>{user?.allocation_amount !== null ? user?.allocation_amount : 'No Limit'}</td>
							<td className={classnames('align-right ')}>
								<button type="button" onClick={() => setUserToEdit(user)} className={classnames('btn', 'btn-outline-secondary', 'py-2', 'mx-1')}>
									<FaUserEdit size={18} />
								</button>
								<button
									type="button"
									disabled={!tenantUsers || tenantUsers?.length === 1}
									onClick={() => setUserToDelete(user)}
									className={classnames('btn', 'btn-outline-secondary', 'py-2', 'mx-1', 'hover-red')}
								>
									<FaTrashAlt size={18} />
								</button>
							</td>
						</tr>
					))
				) : (
					<tr>
						<td colSpan={5} align="center">
							{loading ? <span className={classnames('spinner-border', 'spinner-border-lg')} /> : <span>No Active Users Found</span>}
						</td>
					</tr>
				)}
			</tbody>
		</table>
	);
};
