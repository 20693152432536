import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';
import { listsDetailPageActions } from 'app/containers/ListsPage/containers/ListsDetailPage/slice';

export const useOnDelete = () => {
	const dispatch = useDispatch();
	const { contactFilters } = useSelector(selectListsDetailPage);

	const onDelete = useCallback(
		({ value, type }: { value: string; type: 'include' | 'exclude' }) =>
			() => {
				dispatch(
					listsDetailPageActions.updateContactFilters({
						...contactFilters,
						companyName: {
							include:
								type === 'include' ? contactFilters.companyName.include.filter((companyName) => companyName !== value) : contactFilters.companyName.include,
							exclude:
								type === 'exclude' ? contactFilters.companyName.exclude.filter((companyName) => companyName !== value) : contactFilters.companyName.exclude,
						},
					}),
				);
			},
		[dispatch, listsDetailPageActions, contactFilters],
	);

	return onDelete;
};
