import { useNavigate } from 'react-router-dom';
import { Box, Card } from '@benefitflow/designsystem';

import { useKeyPartners } from './hooks';
import { useEmployerId } from '../../../../../hooks';
import { Row, Description, DescriptionContent } from './components';

export const KeyPartners = () => {
	const navigate = useNavigate();
	const employerId = useEmployerId();
	const { isLoading, data } = useKeyPartners();

	return (
		<Card title="Key Partners">
			<Box display="grid" style={{ gridTemplateColumns: 'repeat(12,1fr)' }} my="sm" gap="lg">
				<Row>
					<Description icon="building-user" isEmpty={!isLoading && !data?.primaryBenefitsBroker} isLoading={isLoading} label="Primary Benefits Broker">
						<DescriptionContent.Clickable
							label={`${data?.primaryBenefitsBroker?.name} (${data?.primaryBenefitsBroker?.location})`}
							imageSrc={data?.primaryBenefitsBroker?.['logo']}
							onClick={() => {
								navigate(`/broker?brokerId=${data?.primaryBenefitsBroker?.id}&employer=${employerId}&brokerLocation=${data?.primaryBenefitsBroker?.location}`);
							}}
						/>
					</Description>
				</Row>
				<Row>
					<Description icon="building-shield" isEmpty={!isLoading && !data?.medicalCarrier} isLoading={isLoading} label="Primary Carrier">
						<DescriptionContent.Clickable label={data?.medicalCarrier?.name} />
					</Description>
				</Row>
				<Row>
					<Description icon="calculator" isEmpty={!isLoading && !data?.accountingFirm} isLoading={isLoading} label="Accounting Firm">
						<DescriptionContent.Clickable label={data?.accountingFirm?.name} />
					</Description>
				</Row>
				<Row>
					<Description icon="peo" isEmpty={!isLoading && !data?.peo} isLoading={isLoading} label="PEO">
						<DescriptionContent.Clickable label={data?.peo?.name} />
					</Description>
				</Row>
			</Box>
		</Card>
	);
};
