import { create } from 'zustand';
import { getEmptyEmployerFilters } from 'common.model/src/types/common/search/filters/CompanyFilters';

import { StoreUtils } from '../../../../utilities';
import { EmployersStore } from './Employers.types';

const FILTERS = [
	'participantRanges' as const,
	'brokerName' as const,
	'brokerSize' as const,
	'primaryBrokerOfficeLocations' as const,
	'carrierName' as const,
	'employerHQLocations' as const,
	'employerNameSearch' as const,
	'entityTypes' as const,
	'industries' as const,
	'lineOfBusiness' as const,
	'planTypes' as const,
	'renewalDatesRanges' as const,
	'employerPEOName' as const,
	'employerAccountantFirmName' as const,
	'employerMasterId' as const,
];

export const useEmployersStore = create<EmployersStore>((set) => ({
	...StoreUtils.createFilterVisibilityMethods(FILTERS, set),
	filters: getEmptyEmployerFilters(),
	clearFilters: () =>
		set({
			filters: getEmptyEmployerFilters(),
			filtersVisibilityMap: FILTERS.reduce((acc, cur) => ({ ...acc, [cur]: false }), {} as Record<(typeof FILTERS)[0], boolean>),
		}),
	setFilters: {
		participantRanges: StoreUtils.createRangeMethods('participantRanges', set),
		brokerName: StoreUtils.createIncludeExcludeMethods('brokerName', set),
		brokerSize: StoreUtils.createIncludeExcludeMethods('brokerSize', set),
		primaryBrokerOfficeLocations: StoreUtils.createLocationMethods('primaryBrokerOfficeLocations', set),
		carrierName: StoreUtils.createIncludeExcludeMethods('carrierName', set),
		employerHQLocations: StoreUtils.createLocationMethods('employerHQLocations', set),
		employerNameSearch: StoreUtils.createStringMethods('employerNameSearch', set),
		entityTypes: StoreUtils.createIncludeExcludeMethods('entityTypes', set),
		industries: StoreUtils.createIncludeExcludeMethods('industries', set),
		lineOfBusiness: StoreUtils.createIncludeExcludeMethods('lineOfBusiness', set),
		planTypes: StoreUtils.createIncludeExcludeMethods('planTypes', set),
		renewalDatesRanges: StoreUtils.createRangeMethods('renewalDatesRanges', set),
		employerPEOName: StoreUtils.createIncludeExcludeMethods('employerPEOName', set),
		employerAccountantFirmName: StoreUtils.createIncludeExcludeMethods('employerAccountantFirmName', set),
		employerMasterId: StoreUtils.createStringListMethods('employerMasterId', set),
	},
}));
