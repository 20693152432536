// @ts-nocheck
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Range, getTrackBackground } from 'react-range';
import styled from 'styled-components';
import omit from 'lodash-es/omit';
import debounce from 'lodash-es/debounce';

import { greyColor, greyLightColor } from 'designTokens/colors';

interface IProps {
	step: number;
	min: number;
	max: number;
	disabled?: boolean;
	values: number[];
	onChange: (e: any) => void;
	valueFormatter: (v: number) => any;
}

const TrackDiv = styled.div`
	height: 20px;
	display: flex;
	width: 100%;
	padding: 0px 5px;
`;

const TrackInnerDiv = styled.div<{ background: string }>`
	height: 5px;
	width: 100%;
	border-radius: 4px;
	align-self: center;
	background: ${(props) => props.background};
`;

const ThumbDiv = styled.div<{ disabled: boolean }>`
	height: 12px;
	width: 12px;
	border-radius: 50%;
	background-color: ${(props) => (props.disabled ? greyLightColor : greyColor)};
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ThumbInnerDiv = styled.div<{ left: string; right: string }>`
	position: absolute;
	top: 100%;
	left: ${(props) => props.left};
	right: ${(props) => props.right};
	color: #b7c2cf;
	font-size: 10px;
	margin-top: 7px;
	white-space: nowrap;
`;

const RangePicker = ({ step, min, max, disabled, values, onChange, valueFormatter }: IProps) => {
	const [innerValues, setInnerValues] = useState([min, max]);

	const debounceFn: any = useRef();

	useEffect(() => {
		setInnerValues(values);
	}, [values]);

	const updateValues = useCallback(
		(v: any) => {
			if (!disabled) {
				onChange(v);
			}
		},
		[disabled, onChange],
	);

	const onChangeValue = useCallback(
		(v: any) => {
			setInnerValues(v);

			if (!debounceFn || !debounceFn.current) {
				debounceFn.current = debounce((v) => updateValues(v), 1000);
			}

			if (debounceFn.current) {
				(debounceFn.current as any)(v);
			}
		},
		[updateValues],
	);

	const minRange = useMemo(() => {
		return min;
	}, [min]);

	const maxRange = useMemo(() => {
		return min === max ? max + 1 : max;
	}, [min, max]);

	return (
		<Range
			values={innerValues}
			step={step}
			min={minRange}
			max={maxRange}
			disabled={disabled}
			onChange={onChangeValue}
			renderTrack={({ props, children }) => (
				<TrackDiv onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} style={props.style}>
					<TrackInnerDiv
						ref={props.ref}
						background={getTrackBackground({
							values: innerValues,
							colors: [greyLightColor, greyColor, greyLightColor],
							min: minRange,
							max: maxRange,
						})}
					>
						{children}
					</TrackInnerDiv>
				</TrackDiv>
			)}
			renderThumb={({ props, value, index }) => (
				<ThumbDiv {...omit(props, ['onKeyDown', 'onKeyUp'])} style={props.style} disabled={disabled}>
					<ThumbInnerDiv left={index === 0 ? '0px' : 'unset'} right={index === 1 ? '0px' : 'unset'} style={props.style}>
						{!disabled && valueFormatter(value)}
					</ThumbInnerDiv>
				</ThumbDiv>
			)}
		/>
	);
};

export default RangePicker;
