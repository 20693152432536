import { useMemo } from 'react';
import { Box, Chart, Typography, Tooltip as DSTooltip } from '@benefitflow/designsystem';
import { ChartColors } from '@benefitflow/designsystem/dist/components/Chart/Chart.types';

import { FillChartData } from '../../../hooks';
import { DetailedInfoTooltip } from '../../../../DetailedInfoTooltip';

export const FillChart = ({
	data,
	colorMap,
	title,
	highestValue,
}: {
	data: FillChartData;
	title: string;
	highestValue: number;
	colorMap: Record<string, ChartColors>;
}) => {
	const width = useMemo(() => {
		const width = (data.total.raw * 100) / highestValue;
		return width > 5 ? `${width}%` : '5%';
	}, [highestValue, data]);
	if (!data?.parts?.length) return <Typography.Text>--</Typography.Text>;

	return (
		<Box width="full" display="flex" justifyContent="between" alignItems="center" gap="xs">
			<DSTooltip content={<DetailedInfoTooltip title={title} values={data.parts.map(({ name, value }) => ({ title: name, value: value.formatted }))} />}>
				{(ref) => (
					<Box style={{ width }} containerRef={ref}>
						<Chart.Fill
							fill
							parts={
								data?.parts?.map((part) => ({
									color: colorMap[part.name] ?? 'light-blue',
									data: { ...part },
									value: part.value.raw,
								})) ?? []
							}
							total={data.total.raw}
							loading={false}
						/>
					</Box>
				)}
			</DSTooltip>
			<Box width="xl2" style={{ textAlign: 'end' }}>
				<Typography.Text size="sm" whiteSpace="nowrap">
					{data.total.formatted}
				</Typography.Text>
			</Box>
		</Box>
	);
};
