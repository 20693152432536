import { create } from 'zustand';
import { EmployerProfileBenefitsTabStore } from './Benefits.types';
import { LineOfBusinessCategory } from 'common.model/src/types/services/employer-service/LineOfBusinessCategory';

export const useEmployerProfileBenefitsTabStore = create<EmployerProfileBenefitsTabStore>((set, get) => ({
	year: {
		value: '',
		setValue: (value) =>
			set((state) => ({
				year: { ...state.year, value },
			})),
	},
	brokerColorMap: {
		setValues: (values) =>
			set((state) => ({
				brokerColorMap: { ...state.brokerColorMap, values },
			})),
		values: {},
	},
	carrierColorMap: {
		setValues: (values) =>
			set((state) => ({
				carrierColorMap: { ...state.carrierColorMap, values },
			})),
		values: {},
	},
	selectedPolicies: {
		values: [LineOfBusinessCategory.ALL],
		setValues: (values) =>
			set((state) => ({
				selectedPolicies: { ...state.selectedPolicies, values },
			})),
		toggleValue: (value) =>
			set((state) => {
				if (value === LineOfBusinessCategory.ALL) {
					if (!state.selectedPolicies.values.includes(LineOfBusinessCategory.ALL))
						return {
							selectedPolicies: { ...state.selectedPolicies, values: [LineOfBusinessCategory.ALL] },
						};
					return {
						selectedPolicies: state.selectedPolicies,
					};
				}
				if (state.selectedPolicies.values.includes(LineOfBusinessCategory.ALL)) {
					return {
						selectedPolicies: { ...state.selectedPolicies, values: [value] },
					};
				}
				if (state.selectedPolicies.values.includes(value)) {
					if (state.selectedPolicies.values.length === 1)
						return {
							selectedPolicies: { ...state.selectedPolicies, values: [LineOfBusinessCategory.ALL] },
						};
					return {
						selectedPolicies: { ...state.selectedPolicies, values: state.selectedPolicies.values.filter((policy) => policy !== value) },
					};
				}
				return {
					selectedPolicies: { ...state.selectedPolicies, values: [...state.selectedPolicies.values, value] },
				};
			}),
	},
}));
