export default class AmplifyConfigManager {
	public static getAmplifyConfig() {
		if (import.meta.env.MODE == 'development') {
			return AmplifyConfigManager.getLocalConfig();
		} else if (import.meta.env.VITE_STAGE == 'dev') {
			return AmplifyConfigManager.getDevConfig();
		} else if (import.meta.env.VITE_STAGE == 'qa') {
			return AmplifyConfigManager.getQAConfig();
		} else if (import.meta.env.VITE_STAGE == 'prod') {
			return AmplifyConfigManager.getProdConfig();
		} else {
			throw new Error('Invalid stage: ' + import.meta.env.VITE_STAGE);
		}
	}

	private static getLocalConfig() {
		const config = {
			region: 'us-east-1',
			userPoolId: 'us-east-1_DEACkKfiV',
			userPoolWebClientId: '7ltjobak3hem76ftjv57o5avjr',
			oauth: {
				domain: 'benefit-flow-qa.auth.us-east-1.amazoncognito.com',
				scope: ['profile', 'openid', 'aws.cognito.signin.user.admin'],
				redirectSignIn: 'https://localhost:3000/Login',
				redirectSignOut: 'https://localhost:3000',
				responseType: 'code',
			},
		};
		return config;
	}

	private static getDevConfig() {
		const config = {
			region: 'us-east-1',
			userPoolId: 'us-east-1_0RDHv5Gte',
			userPoolWebClientId: '55vbac2heckm7sp8glgf8g044s',
			oauth: {
				domain: 'benefit-flow-dev.auth.us-east-1.amazoncognito.com',
				scope: ['profile', 'openid', 'aws.cognito.signin.user.admin'],
				redirectSignIn: 'https://dev.benefit-flow.com/Login',
				redirectSignOut: 'https://dev.benefit-flow.com',
				responseType: 'code',
			},
		};
		return config;
	}

	private static getQAConfig() {
		const config = {
			region: 'us-east-1',
			userPoolId: 'us-east-1_DEACkKfiV',
			userPoolWebClientId: '7ltjobak3hem76ftjv57o5avjr',
			oauth: {
				domain: 'benefit-flow-qa.auth.us-east-1.amazoncognito.com',
				scope: ['profile', 'openid', 'aws.cognito.signin.user.admin'],
				redirectSignIn: 'https://qa.benefit-flow.com/Login',
				redirectSignOut: 'https://qa.benefit-flow.com',
				responseType: 'code',
			},
		};
		return config;
	}

	private static getProdConfig() {
		const config = {
			region: 'us-east-1',
			userPoolId: 'us-east-1_hH6QJvQla',
			userPoolWebClientId: '65adhtumfcl9bblu3q8pot539h',
			oauth: {
				domain: 'benefit-flow-prod.auth.us-east-1.amazoncognito.com',
				scope: ['profile', 'openid', 'aws.cognito.signin.user.admin'],
				redirectSignIn: 'https://benefit-flow.com/Login',
				redirectSignOut: 'https://benefit-flow.com',
				responseType: 'code',
			},
		};
		return config;
	}
}
