import { AutoComplete, Box, Collapsible, InputLabel, Tag, Typography } from '@benefitflow/designsystem';
import { FiltersProps } from '../../Filters.types';
import { useMemo, useState } from 'react';
import { useOptions } from './hooks';
import { BulkSearchDialog } from './components/BulkSearchDialog/BulkSearchDialog';

export interface URLStringSearchFilterProps extends FiltersProps {
	stringSearchValue: string;
	onSelectStringSearch: (value: string) => void;
	onDeleteStringSearch: (value: string) => void;
	onSetMasterIds: (masterIds: string[]) => void;
	onClearMasterIds: () => void;
	masterIds: string[];
}

export const URLStringSearchFilter = ({
	title,
	onSelectStringSearch,
	stringSearchValue,
	onDeleteStringSearch,
	onSetMasterIds,
	onClearMasterIds,
	masterIds,
	description,
	icon,
	isOpen,
	onToggle,
	placeholder,
}: URLStringSearchFilterProps) => {
	const [search, onSearch] = useState('');
	const [hovering, setHovering] = useState(false);
	const options = useOptions({ search });
	const [isModalOpen, setIsModalOpen] = useState(false);
	const content = useMemo(() => {
		if (masterIds.length > 0) {
			return <Tag onDelete={() => onClearMasterIds()} label={`Bulk Search: ${masterIds.length} companies`} variant="primary" />;
		}
		return null;
	}, [masterIds, onClearMasterIds]);
	return (
		<>
			<Collapsible
				headerTestId="url-string-filter-header"
				isOpen={isOpen}
				toggle={onToggle}
				title={title}
				icon={icon}
				content={content}
				openContent={
					<div>
						<InputLabel label={description}>
							<AutoComplete
								onClear={() => onDeleteStringSearch(stringSearchValue)}
								mode="single"
								inputTestId="url-string-filter-input"
								selectedValues={[stringSearchValue]}
								loading={false}
								placeholder={placeholder}
								onSelect={({ value }) => onSelectStringSearch(value)}
								options={options}
								onSearch={onSearch}
							/>
						</InputLabel>
						<Box my="sm" cursor="pointer" onClick={() => setIsModalOpen(true)}>
							<Typography.Text
								color="primary-500"
								decoration={hovering ? 'underline' : undefined}
								onMouseEnter={() => setHovering(true)}
								onMouseLeave={() => setHovering(false)}
							>
								+ Bulk Search Companies
							</Typography.Text>
						</Box>
					</div>
				}
			/>
			<BulkSearchDialog isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} onSetMasterIds={onSetMasterIds} />
		</>
	);
};
