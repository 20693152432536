import { useMemo } from 'react';
import { Box, Card, Table } from '@benefitflow/designsystem';

import { Years } from '../Years/Years';
import { PoliciesSelector, PoliciesTable, Summaries } from './components';
import { useLoadColors, usePolicies, usePoliciesSummary } from './hooks';
import { useEmployerProfileBenefitsTabStore } from '../../Benefits.store';

export const PolicyInsights = () => {
	const ancillary = usePolicies({ type: 'ancillary' });
	const medical = usePolicies({ type: 'medical' });
	const { isLoading: summaryIsLoading, data: summary } = usePoliciesSummary();
	const { year } = useEmployerProfileBenefitsTabStore();
	const isEmpty = useMemo(
		() =>
			!ancillary.isLoading &&
			!medical.isLoading &&
			!summaryIsLoading &&
			!summary?.brokerCommissions?.values?.length &&
			!summary?.carrierPremiums?.values?.length &&
			!medical?.policies?.length &&
			!ancillary?.policies?.length,
		[summaryIsLoading, summary, medical, ancillary],
	);
	const empty = useMemo(
		() => (
			<Box m="lg">
				<Table.Empty style={{ height: '20rem' }} showBorder icon="duotone-building-magnifying-glass" label={`Data not reported in ${year.value} filings`} />
			</Box>
		),
		[year.value],
	);
	useLoadColors();

	return (
		<Card title="Policy Insights" extra={<Years />} defaultPadding={false}>
			{isEmpty ? (
				empty
			) : (
				<>
					<PoliciesSelector />
					<Box m="lg">
						<Summaries isLoading={summaryIsLoading} summary={summary} />
						<PoliciesTable title="Medical Policies" {...medical} />
						<PoliciesTable title="Ancillary Policies" {...ancillary} />
					</Box>
				</>
			)}
		</Card>
	);
};
