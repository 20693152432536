import { useRef } from 'react';
import { Box } from '@benefitflow/designsystem';

import { useOffices, useOnSeeContactsCallback } from './hooks';
import { useBrokerProfileStore } from '../../../../BrokerProfile.store';
import { BrokerOfficeCard } from '../../../../../Search/BrokerOfficeCardV2/BrokerOfficeCardV2';
import { useScrollToOfficeClient } from './hooks/useScrollToOfficeClient/useScrollToOfficeClient';

export const List = ({ minHeight }: { minHeight?: string | number }) => {
	const containerRef = useRef(null);
	const {
		clients: {
			rowExpandMap: { map, set, toggle },
		},
	} = useBrokerProfileStore();
	const { data, isLoading, isFetched } = useOffices();
	useScrollToOfficeClient({ isFetched, set, containerRef });
	const onSeeContactsCallback = useOnSeeContactsCallback();

	return (
		<Box containerRef={containerRef} display="flex" flexDirection="column" gap="sm" overflow="y-scroll" style={{ height: 'calc(100vh - 32rem)', minHeight }}>
			{isLoading
				? new Array(10).fill(null).map((_, index) => <BrokerOfficeCard key={index} loading />)
				: data.map(({ comissions, name, numberOfClients, premiums, location }, index) => (
						<BrokerOfficeCard
							key={index}
							location={location}
							isExpanded={map?.[name] ?? false}
							onSeeClientsCallback={() => toggle(name)}
							onSeeContactsCallback={onSeeContactsCallback(location)}
							cardId={name}
							commissions={comissions}
							name={name}
							numberOfClients={numberOfClients}
							premiums={premiums}
						/>
					))}
		</Box>
	);
};
