import React from 'react';
import styled from 'styled-components';
import ButtonMaterialUi from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Add from '@material-ui/icons/Add';
import DropDownPopover from '../filters/drop/DropDownPopover';
import CircularProgress from '@material-ui/core/CircularProgress';
import { maxContactOperationLimit } from 'common.services/src/search/constants';
import FormattingUtils from 'utils/FormattingUtils';

const StyledButton = styled(ButtonMaterialUi)`
	border-radius: 4px !important;
	height: 40px;
	padding: 0 18px !important;
	background-color: ${(props) => (props.disabled ? '#00000010' : '#185ADB')} !important;
	border: ${(props) => (props.disabled ? '1px solid transparent' : '1px solid #185ADB')} !important;
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')} !important;
	box-shadow: none !important;

	.MuiButton-label {
		font-family: 'Roboto';
		font-size: 14px;
		color: ${(props) => (props.disabled ? '#00000055' : '#FFFFFF')};
		text-transform: capitalize;
		font-weight: 700;
	}
`;

const StyledCircularProgress = styled(CircularProgress)`
	width: 24px !important;
	height: 24px !important;
	color: #ff2b2b !important;
`;

export interface AddToListButtonProps {
	disabled?: boolean;
	loading?: boolean;
	numberOfContacts: number;
	createList: () => void;
	getPopOverChildren: (closeMenu: () => void) => any[];
}

export const AddToListButton = ({ disabled, loading, createList, numberOfContacts, getPopOverChildren }: AddToListButtonProps) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const isMenuOpen = Boolean(anchorEl);
	const infoText =
		numberOfContacts >= maxContactOperationLimit ? `Only the first ${FormattingUtils.formatCommas(maxContactOperationLimit)} will be added` : undefined;
	const children = getPopOverChildren(handleMenuClose);
	children.push(
		<div
			key="create_list"
			style={{ width: '100%' }}
			onClick={() => {
				handleMenuClose();
				createList();
			}}
		>
			<Add /> Create New List
		</div>,
	);

	return (
		<div>
			<StyledButton aria-describedby="add-to-list-menu" onClick={handleMenuOpen} disabled={disabled} endIcon={loading ? <StyledCircularProgress /> : null}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						height: '100%',
					}}
				>
					<div>
						<span>Add To List</span>
						{isMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
					</div>
				</div>
			</StyledButton>
			<DropDownPopover anchorEl={anchorEl} handleMenuClose={handleMenuClose} children={children} infoText={infoText} />
		</div>
	);
};
