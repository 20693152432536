import { useAddNotification } from '@benefitflow/designsystem';
import { EmployerExportPageProps } from 'pages/EmployerPage/organisms/ExportPage';
import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

const useOnClickExportPDF = (props: EmployerExportPageProps, contentRef: React.RefObject<HTMLDivElement>) => {
	const addNotification = useAddNotification();
	const [isLoading, setIsLoading] = useState(false);

	const handlePrint = useReactToPrint({
		contentRef: contentRef,
		onBeforePrint: () => {
			setIsLoading(true);
			return new Promise<void>((resolve) => {
				setTimeout(() => {
					resolve();
				}, 1000); // Add a 1-second delay
			});
		},
		onAfterPrint: () => {
			setIsLoading(false);
			addNotification({
				type: 'success',
				title: '',
				message: 'The PDF has been successfully exported.',
				autoClose: true,
			});
		},
		onPrintError: (error) => {
			console.error('Print failed:', error);
			addNotification({ type: 'error', title: 'Export Failed', message: 'Unable to export PDF. Please try again.', autoClose: true });
		},
	} as any);

	return { handlePrint, contentRef, isLoading };
};

export default useOnClickExportPDF;
