import React from 'react';
import ButtonMaterialUi from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import { FaLayerGroup } from 'react-icons/fa';

export interface GetSelectedButtonProps {
	className?: string;
	title?: string;
	disabled?: boolean;
	loading?: boolean;
	onClick?: () => void;
}

const Button = styled(ButtonMaterialUi)`
	border-radius: 4px !important;
	height: 40px;
	padding: 0 18px !important;
	background-color: ${(props) => (props.disabled ? '#00000010' : '#185ADB')} !important;
	border: ${(props) => (props.disabled ? '1px solid transparent' : '1px solid #185ADB')} !important;
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')} !important;
	box-shadow: none !important;

	.MuiButton-label {
		font-family: 'Roboto';
		font-size: 14px;
		color: ${(props) => (props.disabled ? '#00000055' : '#FFFFFF')};
		text-transform: capitalize;
		font-weight: 700;
	}
`;

const StyledCircularProgress = styled(CircularProgress)`
	width: 24px !important;
	height: 24px !important;
	color: #ff2b2b !important;
`;

export const GetSelectedButton = ({ className, title, disabled, loading, onClick, ...rest }: GetSelectedButtonProps) => {
	return (
		<Button
			className={className}
			variant="contained"
			color="default"
			disabled={disabled}
			onClick={onClick}
			startIcon={<FaLayerGroup style={{ color: '#FFF' }} />}
			endIcon={loading ? <StyledCircularProgress /> : null}
			{...rest}
		>
			{title}
		</Button>
	);
};
