import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';
import { listsDetailPageActions } from 'app/containers/ListsPage/containers/ListsDetailPage/slice';

export const useOnDelete = () => {
	const dispatch = useDispatch();
	const { contactFilters: filters } = useSelector(selectListsDetailPage);
	const onDelete = useCallback(() => {
		dispatch(
			listsDetailPageActions.updateContactFilters({
				...filters,
				yearsAtCompany: [0, 21],
			}),
		);
	}, [dispatch, listsDetailPageActions, filters]);

	return onDelete;
};
