import { useRef } from 'react';
import { Box, Divider, Skeleton } from '@benefitflow/designsystem';
import { LineOfBusinessCategory } from 'common.model/src/types/services/employer-service/LineOfBusinessCategory';

import { Policy } from './components';
import { useEmployerProfileBenefitsTabStore } from '../../../../Benefits.store';
import { useClearSelectionOnYearChange, useIsOverflowed, usePolicies } from './hooks';

export const PoliciesSelector = () => {
	const { selectedPolicies } = useEmployerProfileBenefitsTabStore();
	const { isLoading, count } = usePolicies();
	const containerRef = useRef<HTMLDivElement>(null);
	const isOverflowed = useIsOverflowed(containerRef);
	useClearSelectionOnYearChange();

	if (isLoading)
		return (
			<Box display="flex" p="lg" flexWrap="wrap" gap="md">
				{new Array(9).fill(<Skeleton borderRadius="md" height="xl3" width="xl6" />)}
			</Box>
		);
	if (Object.values(count).every((count) => !count)) return null;

	return (
		<Box
			containerRef={containerRef}
			display="flex"
			gap="md"
			overflow="x-auto"
			style={{
				backgroundColor: '#F9FAFA',
				boxShadow: isOverflowed ? 'inset -5px 0 4px -3px rgba(0, 0, 0, 0.1)' : undefined,
			}}
			p="lg"
		>
			<Policy
				isSelected={selectedPolicies.values.includes(LineOfBusinessCategory.ALL)}
				onClick={() => selectedPolicies.toggleValue(LineOfBusinessCategory.ALL)}
				name="All Policies"
				icon="duotone-shield"
				count={count?.[LineOfBusinessCategory.ALL] ?? 0}
			/>
			<Box height="xl3">
				<Divider direction="vertical" mx="lg" color="neutral-300" />
			</Box>

			{[
				{
					name: 'Medical',
					icon: 'duotone-heart' as const,
					value: LineOfBusinessCategory.MEDICAL,
				},
				{
					name: 'Dental',
					icon: 'duotone-tooth' as const,
					value: LineOfBusinessCategory.DENTAL,
				},
				{
					name: 'Vision',
					icon: 'duotone-glasses' as const,
					value: LineOfBusinessCategory.VISION,
				},
				{
					name: 'Life',
					icon: 'duotone-family' as const,
					value: LineOfBusinessCategory.LIFE,
				},
				{
					name: 'Disability',
					icon: 'duotone-wheelchair' as const,
					value: LineOfBusinessCategory.DISABILITY,
				},
				{
					name: 'Critical Illness',
					icon: 'duotone-ribbon' as const,
					value: LineOfBusinessCategory.CRITICAL_ILLNESS,
				},
				{
					name: 'AD&D',
					icon: 'duotone-domestic-accident' as const,
					value: LineOfBusinessCategory.AD_AND_D,
				},
				{
					name: 'All Other',
					icon: 'duotone-circle-ellipsis' as const,
					value: LineOfBusinessCategory.OTHER,
				},
			].map((policy) => (
				<Policy
					isSelected={selectedPolicies.values.includes(policy.value)}
					count={count?.[policy.value] ?? 0}
					onClick={() => selectedPolicies.toggleValue(policy.value)}
					{...policy}
				/>
			))}
		</Box>
	);
};
