import cn from 'classnames';
import { SalesforceSobjectAccountInstance } from 'common.model/src/db/models';
import { ScrollTable } from 'new-beginning/components/pages/ListSync/SyncTable/ScrollTable';
import { SuccessPill } from 'new-beginning/components/shared/Pills';
import { FaSalesforce } from 'react-icons/fa';

const thTextClass = cn('bf-txt', 'txt-bolder', 'txt-lighter');

interface ParentAssignTableProps {
	salesforceAccounts: SalesforceSobjectAccountInstance[];
	selectedRow?: string;
	selectRow?: (id: string) => void;
	accountWebsite: string;
}

export const ParentAssignTable = ({ salesforceAccounts, selectedRow = null, selectRow, accountWebsite }: ParentAssignTableProps) => {
	return (
		<>
			<div className={cn('d-flex', 'align-items-center', 'mb-2')}>
				<FaSalesforce className={cn('bf-icon', 'ic-xl', 'ic-salesforce', 'me-3')} />
				<div className={cn('d-flex', 'flex-column')}>
					<p className={cn('bf-txt', 'txt-bolder', 'txt-neutral')}>Select a Parent Account</p>
					<p className={cn('bf-txt', 'txt-xs', 'txt-sm', 'txt-lighter')}>We found the following Accounts in your CRM</p>
				</div>
			</div>
			<ScrollTable tableType="account-assign-table" scrollTrigger={true} containerClassNames={cn('account-resolve-table-container', 'mt-1')}>
				<thead className={cn('sticky-header')}>
					<tr>
						<th />
						<th>
							<p className={thTextClass}>Account Name</p>
						</th>
						<th>
							<p className={thTextClass}>Account Id</p>
						</th>
					</tr>
				</thead>
				<tbody>
					{salesforceAccounts?.length ? (
						salesforceAccounts?.map((salesforceAccount) => {
							const accountId = salesforceAccount?.salesforce_record_uid;
							const parentRow = salesforceAccount?.account_type === 'PARENT_ACCOUNT';

							return (
								<tr className={cn('related-account-row')} key={salesforceAccount?.salesforce_record_uid}>
									<td>
										<input
											className={cn('form-check-input', 'cursor-ptr', 'bf-checkbox', 'ms-3')}
											type="checkbox"
											checked={selectedRow === accountId}
											onClick={(e) => {
												e.stopPropagation();
											}}
											onChange={() => selectRow(accountId)}
										/>
									</td>
									<td>
										<div className={cn('d-flex', 'flex-column')}>
											<div className={cn('d-flex', 'align-items-center')}>
												<p className={cn('bf-txt', 'txt-dark')}>{salesforceAccount?.Name || '[Account Missing Name]'}</p>
											</div>
											{parentRow && (
												<div>
													<SuccessPill className={cn('bf-txt', 'txt-xxs')} isVisible label="Likely Parent Account" />
												</div>
											)}
										</div>
									</td>
									<td>
										<div>
											<p className={cn('bf-txt', 'txt-xs', 'txt-lighter')}>{salesforceAccount?.salesforce_record_uid || 'Missing ID'}</p>
										</div>
									</td>
								</tr>
							);
						})
					) : (
						<tr className={cn('related-account-row')}>
							<td colSpan={4}>
								<div className={cn('d-flex', 'flex-column', 'justify-content-center', 'align-items-center', 'py-2')}>
									<p className={cn('bf-txt', 'txt-lighter', 'txt-md')}>No Accounts Found</p>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</ScrollTable>
		</>
	);
};
