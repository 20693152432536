import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';
import { listsDetailPageActions } from 'app/containers/ListsPage/containers/ListsDetailPage/slice';

export const useOnSelect = () => {
	const dispatch = useDispatch();
	const { contactFilters } = useSelector(selectListsDetailPage);

	const onSelect = useCallback(
		({ value }: { value: string }) => {
			dispatch(
				listsDetailPageActions.updateContactFilters({
					...contactFilters,
					jobTitles: {
						include: [...contactFilters.jobTitles.include, value],
						exclude: contactFilters.jobTitles.exclude,
					},
				}),
			);
		},
		[dispatch, listsDetailPageActions, contactFilters],
	);

	return onSelect;
};
