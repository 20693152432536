import cn from 'classnames';
import { ContactTabCounts } from 'common.model/src/db/model-decorators/type-extensions';
import { ReactNode } from 'react';

interface TabHeaderProps {
	tabList: string[];
	tabCounts: ContactTabCounts;
	currentTab: string | number;
	setCurrentTab: (tabName) => void;
	tabEnabledState: {
		[name: string]: boolean;
	};
}

export const TabHeader = ({ tabList, tabCounts, currentTab, setCurrentTab, tabEnabledState }: TabHeaderProps) => {
	return (
		<div className={cn('d-flex', 'justify-content-evenly', 'mt-2')}>
			{tabList.map((name) => (
				<Tab
					label={name}
					key={name}
					tabCount={tabCounts?.[name]}
					isActive={name === currentTab}
					handleChange={() => setCurrentTab(name)}
					isLocked={!tabEnabledState?.[name]}
				/>
			))}
		</div>
	);
};

interface TabProps {
	label: string;
	tabCount: number;
	handleChange: () => void;
	isActive: boolean;
	isLocked: boolean;
}

export const Tab = ({ label, tabCount, handleChange, isActive, isLocked }: TabProps) => {
	return (
		<div className={cn('me-1')}>
			<button
				className={cn('bf-btn', 'bf-btn-tab', 'border-0', { 'is-active': isActive, disabled: isLocked })}
				disabled={isActive || isLocked}
				onClick={handleChange}
			>
				{label}
				<span className={cn('ms-2', 'bf-pill', 'dynamic-pill', 'pill-unchanged', { 'pill-primary': isActive })}>{tabCount || 0}</span>
			</button>
		</div>
	);
};

interface TabContentProps {
	children: ReactNode;
	isActive: boolean;
	className?: string;
}

export const TabContent = ({ children, isActive, className }: TabContentProps) => {
	return isActive && <div className={className}>{children}</div>;
};
