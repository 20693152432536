import { ReactNode } from 'react';
import { Box, TextButton, Typography } from '@benefitflow/designsystem';

import { IncludeExcludeFilter, LocationFilter, RangeFilter, CustomRangeFilter, Section, CheckboxFilter, StringSearchFilter } from './components';
import { URLStringSearchFilter } from './components/URLStringSearchFilter/URLStringSearchFilter';

export const Filters = ({ children, onClear }: { children: ReactNode; onClear: () => void }) => {
	return (
		<Box width="full">
			<Box width="full" mb="sm" mt="xs" display="flex" justifyContent="between" alignItems="center">
				<Typography.Text whiteSpace="nowrap" size="lg" weight="semi-bold" color="neutral-900">
					Filters
				</Typography.Text>
				<Box>
					<TextButton onClick={onClear}>Clear All</TextButton>
				</Box>
			</Box>
			{children}
		</Box>
	);
};

Filters.IncludeExcludeFilter = IncludeExcludeFilter;
Filters.LocationFilter = LocationFilter;
Filters.RangeFilter = RangeFilter;
Filters.CustomRangeFilter = CustomRangeFilter;
Filters.Section = Section;
Filters.CheckboxFilter = CheckboxFilter;
Filters.StringSearchFilter = StringSearchFilter;
Filters.URLStringSearchFilter = URLStringSearchFilter;
