import { FC } from 'react';
import { ListTable } from './ListTable';
import { Box, Tabs } from '@benefitflow/designsystem';
import { ListType } from 'common.model/src/db/model-decorators/type-extensions';
import { create } from 'zustand';
interface ListPageProps {
	isSalesforceVisible: boolean;
}

const useListPageStore = create<{ selectedListType: ListType; setSelectedListType: (t: ListType) => void }>((set) => ({
	selectedListType: ListType.BROKER_CONTACT,
	setSelectedListType: (selectedListType: ListType) => set({ selectedListType }),
}));

export const ListPage: FC<ListPageProps> = ({ isSalesforceVisible }) => {
	const { selectedListType, setSelectedListType } = useListPageStore();
	return (
		<Box display="flex" flexDirection="column" m="sm">
			<Tabs align="start" value={selectedListType} onChange={(listType: ListType) => setSelectedListType(listType)}>
				<Tabs.Tab value={ListType.BROKER_CONTACT} label="Broker Contacts" icon="users">
					<ListTable isSalesforceVisible={isSalesforceVisible} listType={ListType.BROKER_CONTACT} />
				</Tabs.Tab>
				<Tabs.Divider />
				<Tabs.Tab value={ListType.EMPLOYER_CONTACT} label="Employer Contacts" icon="users">
					<ListTable isSalesforceVisible={isSalesforceVisible} listType={ListType.EMPLOYER_CONTACT} />
				</Tabs.Tab>
			</Tabs>
		</Box>
	);
};
