import { useState, createContext, useEffect } from 'react';
import AmplifyTokenManager from 'utils/AmplifyTokenManager';
import { CognitoUser } from '@aws-amplify/auth';

const tokenManager: AmplifyTokenManager = AmplifyTokenManager.getInstance();

interface UserAuth {
	accessToken: {
		jwtToken: string;
		payload: {
			'cognito:groups': string[];
		};
	};
	idToken: { jwtToken: string };
	refreshToken: { token: string };
}

interface UseAttributes {
	email: string;
	email_verified: boolean;
	name: string;
	sub: string;
}

export interface LoggedInUser extends CognitoUser {
	username: string;
	signInUserSession: UserAuth;
	attributes: UseAttributes;
	storage: {
		defaultTenant: string;
		apc_user_id: string;
	};
	tenantIdOverride?: string | null;
}

export interface UserContextType {
	loadingAuth: boolean;
	authUser: LoggedInUser | null;
	setTenantIdOverride: (id: string) => void;
}

export const LoggedInUserContext = createContext<UserContextType>({
	authUser: null,
	setTenantIdOverride: (id) => null,
	loadingAuth: false,
});

export const WithLoggedInUser = ({ children }) => {
	const [loadingAuth, setLoadingAuth] = useState(true);
	const [authUser, setAuthUser] = useState<LoggedInUser | null>(null);
	// TODO: tenantIdOverride Needs to be handled Server-side on the User table and should not rely on a Req Header
	const setTenantIdOverride = (tenantIdOverride: string) => {
		if (authUser) {
			localStorage.setItem('defaultTenant', tenantIdOverride);
			setAuthUser(
				Object.assign({}, authUser, {
					tenantIdOverride,
				}),
			);
		}
	};

	useEffect(() => {
		const getUserAuth = async () => {
			setLoadingAuth(true);
			tokenManager
				.getLoggedInAuther()
				.then((user: LoggedInUser) => {
					setAuthUser(
						Object.assign({}, user, {
							tenantIdOverride: localStorage.getItem('defaultTenant'),
						}),
					);
				})
				.catch((err) => {
					setAuthUser(null);
					localStorage.removeItem('defaultTenant');
				})
				.finally(() => {
					setLoadingAuth(false);
				});
		};
		if (!authUser) {
			getUserAuth();
		}
	}, [authUser?.username]);

	return <LoggedInUserContext.Provider value={{ authUser, setTenantIdOverride, loadingAuth }}>{children}</LoggedInUserContext.Provider>;
};
