import { useState, useEffect } from 'react';
import serviceUrl from 'utils/serviceUrl';
import ITenant from 'types/ITenant';
import axios from 'axios';
import { useBasicApiRequestState, BasicApiRquestState, BaseApiReponse } from './useBasicApiRequestState';

export interface TenantUser {
	id: string;
	tenant_id: string;
	user_type: string;
	name: string;
	email: string;
	credits_used?: number;
	allocation_amount?: number | null;
}

export interface TenantTeam extends ITenant {
	credits_used?: number;
}

export interface TenantUsage {
	tenant: TenantTeam;
	users: TenantUser[];
}

export interface TenantTeamResponse {
	tenantTeamInfo: TenantUsage;
	refetchTeamData: () => void;
	loading: boolean;
	errorMessage: string;
}

const tenantTeamInfoUrl = `${serviceUrl}/tenant/getTenantTeamInfo`;
export const useTenantTeamInfo = (): TenantTeamResponse => {
	const { baseHeaders, loading, setLoading, requestApiSuccess, setApiRequestSuccess, requestErrorMessage, setRequestErrorMessage }: BasicApiRquestState =
		useBasicApiRequestState();
	const [teamData, setTeamData] = useState<TenantUsage | null>(null);

	const fetchTenantData = () => {
		setLoading(true);
		setRequestErrorMessage(null);
		return axios
			.get(tenantTeamInfoUrl, {
				headers: { ...baseHeaders },
			})
			.then((response) => {
				setTeamData(response?.data?.res?.data);
			})
			.catch((err) => {
				setRequestErrorMessage(err.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (baseHeaders?.Authorization) {
			fetchTenantData();
		} else {
			console.warn('Cannot Fetch Team Info without Token');
		}
	}, [baseHeaders?.Authorization, baseHeaders?.bf_login_as_tenant]);

	return {
		loading,
		errorMessage: requestErrorMessage,
		tenantTeamInfo: teamData,
		refetchTeamData: fetchTenantData,
	};
};
