import React from 'react';
import './style.scss';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import { Modal } from 'molecules/Modal';
import Title from 'atoms/Title';
import { Box } from '@benefitflow/designsystem';

export interface DeleteListModalProps {
	isOpen: boolean;
	onDelete: () => any;
	onClose: () => any;
	list: any;
}

export const DeleteListModal = ({ isOpen, onDelete, onClose, list }: DeleteListModalProps) => {
	return (
		<Modal onClose={() => {}} isOpen={isOpen}>
			<Paper style={{ padding: '30px', minWidth: '400px' }}>
				<Box mx="md">
					<div style={{ textAlign: 'center' }}>
						<Title text={'Are you sure you want to delete this list?'} />
					</div>
					<Row>
						<Col lg={{ span: 12 }} xl={{ span: 12 }}>
							<Form className="dev-form">
								<div className="form-content" style={{ marginBottom: '20px' }}>
									<p>All contacts in this list ({list?.name}) will still be available in your [My Contacts] list.</p>

									<p>This is a permanent action and cannot be undone.</p>
								</div>
								<div className="form-footer" style={{ textAlign: 'right' }}>
									<Button variant="secondary" style={{ marginRight: '12px' }} onClick={() => onClose()}>
										Cancel
									</Button>
									<Button onClick={() => onDelete()} variant="primary">
										Delete List
									</Button>
								</div>
							</Form>
						</Col>
					</Row>
				</Box>
			</Paper>
		</Modal>
	);
};
