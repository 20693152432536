import React from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import './style.scss';
import { FormattingUtils } from 'utils/index';

ChartJS.register(ArcElement, Tooltip, Legend);

export interface PieChartProps {
	className?: any;
	title?: string;
	data: any;
	valueTitle: string;
	percentageTitle: string;
}

function getToolTipHtml(name, value, totalValue, valueTitle, percentageTitle) {
	return `
    <div>
      <table>
        <thead style="width:100%">
          <tr>
            <th style="padding: 3px">${name}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="padding: 3px">${valueTitle}:</td>
            <td style="padding: 3px; padding-left: 20px;">${FormattingUtils.formatFinancial(value)}</td>
          </tr>
          <tr>
            <td style="padding: 3px">${percentageTitle}:</td>
            <td style="padding: 3px; padding-left: 20px;">${((value / totalValue) * 100).toFixed(2)}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  `;
}

export const PieChart = ({ className, title, data, valueTitle, percentageTitle }: PieChartProps) => {
	const total = data?.datasets[0].data.reduce((a, b) => a + b, 0);

	return (
		<div className={`chart-bloc ${className}`}>
			<div className="bloc-content">
				<Doughnut
					data={data}
					options={{
						cutout: 33,
						responsive: true,
						maintainAspectRatio: false,
						plugins: {
							title: {
								display: false,
							},
							legend: {
								display: true,
								position: 'right',
								align: 'start',
								labels: {
									// usePointStyle:true,
									boxWidth: 13,
									font: {
										size: 12,
									},
								},
							},
							tooltip: {
								// Disable the on-canvas tooltip
								enabled: false,
								backgroundColor: '#227799',
								external: function (context) {
									// Tooltip Element
									let tooltipEl = document.getElementById('chartjs-tooltip');

									// Create element on first render
									if (!tooltipEl) {
										tooltipEl = document.createElement('div');
										tooltipEl.id = 'chartjs-tooltip';
										tooltipEl.innerHTML = '<table></table>';
										document.body.appendChild(tooltipEl);
									}

									// Hide if no tooltip
									const tooltipModel = context.tooltip;
									if (tooltipModel.opacity === 0) {
										tooltipEl.style.opacity = '0';
										return;
									}

									// Set caret Position
									tooltipEl.classList.remove('above', 'below', 'no-transform');
									if (tooltipModel.yAlign) {
										tooltipEl.classList.add(tooltipModel.yAlign);
									} else {
										tooltipEl.classList.add('no-transform');
									}

									// Set Text
									const dataPoint = tooltipModel.dataPoints[0];
									const html = getToolTipHtml(dataPoint.label, dataPoint.raw, total, valueTitle, percentageTitle);

									const tableRoot = tooltipEl.querySelector('table');
									tableRoot.innerHTML = html;

									const position = context.chart.canvas.getBoundingClientRect();
									// const bodyFont = ChartJS.helpers.toFont(tooltipModel.options.bodyFont);

									// Display, position, and set styles for
									tooltipEl.style.opacity = '1';
									tooltipEl.style.position = 'absolute';
									tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
									tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
									tooltipEl.style.padding = 10 + 'px';
									tooltipEl.style.pointerEvents = 'none';
									tooltipEl.style.backgroundColor = 'rgba(0,0,0,0.8)';
									tooltipEl.style.color = 'white';
									tooltipEl.style.borderRadius = 5 + 'px';
								},
							},
						},
					}}
				/>
			</div>
			{title && <h3 className="bloc-title"> {title} </h3>}
		</div>
	);
};
