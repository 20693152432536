/**
 *
 * Asynchronously loads the component for IntegrationsPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const IntegrationsPageController = lazyLoad(
	() => import('./index'),
	(module) => module.IntegrationsPageController,
);
