import './style.scss';
import React from 'react';
import { BsChevronLeft } from 'react-icons/bs';

interface Props {
	className?: string;
	text?: string;
	subtitle?: string;
	count?: string;
	backBtn?: boolean;
	backBtnAction?: any;
}

const Component = ({ className, text, count, subtitle, backBtn, backBtnAction }: Props) => {
	return (
		<div className={`dev-title ${className ?? ''}`}>
			{backBtn && (
				<button className="back-btn" onClick={backBtnAction}>
					{' '}
					<i className="ico">
						{' '}
						<BsChevronLeft />{' '}
					</i>{' '}
					Back{' '}
				</button>
			)}
			{text} {count ? <span>{count}</span> : ''}
			{subtitle && <p className={`subtitle ${backBtn ? 'm-t' : ''}`}>{subtitle}</p>}
		</div>
	);
};

export default Component;
