import { PayloadAction } from '@reduxjs/toolkit';
import { ContainerState } from './types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { LoadingBannerProps } from 'molecules/Banner';
import { TenantAttributes } from 'common.model/src/db/models/Tenant';

export const initialState: ContainerState = {
	status: 'LOADING',
	isAuthenticated: false,
	isLoggedOut: false,
	idToken: null,
	decodedIdToken: null,
	tenants: null,
	loginAsTenant: null,
	updateTokenInProgress: false,
	updateTokenDone: false,
	errorMessages: [],
	successMessages: [],
	loadingBanners: [],
};

const appPageSlice = createSlice({
	name: 'appPage',
	initialState,
	reducers: {
		loggedIn(state, action: PayloadAction<any>) {
			state.isAuthenticated = true;
			state.isLoggedOut = false;
			state.idToken = action?.payload?.['idToken'];
			state.decodedIdToken = action?.payload?.['decodedIdToken'];
		},
		logout(state) {},
		loggedOut(state) {
			state.idToken = null;
			state.isAuthenticated = false;
			state.isLoggedOut = true;
		},
		updateIsLoggedOut(state, action: PayloadAction<any>) {
			state.isLoggedOut = action.payload;
		},
		updateTenants(state, action: PayloadAction<TenantAttributes[]>) {
			state.tenants = action.payload;
		},
		setSelectedLoginAsTenant(state, action: PayloadAction<any>) {
			localStorage.setItem('defaultTenant', action.payload);
			state.loginAsTenant = action.payload;
		},
		getUpdatedToken(state) {
			state.updateTokenInProgress = true;
		},
		updateToken(state) {
			state.updateTokenInProgress = false;
			state.updateTokenDone = true;
		},
		addErrorMessage(state, action: PayloadAction<any>) {
			const cpy = state.errorMessages.slice();
			cpy.push(action.payload);
			state.errorMessages = cpy;
		},
		addSuccessMessage(state, action: PayloadAction<any>) {
			const cpy = state.successMessages.slice();
			cpy.push(action.payload);
			state.successMessages = cpy;
		},
		addLoadingBanner(state, action: PayloadAction<LoadingBannerProps>) {
			console.log(`adding loading banner with id ${action.payload.id} and message ${action.payload.message}`);
			const existingBanner = state.loadingBanners.find((banner) => banner.id === action.payload.id);
			if (existingBanner) {
				existingBanner.message = action.payload.message;
				return;
			}
			const cpy = state.loadingBanners.slice();
			cpy.push(action.payload);
			state.loadingBanners = cpy;
		},
		updateLoadingBanner(state, action: PayloadAction<LoadingBannerProps>) {},
		onCloseErrorMessage(state, action: PayloadAction<any>) {
			const cpy = state.errorMessages.slice();
			cpy.splice(action.payload, 1);
			state.errorMessages = cpy;
		},
		onCloseSuccessMessage(state, action: PayloadAction<any>) {
			const cpy = state.successMessages.slice();
			cpy.splice(action.payload, 1);
			state.successMessages = cpy;
		},
		onCloseLoadingBanner(state, action: PayloadAction<string>) {
			console.log(`onCloseLoadingBanner for ${action.payload}`);
			const cpy = state.loadingBanners.filter((banner) => banner.id !== action.payload);
			state.loadingBanners = cpy;
		},
		startExportPolling(state, action: PayloadAction<StartExportPollingPayload>) {},
		stopExportPolling(state, action: PayloadAction<StopExportPollingPayload>) {},
		downloadExport(state, action: PayloadAction<string>) {},
	},
});

export type StartExportPollingPayload = {
	exportId: string;
};

export type StopExportPollingPayload = {
	exportId: string;
};

export const { actions: appActions, reducer, name: sliceKey } = appPageSlice;
