import { Box, Skeleton } from '@benefitflow/designsystem';

import { useYears } from './hooks';
import { Year } from './components';

export const Years = () => {
	const { isLoading, years } = useYears();

	if (isLoading)
		return (
			<Box display="flex" gap="xs">
				{new Array(3).fill(null).map(() => (
					<Skeleton height="xl" width="xl2" />
				))}
			</Box>
		);

	return (
		<Box display="flex" gap="sm">
			{years.map((year) => (
				<Year value={year} />
			))}
		</Box>
	);
};
