import { useMemo } from 'react';
import { Box, Button, Icon, Typography } from '@benefitflow/designsystem';
import { IconName } from '@benefitflow/designsystem/dist/components/Icon/Icon.types';

export const Policy = ({
	name,
	icon,
	count,
	onClick,
	isSelected,
}: {
	name: string;
	icon: `${IconName}`;
	count: number;
	onClick: () => void;
	isSelected: boolean;
}) => {
	const policiesLabel = useMemo(() => `${count} ${count === 1 ? 'Policy' : 'Policies'}`, [count]);
	const isDisabled = useMemo(() => count < 1, [count]);
	const colors = useMemo(() => {
		if (isDisabled)
			return {
				icon: 'neutral-400' as const,
				iconBackground: 'neutral-200' as const,
				title: 'neutral-500' as const,
				count: 'neutral-400' as const,
			};
		return {
			icon: 'primary-500' as const,
			iconBackground: 'primary-200' as const,
			title: 'neutral-700' as const,
			count: 'neutral-500' as const,
		};
	}, [isDisabled]);

	return (
		<Button.Selectable isDisabled={isDisabled} onClick={onClick} isSelected={isSelected} padding="md">
			<Box display="flex" gap="md">
				<Box height="xl" width="xl" display="flex" justifyContent="center" alignItems="center" backgroundColor={colors.iconBackground} borderRadius="md">
					<Icon color={colors.icon} name={icon} size="lg" />
				</Box>
				<Box display="flex" flexDirection="column" gap="xxs" justifyContent="between">
					<Typography.Text color={colors.title} whiteSpace="nowrap" weight="bold" size="sm">
						{name}
					</Typography.Text>
					<Typography.Text whiteSpace="nowrap" size="xs" color={colors.count}>
						{policiesLabel}
					</Typography.Text>
				</Box>
			</Box>
		</Button.Selectable>
	);
};
