import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';

export const useValues = () => {
	const {
		contactFilters: { companyName },
	} = useSelector(selectListsDetailPage);

	const values = useMemo(
		() => [
			...companyName?.include?.map((companyName) => ({ value: companyName, label: companyName, type: 'include' as const })),
			...companyName?.exclude?.map((companyName) => ({ value: companyName, label: companyName, type: 'exclude' as const })),
		],

		[companyName],
	);

	return values;
};
