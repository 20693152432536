import { useMemo } from 'react';

export const useOptions = ({ search }: { search: string }) => {
	const options = useMemo(
		() => [
			...(search.trim() != ''
				? [
						{
							value: search,
							label: search,
							data: {},
						},
					]
				: []),
		],
		[search],
	);

	return options;
};
