import { useState } from 'react';
import serviceUrl from 'utils/serviceUrl';
import axios from 'axios';
import { ContactListFilter, SyncResultWithAccountDiff } from 'common.model/src/db/model-decorators/type-extensions';
import { useBasicApiRequestState, BasicApiRquestState, BaseApiReponse } from '../useBasicApiRequestState';
import { useNotificationContext, NotificationType } from 'new-beginning/context/NotificationContext';

export interface ListSyncResponse extends BaseApiReponse {
	contactSyncResult: SyncResultWithAccountDiff;
	syncListContacts: (contactFitler: ContactListFilter, contactsSelected?: Record<string, boolean>, callback?: Function) => Promise<void>;
}

type CallbackFunction = () => void;

const listSyncUrl = `${serviceUrl}/crm-integration/syncContacts`;
export const useSyncListContacts = (listId: string): ListSyncResponse => {
	const {
		baseHeaders,
		loading,
		tokenLoading,
		setLoading,
		requestApiSuccess,
		setApiRequestSuccess,
		requestErrorMessage,
		setRequestErrorMessage,
	}: BasicApiRquestState = useBasicApiRequestState();

	const { setNotification, clearNotification } = useNotificationContext();
	const [contactSyncResult, setContactSyncResult] = useState<SyncResultWithAccountDiff>(null);

	const syncListContacts = (
		syncContactFilter: ContactListFilter = ContactListFilter.ALL,
		contactsSelected = {},
		callback: CallbackFunction = () => null,
	): Promise<void> => {
		const allSelected = contactsSelected?.['ALL'] === true;
		const listItemIds = allSelected
			? []
			: Object.entries(contactsSelected).reduce((memo, [itemId, isSelected]) => {
					if (itemId && isSelected === true) {
						memo.push(itemId);
					}
					return memo;
				}, []);
		const hasSelection = allSelected || listItemIds?.length !== 0;
		const contactFilter = hasSelection ? syncContactFilter : ContactListFilter.ALL;

		setContactSyncResult(null);
		setLoading(true);
		clearNotification();
		return axios
			.post(`${listSyncUrl}/${listId}`, { listId, contactFilter, listItemIds }, { headers: { ...baseHeaders } })
			.then((res) => {
				const response = res?.data?.res;
				if (response?.success === false && response?.message) {
					throw new Error(response.message);
				}

				const syncedItems: SyncResultWithAccountDiff = response?.data || null;
				const { numContactsSynced, numContactsFailed } = syncedItems?.contactSyncItems.reduce(
					(memo, syncItem) => {
						const syncStatus = syncItem?.status;
						if (syncStatus === 'SYNCED') {
							memo.numContactsSynced += 1;
						} else {
							memo.numContactsFailed += 1;
						}

						return memo;
					},
					{ numContactsSynced: 0, numContactsFailed: 0 },
				);

				const numAccountsToAssign = syncedItems?.accountDiffIdsToAssign?.length || 0;
				const contactSuccessMsg =
					numContactsSynced > 0
						? `${numContactsSynced} ${numContactsSynced > 1 ? 'Contacts' : 'Contact'} successfully synced to your CRM.`
						: 'No Contacts were Synced.';
				const contactFailureMsg =
					numContactsFailed > 0
						? ` ${numContactsFailed} ${numContactsFailed > 1 ? 'Contacts' : 'Contact'} failed to sync to your CRM; check your list for detailed errors.`
						: '';
				const accountAssignMsg =
					numAccountsToAssign > 0 ? ` ${numAccountsToAssign} Net New Accounts require a Parent Account assignment before their Contacts can be created.` : '';

				const syncMessage = contactSuccessMsg + contactFailureMsg + accountAssignMsg;

				if (numContactsSynced === 0 && numAccountsToAssign === 0) {
					throw new Error(syncMessage);
				}

				setNotification(syncMessage, NotificationType.INFO);
				setContactSyncResult(syncedItems);
				setApiRequestSuccess(true);
			})
			.catch((err: Error) => {
				setNotification(err.message, NotificationType.ERROR);
				setRequestErrorMessage(err.message);
				setApiRequestSuccess(false);
			})
			.finally(() => {
				callback();
				setLoading(false);
			});
	};

	return {
		syncListContacts,
		contactSyncResult,
		loading,
		requestApiSuccess,
		requestErrorMessage,
	};
};
