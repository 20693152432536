import { ReactNode, useMemo } from 'react';
import { Box } from '@benefitflow/designsystem';

import { useScreenSize } from '../../../../../../../../../hooks/useScreenSize';

export const Row = ({ children }: { children: ReactNode }) => {
	const screenSize = useScreenSize();
	const gridSpan = useMemo(() => {
		if (screenSize === 'sm') return 12;
		if (screenSize === 'md') return 4;
		return 3;
	}, [screenSize]);

	return <Box gridSpan={gridSpan}>{children}</Box>;
};
