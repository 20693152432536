import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import { ChevronDown, ChevronUp } from 'react-iconly';
import Divider from '@material-ui/core/Divider';
import { ListSubheader } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { LazyTextField } from '../../../LazyTextField';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: 0,
			display: 'flex',
		},
		underline: {
			'&:before': {
				opacity: 0.3,
				borderBottom: '0.4px solid #B7C2CF;',
			},
			'&:after': {
				opacity: 0.3,
				borderBottom: '0.4px solid #B7C2CF;',
			},
		},
		inputLabelFocused: {
			color: '#B7C2CF !important',
			fontSize: '12px',
			fontFamily: 'Roboto',
			lineHeight: '12.1px',
			fontWeight: 400,
			top: '-16px',
		},
		selectSelected: {
			'&:focus': {
				backgroundColor: 'transparent',
			},
		},
		inputFormControl: {
			marginTop: '0 !important',
		},
		menuPaper: {
			maxHeight: '250px',
			background: '#FFFFFF',
			border: '0.5px solid rgba(0, 0, 0, 0.09);',
			boxSizing: 'border-box',
			boxShadow: '2px 4px 31px rgba(0, 0, 0, 0.07)',
			transitionDuration: '0s !important',
			'&::-webkit-scrollbar': {
				width: '4px',
				height: '0px',
			},
			'&::-webkit-scrollbar-track': {
				background: '#f1f1f1',
			},
			'&::-webkit-scrollbar-thumb': {
				background: '#C4C4C4',
				borderRadius: '2px',
			},
			'&::-webkit-scrollbar-thumb:hover': {
				background: '#555',
			},
		},
		chips: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		chip: {
			margin: 2,
			maxWidth: '100%',
			backgroundColor: '#dee2e6',
			'&:hover': {
				backgroundColor: '#F4F4F4',
			},
		},
		noLabel: {
			marginTop: theme.spacing(3),
		},
		menuItem: {
			'&:focus': {
				backgroundColor: 'transparent',
			},
			'&:hover': {
				backgroundColor: 'rgba(24, 90, 219, 0.09) !important',
			},
		},
	}),
);

export class OptionConfigProps {
	option: any;
	formattedOption?: any;
	count?: number;
}

export class ValueConfigProps {
	value: string;
	formattedValue?: any;
}

export class DropDownProps {
	title?: string;
	values: ValueConfigProps[];
	onChange: (newValues) => any;
	options: OptionConfigProps[];
	isDeleteable?: boolean;
	asyncSearch?: DropDownAsyncSearchProps;
	controlledOpen?: DropDownControlledOpenProps;
	disableSearch?: boolean;
	disableMultiSelect?: boolean;
	maxRenderedOptions?: number;
}

export class DropDownAsyncSearchProps {
	isLoading: boolean;
	onSearch: (searchText: string) => void;
	searchValue: string;
	updateSearchText: (searchText: string) => void;
}

export class DropDownControlledOpenProps {
	isOpen: boolean;
	onChangeIsOpen: (isOpen: boolean) => void;
}

export const DropDown = (props: DropDownProps) => {
	const classes = useStyles();
	const [localIsOpen, setLocalIsOpen] = useState(false);
	const [localSearchText, setLocalSearchText] = useState('');

	// Initial render
	useEffect(() => {
		const isValidIsOpen = props?.controlledOpen?.isOpen !== undefined && props?.controlledOpen?.isOpen !== null;
		if (isValidIsOpen && props?.controlledOpen?.isOpen !== localIsOpen) {
			setLocalIsOpen(props?.controlledOpen?.isOpen);
		}
		const isValidSearchText = props?.asyncSearch?.searchValue !== undefined && props?.asyncSearch?.searchValue !== null;
		if (isValidSearchText && props?.asyncSearch?.searchValue !== localSearchText) {
			setLocalSearchText(props?.asyncSearch?.searchValue);
		}
	}, []);

	useEffect(() => {
		const isValidIsOpen = props?.controlledOpen?.isOpen !== undefined && props?.controlledOpen?.isOpen !== null;
		if (isValidIsOpen && props?.controlledOpen?.isOpen !== localIsOpen) {
			setLocalIsOpen(props?.controlledOpen?.isOpen);
		}
	}, [props?.controlledOpen?.isOpen]);

	useEffect(() => {
		const isValidSearchText = props?.asyncSearch?.searchValue !== undefined && props?.asyncSearch?.searchValue !== null;
		if (isValidSearchText && props?.asyncSearch?.searchValue !== localSearchText) {
			setLocalSearchText(props?.asyncSearch?.searchValue);
		}
	}, [props?.asyncSearch?.searchValue]);

	useEffect(() => {}, [localIsOpen, localSearchText]);

	return (
		<div className="styled-dropdown">
			<FormControl className={classes.formControl}>
				<InputLabel classes={{ root: classes.inputLabelFocused, focused: classes.inputLabelFocused }} shrink={false} id="demo-simple-select-label">
					{props.values.length > 0 ? '' : props.title}
				</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					classes={{
						select: classes.selectSelected,
					}}
					style={{ backgroundColor: 'transparent' }}
					value={props.values}
					onChange={(e) => {
						e.stopPropagation();
						if (props.disableMultiSelect) {
							props.onChange(e.target.value);
						} else if (e?.target?.value?.[0]) {
							const arr: any[] = e.target.value as any[];
							const vals = arr.filter((x) => x !== 'undefined').map((x) => (x.value ? x.value : x));
							props.onChange(vals);
						}
					}}
					multiple={!props.disableMultiSelect}
					MenuProps={{
						classes: { paper: classes.menuPaper },
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'left',
						},
						getContentAnchorEl: null,
						autoFocus: false,
					}}
					input={
						<Input
							classes={{
								underline: classes.underline,
								formControl: classes.inputFormControl,
							}}
						/>
					}
					IconComponent={(props) => {
						if (props.className.includes('iconOpen')) {
							return <ChevronUp style={{ position: 'absolute', right: 0, height: 20, width: 20 }} set="light" primaryColor="#B7C2CF" />;
						} else {
							return <ChevronDown style={{ position: 'absolute', right: 0, height: 20, width: 20 }} set="light" primaryColor="#B7C2CF" />;
						}
					}}
					renderValue={(selected) => (
						<div className={classes.chips}>
							{props.values.map((value) =>
								props.isDeleteable ? (
									<Chip
										key={value.value}
										label={value.formattedValue ? value.formattedValue : value.value}
										clickable
										deleteIcon={props.isDeleteable ? <CancelIcon onMouseDown={(event) => event.stopPropagation()} /> : null}
										className={classes.chip}
										onDelete={(e) => {
											const filtered = props.values.filter((x) => x != value).map((x) => x.value);
											props.onChange(filtered);
										}}
									/>
								) : (
									<Chip key={value.value} label={value.formattedValue ? value.formattedValue : value.value} clickable className={classes.chip} />
								),
							)}
						</div>
					)}
					onOpen={() => {
						setLocalIsOpen(true);
						if (props?.controlledOpen?.onChangeIsOpen) {
							props?.controlledOpen?.onChangeIsOpen(true);
						}
					}}
					open={localIsOpen}
					onClose={() => {
						setLocalIsOpen(false);
						if (props?.controlledOpen?.onChangeIsOpen) {
							props?.controlledOpen?.onChangeIsOpen(false);
							if (props?.asyncSearch?.onSearch) {
								props?.asyncSearch?.onSearch('');
								props?.asyncSearch?.updateSearchText('');
							}
						}
					}}
				>
					{props.disableSearch ? null : (
						<ListSubheader style={{ paddingTop: '10px', backgroundColor: 'white' }}>
							<LazyTextField
								title={props.title}
								searchText={localSearchText}
								onSearch={(searchText: string) => {
									setLocalSearchText(searchText);
									if (props?.asyncSearch?.onSearch) {
										props?.asyncSearch?.onSearch(searchText);
										props?.asyncSearch?.updateSearchText(searchText);
									}
								}}
							/>
						</ListSubheader>
					)}
					{props?.asyncSearch?.isLoading ? (
						<div style={{ paddingRight: '16px', paddingLeft: '16px' }}>
							<Skeleton />
						</div>
					) : props.options ? (
						props.options
							.filter((curOption) => {
								const text = curOption.formattedOption ? curOption.formattedOption : curOption.option;
								if (localSearchText && !props.asyncSearch) {
									return text.toLowerCase().includes(localSearchText.toLowerCase());
								}
								return true;
							})
							.slice(0, props.maxRenderedOptions)
							.map((curOption, ix) => {
								return (
									<MenuItem
										key={curOption.option + ix}
										value={curOption.option}
										className={classes.menuItem}
										disabled={props.values.map((x) => x.value).includes(curOption.option)}
									>
										{curOption.formattedOption ? curOption.formattedOption : curOption.option}
										<Divider style={{ position: 'absolute', bottom: 0, width: 'calc(100% - 30px)', left: '15px', opacity: '0.3', color: '#B7C2CF' }} />
									</MenuItem>
								);
							})
					) : (
						[]
					)}
					{/* </div> */}
				</Select>
			</FormControl>
		</div>
	);
};
