import { useMemo } from 'react';
import { Box, Table, TextButton } from '@benefitflow/designsystem';
import { UseTableParams } from '@benefitflow/designsystem/dist/components/Table/hooks/useTable.types';

import { Policy } from '../../hooks';
import { FillChart } from './components';
import { TableTitle } from '../../../TableTitle';
import { useEmployerProfileBenefitsTabStore } from '../../../../Benefits.store';

export const PoliciesTable = ({
	policies,
	title,
	isLoading,
	isFetching,
	highestValues,
	totalCount,
	fetchNextPage,
	hasNextPage,
}: {
	isLoading: boolean;
	totalCount: number;
	isFetching: boolean;
	hasNextPage: boolean;
	fetchNextPage: () => void;
	title: string;
	policies: Policy[];
	highestValues: {
		brokerCommission: number;
		carrierPremium: number;
	};
}) => {
	const isEmpty = useMemo(() => !isLoading && !policies.length, [isLoading, policies]);
	const { brokerColorMap, carrierColorMap } = useEmployerProfileBenefitsTabStore();
	const empty = useMemo(
		() => (
			<Table.Empty
				style={{ height: '16rem' }}
				icon="duotone-building-magnifying-glass"
				label={`This view is empty based on your filters. Try adjusting your selections to see more data.`}
			/>
		),
		[],
	);
	const columns = useMemo<UseTableParams<Policy>['columns']>(
		() => [
			{ title: 'Benefit', key: 'benefit', cellAlign: 'left', headerAlign: 'left' },
			{ title: 'Covered Lives', key: 'lives', cellAlign: 'left', headerAlign: 'left' },
			{ title: 'Renewal', key: 'renewal', borderRight: true, cellAlign: 'left', headerAlign: 'left' },
			{
				title: 'Broker Commissions',
				key: 'brokerCommissions',
				render: ({ brokerCommissions }) => (
					<FillChart highestValue={highestValues.brokerCommission} title="Broker Commissions" data={brokerCommissions} colorMap={brokerColorMap.values} />
				),
				borderRight: true,
			},
			{
				title: 'Carrier Premiums',
				key: 'carrierPremiums',
				render: ({ carrierPremiums }) => (
					<FillChart highestValue={highestValues.carrierPremium} title="Carrier Premiums" data={carrierPremiums} colorMap={carrierColorMap.values} />
				),
				borderRight: true,
			},
			{ title: 'PEPM', key: 'pepm', cellAlign: 'right', headerAlign: 'right' },
		],
		[brokerColorMap.values, carrierColorMap.values, highestValues],
	);
	const tableProps = Table.use<Policy>({
		columns: columns ?? [],
		data: policies ?? [],
		loading: isLoading,
		isEmpty,
		empty,
		isFetching,
		skeleton: () => (
			<Table.Skeleton
				numberOfRows={5}
				columns={[
					{ height: 'sm', width: 'xl3' },
					{ height: 'sm', width: 'xl3' },
					{ height: 'sm', width: 'xl3' },
					{ height: 'sm', width: 'xl3' },
					{ height: 'sm', width: 'xl3' },
					{ height: 'sm', width: 'xl3' },
				]}
			/>
		),
	});

	return (
		<Box display="flex" flexDirection="column" my="lg">
			<TableTitle isLoading={isLoading} title={title} count={totalCount} />
			<Table {...tableProps} />
			{hasNextPage && !isLoading && !isFetching && (
				<Box height="xl" display="flex" justifyContent="center" alignItems="center" width="full" mt="sm">
					<Box>
						<TextButton onClick={fetchNextPage}>Load More</TextButton>
					</Box>
				</Box>
			)}
		</Box>
	);
};
