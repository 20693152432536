import { Box } from '@benefitflow/designsystem';

import { KeyPartners, PolicyInsights, ServiceProviders } from './components';

export const Benefits = () => {
	return (
		<Box display="flex" flexDirection="column" gap="md">
			<KeyPartners />
			<PolicyInsights />
			<ServiceProviders />
		</Box>
	);
};
