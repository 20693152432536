import { Route, Routes } from 'react-router-dom';
import PrivateRoute from 'routes/PrivateRoute';
import { EmployerPageController } from 'app/containers/EmployerPage/Loadable';
import { ListsDetailPageController } from 'app/containers/ListsPage/containers/ListsDetailPage/Loadable';
// import { SearchPageController } from 'app/containers/SearchPage/Loadable';
import { ProviderPageController } from 'app/containers/ProviderPage/Loadable';
// import { BrokerPageController } from 'app/containers/BrokerPage/Loadable';
import { ListPage } from 'new-beginning/components/pages/List/ListPage';
import { SalesforceSyncPage } from 'new-beginning/components/pages/ListSync/SalesforceSyncPage';
import { Search } from '../new-beginning/components/pages/Search/Search';
import { BrokerProfile } from '../new-beginning/components/pages/BrokerProfile/BrokerProfile';

interface StandardAuthRoutesProps {
	isAuthed: boolean;
	isAdmin: boolean;
	loading: boolean;
}

export const AuthRoutes = ({ isAuthed, isAdmin, loading }: StandardAuthRoutesProps) => (
	<Routes>
		<Route element={<PrivateRoute isAuthenticated={isAuthed} loadingAuth={loading} />}>
			<Route path="Search" element={<Search />} />
			<Route path="Provider" element={<ProviderPageController />} />
			<Route path="Broker" element={<BrokerProfile />} />
			<Route path="Lists" element={<ListPage isSalesforceVisible />} />
			<Route path="lists/sync/:listId" element={<SalesforceSyncPage isAdmin={isAdmin} />} />
			<Route path="Lists/:listId" element={<ListsDetailPageController />} />
			<Route path="Employer/:employer_master_id" element={<EmployerPageController />} />
		</Route>
	</Routes>
);
