import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import {
	BrokerFilters,
	EmployerFilters,
	getEmptyBrokerFilters,
	getEmptyEmployerFilters,
	getEmptyProviderFilters,
	ProviderFilters,
} from 'common.model/src/types/common/search/filters/CompanyFilters';

// The initial state of the FilterBar container
export const initialState: ContainerState = {
	providerFilters: getEmptyProviderFilters(),
	brokerFilters: getEmptyBrokerFilters(),
	employerFilters: getEmptyEmployerFilters(),
	filtersOpenMap: {},
	filtersSearchMap: {},
	isNewBrokerSearch: false,
};

const filterBarSlice = createSlice({
	name: 'filterBar',
	initialState,
	reducers: {
		updateBrokerFilters(state, action: PayloadAction<{ filters: BrokerFilters; fieldChanged?: string }>) {
			state.brokerFilters = action.payload.filters;
			state.isNewBrokerSearch = true;
		},
		updateEmployerFilters(state, action: PayloadAction<{ filters: EmployerFilters; fieldChanged?: string }>) {
			state.employerFilters = action.payload.filters;
		},
		updateProviderFilters(state, action: PayloadAction<{ filters: ProviderFilters; fieldChanged?: string }>) {
			state.providerFilters = action.payload.filters;
		},
		updateFiltersOpenMap(state, action: PayloadAction<any>) {
			state.filtersOpenMap = action.payload;
		},
		updateFiltersSearchMap(state, action: PayloadAction<any>) {
			state.filtersSearchMap = action.payload;
		},
		clearFilters(state, action: PayloadAction<any>) {
			switch (action.payload['searchBy']) {
				case 1:
					state.employerFilters = getEmptyEmployerFilters();
					break;
				case 2:
					state.providerFilters = getEmptyProviderFilters();
					break;
				case 0:
					state.isNewBrokerSearch = true;
					state.brokerFilters = getEmptyBrokerFilters();
					break;
			}
		},
	},
});

export const { actions: filterBarActions, reducer, name: sliceKey } = filterBarSlice;
