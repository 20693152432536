import { useContext } from 'react';
import { APIContext } from 'new-beginning/services/api/Api.context';

export const useDeleteList = ({ listId }: { listId: number }) => {
	const { listsApi } = useContext(APIContext);
	const query = listsApi.useGetAllListsForUser();
	const { mutate, loading } = listsApi.useDeleteList(
		{ listId: listId },
		{
			onSuccess: () => {
				query.query.refetch();
			},
		},
	);
	return { onDelete: mutate, isDeleting: loading };
};
