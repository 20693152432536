import serviceUrl from 'utils/serviceUrl';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useBasicApiRequestState, BasicApiRquestState, BaseApiReponse } from '../useBasicApiRequestState';
import { SalesforceSobjectUserInstance } from 'common.model/src/db/models';

export type SalesforceUsersById = Record<string, SalesforceSobjectUserInstance>;
interface ContactOwnerResponse extends BaseApiReponse {
	getContactOwners: () => Promise<void>;
	salesforceUsers: SalesforceSobjectUserInstance[];
	salesforceUsersById: SalesforceUsersById;
}

const getSalesforceUsersUrl = `${serviceUrl}/crm-integration/getSalesforceUsers`;
export const useFetchContactOwners = (listId: string): ContactOwnerResponse => {
	const { baseHeaders, loading, setLoading, requestApiSuccess, setApiRequestSuccess, requestErrorMessage, setRequestErrorMessage }: BasicApiRquestState =
		useBasicApiRequestState();

	const [salesforceUsers, setSalesforceUsers] = useState<SalesforceSobjectUserInstance[]>(null);

	const getContactOwners = () => {
		setLoading(true);
		return axios
			.get(`${getSalesforceUsersUrl}/${listId}`, { headers: { ...baseHeaders } })
			.then((res) => {
				const response = res?.data?.res?.data;
				setSalesforceUsers(response);
				setApiRequestSuccess(true);
			})
			.catch((err: Error) => {
				setRequestErrorMessage(err.message);
				setApiRequestSuccess(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (baseHeaders?.Authorization) {
			getContactOwners();
		}
	}, [baseHeaders?.Authorization]);

	const salesforceUsersById =
		salesforceUsers?.reduce<SalesforceUsersById>((memo, sfUser) => {
			const userId = sfUser?.salesforce_record_uid;
			if (userId) {
				memo[userId] = sfUser;
			}
			return memo;
		}, {}) || {};

	return {
		salesforceUsers,
		salesforceUsersById,
		getContactOwners,
		loading,
		requestApiSuccess,
		requestErrorMessage,
	};
};

interface SetContactOwnerResponse extends BaseApiReponse {
	setContactOwner: (contactOwnerId: string, listItemIds: string[], allSelected: boolean) => Promise<void>;
}

const setContactOwnerUrl = `${serviceUrl}/crm-integration/setSalesforceContactOwner`;
export const useSetContactOwner = (callBack?: Function): SetContactOwnerResponse => {
	const { baseHeaders, loading, setLoading, requestApiSuccess, setApiRequestSuccess, requestErrorMessage, setRequestErrorMessage }: BasicApiRquestState =
		useBasicApiRequestState();

	const { listId } = useParams();

	const setContactOwner = (contactOwnerId: string, listItemIds: string[], allSelected: boolean = false): Promise<void> => {
		setLoading(true);
		return axios
			.post(`${setContactOwnerUrl}/${listId}`, { listId, contactOwnerId, listItemIds, allSelected }, { headers: { ...baseHeaders } })
			.then((res) => {
				const response = res?.data?.res?.data;
				setApiRequestSuccess(true);
				if (!!callBack) {
					return callBack();
				}
			})
			.catch((err: Error) => {
				setRequestErrorMessage(err.message);
				setApiRequestSuccess(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return {
		setContactOwner,
		loading,
		requestApiSuccess,
		requestErrorMessage,
	};
};
