import { Box, Typography } from '@benefitflow/designsystem';

export const DetailedInfoTooltip = ({ title, values }: { title: string; values: { title: string; value?: string }[] }) => {
	return (
		<Box m="md">
			<Box display="flex" alignItems="start" flexDirection="column" gap="xs" minWidth="xl7">
				<Typography.Text size="md" whiteSpace="nowrap">
					{title}
				</Typography.Text>
				{values.map(({ title, value }) => (
					<Box display="flex" justifyContent="between" width="full" gap="lg">
						<Typography.Text size="sm" color="neutral-500" weight="medium" whiteSpace="nowrap">
							{title}
						</Typography.Text>
						{value && (
							<Typography.Text size="sm" whiteSpace="nowrap" weight="regular" color="neutral-400">
								{value}
							</Typography.Text>
						)}
					</Box>
				))}
			</Box>
		</Box>
	);
};
