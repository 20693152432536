import cn from 'classnames';
import { AccountInsertAssignInstance, OrphanAccountInstance } from 'common.model/src/db/model-decorators/type-extensions';
import { ExistingAccountParentAssignRowSet } from 'new-beginning/components/pages/ListSync/SubComponents/AccountResolution/ExistingAccountResolve';
import { NewAccountParentAssignRowSet } from 'new-beginning/components/pages/ListSync/SubComponents/AccountResolution/NewAccountResolve';
import { ParentAssignTable } from 'new-beginning/components/pages/ListSync/SubComponents/AccountResolution/ParentAssignTable';
import { CreateParentAccountContainer } from 'new-beginning/components/pages/ListSync/SubComponents/AccountResolution/CreateParentAccountContainer';
import { bf_logo_new } from 'assets/index';

const accountRowClasses = cn('d-flex', 'flex-column', 'mt-2');
const sectionHeaderClasses = cn('bf-txt', 'txt-bolder', 'txt-neutral');
const sectionSubtextClasses = cn('bf-txt', 'txt-xs', 'txt-sm', 'txt-lighter');

interface AccountAssignParentContainerProps {
	selectRow: (id: string) => void;
	selectedRow: string;
	accountReassignInstance: OrphanAccountInstance | AccountInsertAssignInstance;
}

export const ParentAccountAssignContainer = ({ accountReassignInstance, selectRow, selectedRow }: AccountAssignParentContainerProps) => {
	const accountWebsite = accountReassignInstance?.accountWebsite;
	const parentAccounts = accountReassignInstance?.relatedParentAccounts?.relatedAccounts || [];
	const additionalAccounts = accountReassignInstance?.relatedOrphanAccounts?.relatedAccounts || [];
	const allRelatedAccounts = [...parentAccounts, ...additionalAccounts];
	return (
		<div className={cn('w-100')}>
			{!!accountReassignInstance && allRelatedAccounts?.length ? (
				<ParentAssignTable accountWebsite={accountWebsite} salesforceAccounts={allRelatedAccounts} selectRow={selectRow} selectedRow={selectedRow} />
			) : (
				<CreateParentAccountContainer accountWebsite={accountWebsite} />
			)}
		</div>
	);
};

interface ChildAccountAssignContainerProps {
	accountReassignInstance: OrphanAccountInstance | AccountInsertAssignInstance;
}

export interface ExistingAccountAssignContainerProps extends ChildAccountAssignContainerProps {
	accountReassignInstance: OrphanAccountInstance;
}

export const ExistingOrphanAccountContainer = ({ accountReassignInstance }: ExistingAccountAssignContainerProps) => {
	const orphanedAccounts = accountReassignInstance?.childAccountsToAssign;
	const salesforceInstanceUrl = accountReassignInstance?.salesforceInstanceUrl;
	const accountCountText = orphanedAccounts?.length > 1 ? `${orphanedAccounts?.length} Accounts` : 'Account';
	const accountText = `The selected Parent will apply to the following Orphaned ${accountCountText}`;
	return (
		<div className={cn('w-100', 'mt-2')}>
			<div className={cn('d-flex', 'align-items-center')}>
				<div className={cn('d-flex', 'flex-column')}>
					<p className={cn(sectionHeaderClasses)}>Accounts Missing Parent</p>
					<p className={cn(sectionSubtextClasses)}>{accountText}</p>
				</div>
			</div>
			<div className={cn('affected-account-container', accountRowClasses)}>
				<ExistingAccountParentAssignRowSet salesforceAccounts={orphanedAccounts} salesforceInstanceUrl={salesforceInstanceUrl} />
			</div>
		</div>
	);
};

export interface InsertAccountAssignContainerProps extends ChildAccountAssignContainerProps {
	accountReassignInstance: AccountInsertAssignInstance;
}

export const PendingInsertAccountContainer = ({ accountReassignInstance }: InsertAccountAssignContainerProps) => {
	const accountDiffInserts = accountReassignInstance?.childAccountsToAssign;
	const accountAcountText = accountDiffInserts?.length > 1 ? `${accountDiffInserts?.length} Accounts` : `Account`;
	const accountText = `The selected Parent Account will apply to the following ${accountAcountText}`;
	return (
		<div className={cn('w-100', 'mt-2')}>
			<div className={cn('d-flex', 'align-items-center')}>
				<img className={cn('img-md', 'me-2')} src={bf_logo_new} />
				<div className={cn('d-flex', 'flex-column')}>
					<p className={cn(sectionHeaderClasses)}>Accounts Pending Insert</p>
					<p className={cn(sectionSubtextClasses)}>{accountText}</p>
				</div>
			</div>
			<div className={cn('affected-account-container', accountRowClasses)}>
				<NewAccountParentAssignRowSet accountDiffInserts={accountDiffInserts} />
			</div>
		</div>
	);
};
