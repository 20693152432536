/**
 *
 * Asynchronously loads the component for EmployerPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const EmployerPageController = lazyLoad(
	() => import('./index'),
	(module) => module.EmployerPageController,
);
