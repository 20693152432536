import { useMemo } from 'react';
import { Box, Table } from '@benefitflow/designsystem';

import { Summary } from '../Summary/Summary';
import { PolicySummaryDTO } from '../../hooks';
import { TABLE_HEIGHT } from '../Summary/Summary.types';
import { useEmployerProfileBenefitsTabStore } from '../../../../Benefits.store';
import { useGetValueByScreenSizes } from '../../../../../../../../../../hooks/useGetValueByScreenSizes';

export const Summaries = ({ isLoading, summary }: { isLoading: boolean; summary: PolicySummaryDTO }) => {
	const { brokerColorMap, carrierColorMap, year } = useEmployerProfileBenefitsTabStore();
	const getValuesByScreenSizes = useGetValueByScreenSizes();
	const isEmpty = useMemo(() => !isLoading && !summary?.brokerCommissions?.values?.length && !summary?.carrierPremiums?.values?.length, [summary, isLoading]);
	const emptyLabel = useMemo(() => `Data not available in ${year.value} filings`, [year.value]);
	if (isEmpty) return <Table.Empty style={{ height: TABLE_HEIGHT }} showBorder icon="duotone-building-magnifying-glass" label={emptyLabel} />;

	return (
		<Box display="flex" gap="lg" mt="lg" flexDirection={getValuesByScreenSizes({ lg: 'row', md: 'column', sm: 'column' })}>
			<Summary colorMap={brokerColorMap.values} title="BROKER COMMISSIONS" isLoading={isLoading} {...summary?.brokerCommissions} />
			<Summary colorMap={carrierColorMap.values} title="CARRIER PREMIUMS" isLoading={isLoading} {...summary?.carrierPremiums} />
		</Box>
	);
};
