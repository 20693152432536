export enum LineOfBusinessCategory {
	ALL = 'all_policies',
	MEDICAL = 'medical',
	DENTAL = 'dental',
	VISION = 'vision',
	LIFE = 'life',
	DISABILITY = 'disability',
	CRITICAL_ILLNESS = 'critical_illness',
	AD_AND_D = 'ad&d',
	OTHER = 'other',
}
