import { useCallback, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RouterUtils from 'app/RouterUtils';
import { APIContext } from 'new-beginning/services/api/Api.context';

export const useOnBrokerClick = () => {
	const { employerId, employer_master_id } = useParams();
	const { employerApi } = useContext(APIContext);
	const query = employerApi.useGetEmployerSummary({ employer_master_id: employerId || employer_master_id });

	const navigate = useNavigate();
	const onBrokerClick = useCallback(() => {
		if (!query?.data) {
			console.error('No data found');
			return;
		}
		navigate(
			RouterUtils.getBrokerPath(
				query?.data?.primary_broker_unique_code,
				query?.data?.primary_broker,
				null,
				null,
				query?.data.primary_broker_closest_office_city && query?.data.primary_broker_closest_office_state
					? `${query?.data.primary_broker_closest_office_city}, ${query?.data.primary_broker_closest_office_state}`
					: null,
				query?.data.employer_master_id,
			),
		);
	}, [query?.data, navigate]);

	return onBrokerClick;
};
