import serviceUrl from 'utils/serviceUrl';
import axios from 'axios';
import { TenantUser } from './useTenantTeamInfo';
import { useBasicApiRequestState, BasicApiRquestState, BaseApiReponse } from './useBasicApiRequestState';

export interface DeleteUserResponse extends BaseApiReponse {
	deleteUserFromTeam: (user: TenantUser) => Promise<void>;
}

const deleteUserUrl: string = `${serviceUrl}/user/deleteUser`;
export const useDeleteTenantUser = (): DeleteUserResponse => {
	const { baseHeaders, loading, setLoading, requestApiSuccess, setApiRequestSuccess, requestErrorMessage, setRequestErrorMessage }: BasicApiRquestState =
		useBasicApiRequestState();

	const deleteUserFromTeam = (user: TenantUser) => {
		if (!user) {
			console.error('User required to Execute Deletion.');
		}
		setLoading(true);
		setRequestErrorMessage(null);
		setApiRequestSuccess(null);
		return axios
			.post(deleteUserUrl, { email: user.email }, { headers: { ...baseHeaders } })
			.then((res) => {
				setApiRequestSuccess(true);
			})
			.catch((err: Error) => {
				setRequestErrorMessage(err.message);
				setApiRequestSuccess(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return {
		loading,
		requestErrorMessage,
		requestApiSuccess,
		deleteUserFromTeam,
	};
};
