import styled from 'styled-components';
import { darken } from 'polished';

export const DefaultScrollBarContainer = styled.div`
	::-webkit-scrollbar {
		width: 4px;
		height: 0px;
	}
	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 2px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;

export const PrimaryScrollBarContainer = styled.div`
	overflow: auto;
	::-webkit-scrollbar {
		width: 4px;
		height: 0px;
	}
	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	::-webkit-scrollbar-thumb {
		background: #185adb;
		border-radius: 2px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;

export const PrimaryButton = styled.button<{}>`
	background-color: #185adb;
	color: #f4f4f4;
	border-radius: 6px;
	padding: 10px 22px;
	font-family: 'Roboto';
	font-style: 'normal';
	font-weight: 500;
	font-size: '12px';
	line-height: '15px';
	text-align: 'center';
	border: none;

	&:hover {
		background-color: ${darken(0.05, '#185ADB')};
		box-shadow: 'none';
		color: 'white';
		cursor: pointer;
	}
`;

export const LightPrimaryButton = styled.button<{}>`
	background-color: rgba(4, 4, 4, 0.09);
	color: '#0A1931';
	border-radius: 6px;
	padding: 10px 22px;
	font-family: 'Roboto';
	font-style: 'normal';
	font-weight: 700;
	font-size: '12px';
	line-height: '14.52px';
	text-align: 'center';
	border: none;
	cursor: pointer;

	&:hover {
		background-color: rgba(24, 90, 219, 0.09);
		color: #185adb;
		box-shadow: 'none';
		cursor: pointer;
	}
`;

export const LightPrimaryButtonOutline = styled.button`
	padding-right: 28px;
	padding-left: 28px;
	padding-top: 16px;
	padding-bottom: 16px;
	border-radius: 8px;
	border: 2px solid #185adb;
	color: #185adb;
	background-color: transparent;
	font-family: 'Roboto';
	font-weight: 600;
	font-size: 17px;

	&:hover {
		background-color: rgba(24, 90, 219, 0.09);
	}
`;

export const SecondaryButton = styled.button`
	background: #0a1931;
	border-radius: 6px;
	padding: 10px 22px;
	font-family: 'Roboto';
	font-style: normal;
	// font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	border: none;
	/* identical to box height */

	text-align: center;

	color: #ffffff;
`;

export const Paper = styled.div`
	background: #ffffff;
	border: 0.5px solid rgba(0, 0, 0, 0.09);
	box-sizing: border-box;
	box-shadow: 2px 4px 11px rgba(228, 228, 228, 0.25);
	border-radius: 8px;
	// padding-top: 25px;
	// padding-bottom: 25px;
	// padding-right: 22px;
	// padding-left: 22px;
`;

export const PrimarySpanLarge = styled.span`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	/* identical to box height */

	color: #185adb;
`;

export const PrimarySpanMedium = styled.span`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 17px;
	/* identical to box height */

	color: #185adb;
`;

export const SecondarySpanLarge = styled.span`
	font-family: 'Roboto';
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;

	color: #0a1931;
`;

export const SecondarySpanMedium = styled.span`
	font-family: 'Roboto';
	font-style: normal;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */

	color: #0a1931;
`;

export const SecondarySpanSmall = styled.span`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	/* identical to box height */

	color: #0a1931;
`;

export const TerciarySpanMedium = styled.span`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	/* identical to box height */

	color: #b7c2cf;
`;

export const TerciarySpanLarge = styled.span`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */

	text-align: right;

	// color: #C4C4C4;
	color: #b7c2cf;
`;

export const TerciarySpanSmall = styled.span`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;

	color: #b7c2cf;
`;

export const HeaderSpan = styled.span`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;

	color: #afbdcf;
`;
