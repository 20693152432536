import cn from 'classnames';
import { useState } from 'react';
import { BaseModal, BaseModalProps } from 'new-beginning/components/shared/Modals/BaseModal';
import { SalesforceSobjectUserInstance } from 'common.model/src/db/models';
import { useSetContactOwner } from 'new-beginning/hooks/salesforce/useContactOwner';

interface AssignContactOwnerProps extends Partial<BaseModalProps> {
	numSelected?: string | number;
	salesforceUsers: SalesforceSobjectUserInstance[];
	usersLoading: boolean;
	allContactsSelected: boolean;
	selectedContacts: Record<string, boolean>;
	triggerContactRefetch: () => void;
}

export const AssignContactOwnerModal = ({
	isVisible,
	cancelAction,
	triggerContactRefetch,
	salesforceUsers,
	usersLoading,
	allContactsSelected,
	selectedContacts,
}: AssignContactOwnerProps) => {
	const { setContactOwner, loading: setContactOwnerLoading } = useSetContactOwner();
	const modalActionLoading = usersLoading || setContactOwnerLoading;

	const [newContactOwner, setNewContactOwner] = useState<string>('');
	const contactOwnerSet = salesforceUsers || [];

	const selectedListItemIds = Object.keys(selectedContacts)?.filter((listId) => Boolean(selectedContacts?.[listId])) || [];
	const numSelected = allContactsSelected ? 'All' : selectedListItemIds.length;
	const selectionText = numSelected === 'All' ? 'all the selected Contacts' : `${numSelected} Contacts`;

	const closeModal = () => {
		setNewContactOwner('');
		cancelAction();
	};

	const confirmContactOwner = async () => {
		try {
			await setContactOwner(newContactOwner, selectedListItemIds, allContactsSelected);
			triggerContactRefetch();
		} catch (err) {
		} finally {
			closeModal();
		}
	};

	return (
		<BaseModal isVisible={isVisible} headerText="Assign Contact Owner" cancelAction={cancelAction} className={cn('base-form-modal')}>
			<div className={cn('modal-form-container', 'w-100', 'd-flex', 'flex-column', 'my-2', 'ms-1')}>
				<div className={cn('d-flex', 'flex-column', 'justify-content-between')}>
					<div className={cn('py-1', 'mx-3', 'my-2', 'primary-content')}>
						<div className={cn('text-left', 'text-wrap', 'pb-2')}>
							<p className={cn('bf-txt', 'txt-xs', 'txt-lighter')}>The new Contact Owner selection will apply to {selectionText}.</p>
						</div>
						<div className={cn('d-flex', 'flex-column', 'align-items-start', 'my-2')}>
							<div className={cn('input-group', 'd-flex', 'flex-column')}>
								<label className={cn('mb-2')}>New Contact Owner</label>
								<div className="dropdown-container">
									<select
										value={newContactOwner}
										disabled={modalActionLoading || !contactOwnerSet?.length}
										onChange={(e) => setNewContactOwner(e?.target?.value)}
										className={cn('w-100')}
									>
										<option value={''}>{usersLoading ? 'Loading Contact Owners...' : 'Select team member'}</option>
										{contactOwnerSet?.map((owner) => (
											<option key={owner.bf_id} value={owner.salesforce_record_uid}>{`${owner.Name} | ${owner?.Username}`}</option>
										))}
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={cn('d-flex', 'align-items-center', 'justify-content-between', 'p-3', 'modal-footer')}>
				<button onClick={() => closeModal()} className={cn('bf-btn', 'bf-btn-outline', 'x-pd', 'me-1')}>
					<p className={cn('bf-txt', 'd-inline-block')}>Cancel</p>
				</button>
				<button
					onClick={confirmContactOwner}
					disabled={modalActionLoading || !newContactOwner}
					className={cn('bf-btn', 'bf-btn-primary', { loading: setContactOwnerLoading }, 'x-pd', 'me-1')}
				>
					{setContactOwnerLoading ? (
						<span>
							<span className={cn('spinner-border', 'spinner-border-sm')} style={{ color: 'white' }} />
							<span className={cn('bf-txt', 'ms-2')}>Updating</span>
						</span>
					) : (
						<p className={cn('bf-txt', 'd-inline-block')}>Confirm</p>
					)}
				</button>
			</div>
		</BaseModal>
	);
};
