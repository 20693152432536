import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.filterBar || initialState;

export const selectFilterBar = createSelector([selectDomain], (filterBarState) => filterBarState);

export const selectBrokerFilters = createSelector([selectDomain], (filterBarState) => filterBarState.brokerFilters);
export const selectEmployerFilters = createSelector([selectDomain], (filterBarState) => filterBarState.employerFilters);
export const selectProviderFilters = createSelector([selectDomain], (filterBarState) => filterBarState.providerFilters);

export const selectIsNewBrokerSearch = createSelector([selectDomain], (filterBarState) => filterBarState.isNewBrokerSearch);

export const selectFiltersOpenMap = createSelector([selectDomain], (filterBarState) => filterBarState.filtersOpenMap);

export const selectFiltersSearchMap = createSelector([selectDomain], (filterBarState) => filterBarState.filtersSearchMap);
