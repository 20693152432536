import { FC } from 'react';
import cn from 'classnames';
import { SortableHeader, SortableHeaderProps } from 'new-beginning/components/shared/SortableHeader';
import { SetSortParams } from 'new-beginning/hooks/salesforce/useListContacts';
import { PaginationParams, SortKey } from 'common.model/src/types/Pagination';

interface ListTableHeaderProps {
	paginationParams?: PaginationParams;
	setSortConfig: (args: SetSortParams) => void;
	disableSorting?: boolean;
}

const sortableHeaderStyle: Partial<SortableHeaderProps> = {
	alignRight: true,
	iconSize: 10,
	hasSoftText: false,
};

export const ListTableHeader: FC<ListTableHeaderProps> = ({ setSortConfig, paginationParams, disableSorting = false }) => {
	return (
		<thead className={cn('sticky-header')}>
			<tr>
				<th className={cn('bf-custom-header', 'right-border')} style={{ width: '75px' }}>
					<div className={cn('bf-txt')}>Actions</div>
				</th>
				<th className={cn('bf-custom-header')}>
					<SortableHeader
						header="List Name"
						sortKey={SortKey.ListName}
						setSort={setSortConfig}
						activeSortDirection={paginationParams?.sortKey === SortKey.ListName ? paginationParams?.sortOrder : null}
						disabled={disableSorting}
						hasSoftText={false}
						iconSize={10}
					/>
				</th>
				<th className={cn('bf-custom-header', 'right-border')}>
					<SortableHeader
						header="Contacts"
						sortKey={SortKey.ListContacts}
						setSort={setSortConfig}
						activeSortDirection={paginationParams?.sortKey === SortKey.ListContacts ? paginationParams?.sortOrder : null}
						disabled={disableSorting}
						{...sortableHeaderStyle}
					/>
				</th>
				<th className={cn('bf-custom-header', 'right-border')}>
					<SortableHeader
						header="Created By"
						sortKey={SortKey.ListCreatedBy}
						setSort={setSortConfig}
						activeSortDirection={paginationParams?.sortKey === SortKey.ListContacts ? paginationParams?.sortOrder : null}
						disabled={disableSorting}
						{...sortableHeaderStyle}
					/>
				</th>
				<th className={cn('bf-custom-header', 'right-border')}>
					<SortableHeader
						header="Created Date"
						sortKey={SortKey.ListCreatedAt}
						setSort={setSortConfig}
						activeSortDirection={paginationParams?.sortKey === SortKey.ListContacts ? paginationParams?.sortOrder : null}
						disabled={disableSorting}
						{...sortableHeaderStyle}
					/>
				</th>
			</tr>
		</thead>
	);
};
