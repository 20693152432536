import cn from 'classnames';
import moment, { Moment } from 'moment';
import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { salesforce } from 'assets/index';
import { ListDetailsType } from 'new-beginning/hooks/salesforce/useListInfo';
import { SkeletonLoader } from 'new-beginning/components/shared/Loaders/SkeletonLoader';

interface HeaderProps {
	generateRefreshItems: Function;
	listDetails: ListDetailsType;
	detailsLoading: boolean;
	jobLoading: boolean;
	children: ReactElement;
	isAdmin?: boolean;
}

export const SyncHeader = ({ listDetails, generateRefreshItems, detailsLoading = false, jobLoading = true, children, isAdmin }: HeaderProps) => {
	const navigate = useNavigate();
	const listName = listDetails?.list?.name;
	const refreshDate = listDetails?.syncJobStatus?.parentJob?.createdAt;
	const syncInProgress = listDetails?.syncJobStatus?.syncInProgress;
	const [elapsedSinceRefresh, setElapsedSinceRefresh] = useState<Moment>(refreshDate ? moment(refreshDate) : null);

	useEffect(() => {
		const updateTimeSince = () => {
			if (refreshDate) {
				setElapsedSinceRefresh(moment(refreshDate));
				return setInterval(() => {
					setElapsedSinceRefresh(moment(refreshDate));
				}, 30 * 1000);
			} else {
				setElapsedSinceRefresh(null);
			}
			return null;
		};

		const refreshIntervalId = updateTimeSince();

		return () => clearInterval(refreshIntervalId);
	}, [refreshDate]);

	const now = moment();
	const timeSinceRefresh = moment.duration(elapsedSinceRefresh?.diff(now));
	const minutesSinceRefresh = Math.abs(timeSinceRefresh?.asMinutes());
	const disableRefresh = minutesSinceRefresh <= 0.5 && syncInProgress;

	const needsFirstRefresh = !detailsLoading && !refreshDate;
	const refreshTimeLabel = needsFirstRefresh ? 'List Requires Refresh' : `Refreshed: ${timeSinceRefresh?.humanize(true)}`;

	return (
		<>
			<div className={cn('bf-header', 'sync-page-header', 'p-3', 'my-2', 'rounded', 'bg-white', 'w-100')}>
				<div className={cn('d-flex', 'justify-content-between', 'flex-column', 'align-items-start')}>
					<div className={cn('d-flex', 'align-items-center', 'justify-content-start', 'flex-wrap', 'flex-wrap-space')}>
						<button onClick={() => navigate('/lists')} className={cn('btn', 'btn-outline', 'btn-lg', 'border', 'me-2', 'p-2')}>
							<FaArrowLeft size={16} />
						</button>
						<img className={cn('img-sm', 'me-2')} src={salesforce} />
						<SkeletonLoader
							loading={!listName && detailsLoading}
							loaderProps={{ className: cn('rounded', 'bf-skeleton-load', 'me-3'), height: 30, width: 180 }}
						>
							<h4 className={cn('my-0', 'me-3', 'text-muted')}>{listName}</h4>
						</SkeletonLoader>
						{isAdmin && (
							<button type="button" onClick={() => navigate('/Integrations/crm/Settings')} className={cn('bf-btn', 'bf-btn-outline')}>
								Field Mappings
							</button>
						)}
					</div>
					<div className={cn('d-flex', 'justify-content-between', 'align-items-end', 'pe-2', 'w-100')}>
						<div className={cn('pe-1')}>{children}</div>
						<SkeletonLoader loading={detailsLoading} loaderProps={{ className: cn('rounded', 'bf-skeleton-load'), width: 300, height: 40 }}>
							<div className={cn('d-flex', 'justify-content-start', 'align-items-center', 'flex-wrap', 'refresh-action-container', 'px-2', 'mt-2')}>
								<div className={cn('d-flex', 'align-items-baseline', 'me-2')}>
									<div>{refreshTimeLabel}</div>
								</div>
								<button
									className={cn('bf-btn', 'bf-btn-simple', 'bf-btn-primary')}
									disabled={jobLoading || disableRefresh}
									onClick={() => generateRefreshItems()}
								>
									Refresh Data
								</button>
							</div>
						</SkeletonLoader>
					</div>
				</div>
			</div>
		</>
	);
};
