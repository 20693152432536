import classnames from 'classnames';
import { TenantUsage } from '../../../hooks/useTenantTeamInfo';
import { FaPlus } from 'react-icons/fa';

interface TeamHeaderProps {
	tenantTeam?: TenantUsage;
	showAddUser: () => void;
}

export const TeamHeader = ({ tenantTeam, showAddUser }: TeamHeaderProps) => {
	const { users, tenant } = tenantTeam || {};
	const numUsers = users?.length || 0;
	const numSeats = tenant?.num_licenses || 0;
	const remainingSeats = numSeats - numUsers;
	const usagePercentage = !tenant?.credits_used || !tenant?.credits ? 0 : (tenant.credits_used / tenant?.credits) * 100;
	return (
		<div
			className={classnames(
				'header',
				'admin-header',
				'rounded',
				'pt-4',
				'd-flex',
				'flex-row',
				'justify-content-between',
				'align-items-center',
				'bg-white',
				'flex-wrap',
			)}
		>
			<div className="ms-5">
				<p className={classnames('h2', 'text-muted')}>My Team</p>
			</div>
			<div className={classnames('credit-usage-widget', 'd-flex', 'align-items-center', 'border', 'rounded', 'me-5')}>
				<div className={classnames('d-flex', 'flex-column', 'justify-content-around', 'equal-child')}>
					<div className={classnames('d-flex', 'justify-content-between')}>
						<div className={classnames('text-secondary')}>Credits</div>
						<div className={classnames('font-weight-bold', 'text-dark')}>
							{tenant?.credits_used || 0}/{tenant?.credits || 0}
						</div>
					</div>
					<div className={classnames('d-flex', 'justify-content-between')}>
						<div className={classnames('progress', 'w-100')}>
							<div
								className={classnames('progress-bar', 'progress-bar-striped', {
									'bg-success': usagePercentage <= 45,
									'bg-warning': usagePercentage > 45 && usagePercentage < 75,
									'bg-error': usagePercentage >= 75,
								})}
								style={{ width: `${usagePercentage}%` }}
							/>
						</div>
					</div>
				</div>
				<div className={classnames('border', 'border-darker', 'border-left', 'h-100')} />
				<div className={classnames('user-section', 'd-flex', 'justify-content-around', 'align-items-center', 'equal-child')}>
					<div className={classnames('d-flex', 'flex-column', 'justify-content-even', 'text-secondary', 'text-nowrap')}>
						<div>{`${numUsers} Active ${numUsers > 1 ? 'Users' : 'User'}`}</div>
						<div className={classnames({ 'text-secondary': remainingSeats === 0, 'text-success': remainingSeats > 0, 'text-danger': remainingSeats < 0 })}>
							{numSeats - numUsers} Open Licenses
						</div>
					</div>
					<div>
						<button
							type="button"
							onClick={showAddUser}
							disabled={remainingSeats <= 0}
							className={classnames('btn', 'text-nowrap', { 'btn-outline-primary': remainingSeats > 0, 'btn-outline-secondary': remainingSeats <= 0 })}
						>
							<FaPlus /> Add User
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
