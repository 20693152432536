import cn from 'classnames';
import { useEffect, useRef, useState, ReactNode, RefObject } from 'react';
import { throttle } from 'lodash-es';

type TableType = 'contact-sync-table' | 'account-assign-table';

interface ScrollTableProps {
	scrollTrigger: boolean | number | string;
	children: ReactNode;
	tableType?: TableType;
	containerClassNames?: string;
}

export const ScrollTable = ({ tableType, containerClassNames, scrollTrigger, children }: ScrollTableProps) => {
	const tableContainerRef = useRef(null);
	const [isScrolled, setIsScrolled] = useState<boolean>(false);
	const [canScroll, setCanScroll] = useState<boolean>(false);

	const handleTopShadow = () => {
		const containerElement = tableContainerRef?.current;
		const scrollPos = containerElement?.scrollTop;
		const containerIsScrolled = scrollPos !== null && scrollPos > 0;
		// Table Child Element Contains the actual scroll Class
		const hasScrollClass = containerElement?.firstElementChild?.classList.contains('scrolled');
		const shouldToggleScrollClass = (containerIsScrolled && !hasScrollClass) || (!containerIsScrolled && hasScrollClass);
		if (shouldToggleScrollClass) {
			setIsScrolled(containerIsScrolled);
		}
	};

	const handleBottomShadow = () => {
		// Style Lower inset box-shadow of container depending on if there's scrollable content
		const containerElement = tableContainerRef?.current;
		const containerCanScroll =
			containerElement?.scrollHeight &&
			containerElement?.scrollHeight > containerElement?.clientHeight &&
			containerElement?.scrollHeight - containerElement?.clientHeight !== containerElement?.scrollTop;

		const containerHasScrollBarClass = containerElement?.classList.contains('can-scroll');
		const shouldToggleBottomShadowClass = (containerCanScroll && !containerHasScrollBarClass) || (!containerCanScroll && containerHasScrollBarClass);

		if (shouldToggleBottomShadowClass && canScroll !== containerCanScroll) {
			setCanScroll(containerCanScroll);
		}
	};

	// Smooth-Scroll to top of List after page change
	// Add Bottom-Inset-Shadow when the table is scrollable
	useEffect(() => {
		if (tableContainerRef?.current && tableContainerRef?.current?.scrollTop !== 0) {
			tableContainerRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}, [tableContainerRef, scrollTrigger]);

	useEffect(() => {
		// Listen to Scroll Position of Table-Container to add .scrolled shadow Class to Table
		const throttledTopShadowHandler = throttle(handleTopShadow, 150);
		const throttledBottomShadowHandler = throttle(handleBottomShadow, 250);
		setTimeout(handleBottomShadow, 100);
		setTimeout(handleTopShadow, 200);

		tableContainerRef?.current?.addEventListener('scroll', throttledTopShadowHandler, { passive: true });
		tableContainerRef?.current?.addEventListener('scroll', throttledBottomShadowHandler, { passive: true });
		return () => {
			tableContainerRef?.current?.removeEventListener('scroll', throttledTopShadowHandler);
			tableContainerRef?.current?.removeEventListener('scroll', throttledBottomShadowHandler);
		};
	}, [tableContainerRef, scrollTrigger]);

	return (
		<div ref={tableContainerRef} className={cn('bf-table-container', 'contained-scroll-table', containerClassNames, { 'can-scroll': canScroll })}>
			<table className={cn('table', 'bf-table', 'scroll-table', tableType, { scrolled: isScrolled })}>{children}</table>
		</div>
	);
};
