import { ChartColors } from '@benefitflow/designsystem/dist/components/Chart/Chart.types';

export const TABLE_HEIGHT = 412;

export interface SummaryProps {
	title: string;
	value: string;
	total: number;
	colorMap: Record<string, ChartColors>;
	values: {
		title: string;
		value: {
			formatted: string;
			raw: number;
		};
		companyId: string;
		parts: {
			title: string;
			value: string;
		}[];
	}[];
	isLoading: boolean;
}
