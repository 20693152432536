import { Fragment, MouseEvent, ReactElement, ReactNode } from 'react';
import cn from 'classnames';
import { SalesforceContactDiffJobItemInstance } from 'common.model/src/db/models';
import { ListContactType } from 'common.model/src/db/model-decorators/type-extensions';
import { Tooltip } from '@material-ui/core';

interface PillProps {
	label: string | ReactNode;
	isVisible: boolean;
	className?: string;
	onClick?: (e: MouseEvent<Element>) => any;
}

export const BasePill = ({ label, isVisible = false, className, onClick, ...props }: PillProps) => {
	return (
		isVisible && (
			<span className={cn('bf-pill', className)} onClick={onClick} {...props}>
				{label}
			</span>
		)
	);
};

export const UpdatePill = ({ className, ...props }: PillProps) => <BasePill className={cn('pill-update', className)} {...props} />;
export const SuccessPill = ({ className, ...props }: PillProps) => <BasePill className={cn('pill-success', className)} {...props} />;

enum StatusClassMap {
	'UPDATE' = 'update',
	'INSERT' = 'success',
	'UNCHANGED' = 'unchanged',
	'NOT_COMPUTED' = 'danger',
}

type ContactActionType = SalesforceContactDiffJobItemInstance['action_type'];
const DiffActionToStatus: Record<ContactActionType, StatusClassMap> = {
	INSERT: StatusClassMap.INSERT,
	UPDATE: StatusClassMap.UPDATE,
	UNCHANGED: StatusClassMap.UNCHANGED,
	NOT_COMPUTED: StatusClassMap.NOT_COMPUTED,
};

type ContactStatusLabelMap = Record<ContactActionType, (contact?: ListContactType) => string>;

const DiffActionLabel: ContactStatusLabelMap = {
	INSERT: (contact) => {
		const syncItem = contact?.contactSyncItem;
		const syncField = syncItem?.status === 'SYNCED' ? 'Inserted' : 'Insert Failed';
		return !!syncItem ? syncField : 'New';
	},
	UPDATE: (contact) => {
		const numChanges = Object.keys(contact?.contactDiffItem?.field_changelog || {}).length || 0;
		const syncItem = contact?.contactSyncItem;
		if (!!syncItem) {
			const fieldsModified = `${numChanges} ${numChanges > 1 ? 'Fields' : 'Field'}`;
			return `${fieldsModified} ${syncItem?.status === 'SYNCED' ? 'Updated' : 'Failed'}`;
		}

		const updateText = numChanges > 1 ? 'Updates' : 'Update';
		return !numChanges ? 'Update' : `${numChanges} ${updateText}`;
	},
	UNCHANGED: () => 'No Change',
	NOT_COMPUTED: () => 'Skipped',
};

interface ContactStatusPillProps {
	isVisible: boolean;
	contact: ListContactType;
	className?: string;
}

export const ContactStatusPill = ({ isVisible = false, contact, className }: ContactStatusPillProps) => {
	const contactType = contact?.item_type;
	const syncType = contact?.contactDiffItem?.action_type;
	const syncItem = contact?.contactSyncItem;
	const displayLabelTemplate = DiffActionLabel?.[syncType] || DiffActionLabel.NOT_COMPUTED;

	const syncFailed = syncItem?.status === 'FAILED';
	const syncAlreadyAttempted = !!syncItem;

	const label = displayLabelTemplate(contact);

	const syncedItemStatus = syncFailed ? 'danger' : 'success';
	const status = syncAlreadyAttempted ? syncedItemStatus : DiffActionToStatus?.[syncType] || StatusClassMap.NOT_COMPUTED;

	return (
		<FieldChangelogTooltip contactDiff={contact?.contactDiffItem}>
			<BasePill className={cn(`pill-${status}`, className)} isVisible={isVisible} label={label} />
		</FieldChangelogTooltip>
	);
};

interface FieldChangelogTooltipProps {
	contactDiff: SalesforceContactDiffJobItemInstance;
	children: ReactElement;
}

const FieldChangelogTooltip = ({ contactDiff, children }: FieldChangelogTooltipProps) => {
	const showUpdateChangelog = contactDiff?.action_type === 'UPDATE' && contactDiff?.field_changelog;

	return showUpdateChangelog ? (
		<Tooltip
			title={
				showUpdateChangelog ? (
					<Fragment>
						<div className={cn('px-1')}>
							{Object.entries(contactDiff?.field_changelog || {})?.map(([contactField, contactChange]) => (
								<div className={cn('bf-txt', 'txt-xl', 'my-2')} key={contactField}>
									<div className={cn('bf-txt', 'txt-bolder', 'txt-white', 'txt-lg', 'pb-1')}>{contactField}</div>
									<div className={cn('my-1', 'ps-2', 'bf-txt', 'txt-white')}>
										<div className={cn('pb-1')}>
											<span className={cn('bf-txt', 'txt-white')}>Prev:&nbsp;</span>
											<BasePill isVisible className="pill-unchanged" label={contactChange?.previous || 'null'} />
										</div>
										<div>
											<span className={cn('bf-txt', 'txt-white')}>New:&nbsp;</span>
											<BasePill isVisible className="pill-update" label={contactChange?.new || 'null'} />
										</div>
									</div>
								</div>
							))}
						</div>
					</Fragment>
				) : (
					''
				)
			}
		>
			<div>{children}</div>
		</Tooltip>
	) : (
		<Fragment>{children}</Fragment>
	);
};
