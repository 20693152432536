import serviceUrl from 'utils/serviceUrl';
import axios from 'axios';
import { useBasicApiRequestState, BasicApiRquestState, BaseApiReponse } from '../useBasicApiRequestState';
import { AccountInsertAssignInstance, OrphanAccountInstance, ChildAccountAssignType } from 'common.model/src/db/model-decorators/type-extensions';
import { SalesforceAccountDiffJobItemInstance, SalesforceSobjectAccountInstance } from 'common.model/src/db/models';

type ChildAccounts = AccountInsertAssignInstance['childAccountsToAssign'] | OrphanAccountInstance['childAccountsToAssign'];

interface SyncAccountsRequiringParentResponce extends BaseApiReponse {
	setParentAccount: (parentAccountId: string, childAccounts: ChildAccounts) => Promise<void>;
}
const ChildAccountIdGetter: Record<ChildAccountAssignType, Function> = {
	INSERT: (accountDiffItem: SalesforceAccountDiffJobItemInstance): number => accountDiffItem?.id,
	UPDATE: (existingAccount: SalesforceSobjectAccountInstance): string => existingAccount?.salesforce_record_uid,
};

const setParentAccountUrl = `${serviceUrl}/crm-integration/setParentAccountOnSyncAccount`;
export const useAssignParentAccount = (listId: string, childAccountType: ChildAccountAssignType, callBack?: Function): SyncAccountsRequiringParentResponce => {
	const { baseHeaders, loading, setLoading, requestApiSuccess, setApiRequestSuccess, requestErrorMessage, setRequestErrorMessage }: BasicApiRquestState =
		useBasicApiRequestState();

	const childIdMapFn = ChildAccountIdGetter?.[childAccountType];

	const setParentAccount = (parentAccountId: string, childAccounts: ChildAccounts): Promise<void> => {
		setLoading(true);
		const childAccountIds: (string | number)[] = childAccounts?.map((account) => childIdMapFn(account));
		console.log('Attempting to set: ', parentAccountId, childAccountIds, childAccountType);
		return axios
			.post(`${setParentAccountUrl}/${listId}`, { listId, parentAccountId, childAccountIds, childAccountType }, { headers: { ...baseHeaders } })
			.then((res) => {
				const response = res?.data?.res?.data;
				setApiRequestSuccess(true);
				if (!!callBack) {
					return callBack();
				}
			})
			.catch((err: Error) => {
				setRequestErrorMessage(err.message);
				setApiRequestSuccess(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return {
		setParentAccount,
		loading,
		requestApiSuccess,
		requestErrorMessage,
	};
};
