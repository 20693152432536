import { Description, Typography } from '@benefitflow/designsystem';
import { useOnBrokerClick } from '../hooks';
import { useState } from 'react';
import { TextOverflowTooltip } from '../../../../shared/TextOverflowTooltip';

export const Broker = ({ broker }: { broker: string }) => {
	const [hovered, setHovered] = useState(false);
	const onBrokerClick = useOnBrokerClick();

	return (
		<>
			{broker && (
				<Description icon="building-user" size="lg">
					<TextOverflowTooltip label={broker}>
						{(overflowCallback) => (
							<Typography.Text
								whiteSpace="nowrap"
								ellipsedCallback={overflowCallback}
								ellipsis
								onMouseEnter={() => setHovered(true)}
								onMouseLeave={() => setHovered(false)}
								color="primary-500"
								onClick={onBrokerClick}
								style={{ cursor: 'pointer' }}
								decoration={hovered ? 'underline' : undefined}
								size="md"
							>
								{broker}
							</Typography.Text>
						)}
					</TextOverflowTooltip>
				</Description>
			)}
		</>
	);
};
