import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { reducer, sliceKey, appActions } from './slice';
import { appSaga } from './saga';
import { useSelector, useDispatch } from 'react-redux';
import { selectAppPage, selectIdToken } from './selectors';
import { AppPage } from 'pages/index';
import { LoggedInUserContext } from 'new-beginning/context/LoggedInUser';
import '../../../node_modules/bootstrap/scss/bootstrap.scss';

import { NavBar } from 'organisms/NavBar';
import { AdminRoutes } from 'routes/AdminRoutes';
import { RootAdminRoutes } from 'routes/AdminRoutes';
import { AuthRoutes } from 'routes/AuthRoutes';
import { PublicRoutes } from 'routes/PublicRoutes';
// Import main.sccs File from new Component Directory
import '../new-beginning/styles/main.scss';
import { useSetScreenSize } from 'new-beginning/hooks/useScreenSize';

export function AppController() {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: appSaga });

	const dispatch = useDispatch();

	const appPage = useSelector(selectAppPage);

	const { authUser, loadingAuth } = useContext(LoggedInUserContext);
	const isAuthed = !!authUser && !loadingAuth;

	const userRoles = authUser?.signInUserSession?.accessToken?.payload?.['cognito:groups'];
	const isBaseAdmin = userRoles?.some((role) => role === 'clientAdmin') || false;
	const isRootAdmin = userRoles?.some((role) => role === 'benefitflowAdmin') || false;
	const isAdmin = isBaseAdmin || isRootAdmin;
	useSetScreenSize();

	useEffect(() => {
		if (!appPage.idToken && !appPage.updateTokenInProgress) {
			dispatch(appActions.getUpdatedToken());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appPage.idToken]);

	return (
		<>
			<Helmet titleTemplate="BenefitFlow" defaultTitle="BenefitFlow">
				<meta name="description" content="A BenefitFlow application" />
			</Helmet>

			<AppPage
				NavBarElement={
					<NavBar isClientAdmin={isAdmin} isBfAdmin={isRootAdmin} isAuthenticated={isAuthed} userName={authUser?.attributes?.name} tenants={appPage?.tenants} />
				}
				showBanner={window.location.pathname !== '/'}
				banner={{
					errorMessages: appPage.errorMessages,
					successMessages: appPage.successMessages,
					loadingBanners: appPage.loadingBanners,
					onCloseErrorMessage: (message) => dispatch(appActions.onCloseErrorMessage(message)),
					onCloseSuccessMessage: (message) => dispatch(appActions.onCloseSuccessMessage(message)),
				}}
			>
				<PublicRoutes />
				<RootAdminRoutes isAuthed={isAuthed} isRootAdmin={isRootAdmin} loading={loadingAuth} />
				<AdminRoutes isAuthed={isAuthed} isAdmin={isAdmin} loading={loadingAuth} />
				<AuthRoutes isAuthed={isAuthed} isAdmin={isAdmin} loading={loadingAuth} />
			</AppPage>
		</>
	);
}
