import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { employerSummaryApiAdapter } from './helpers/employerSummaryApiAdapter';
import { useEmployeeSentiment } from '../../../Tabs/components/EmployeeSentimentTab/hooks';
import { APIContext } from 'new-beginning/services/api/Api.context';

export const useSummary = () => {
	const { employerId, employer_master_id } = useParams();
	const { employerApi } = useContext(APIContext);
	const query = employerApi.useGetEmployerSummary({ employer_master_id: employerId || employer_master_id });
	const employerSummaryLoading = query?.loading;
	const { evaluation, loading: employeeSentimentLoading } = useEmployeeSentiment();
	const loading = useMemo(() => employeeSentimentLoading || employeeSentimentLoading, [employerSummaryLoading, employeeSentimentLoading]);
	const {
		address,
		broker,
		description,
		fundingType,
		imageSrcs,
		linkedinUrl,
		location,
		name,
		numberOfContacts,
		renewalDate,
		totalEmployees,
		websiteUrl,
		benefitsRating,
	} = useMemo(() => (query?.data ? employerSummaryApiAdapter(query?.data) : {}), [query?.data]);

	return {
		address,
		broker,
		description,
		fundingType,
		imageSrcs,
		linkedinUrl,
		location,
		name,
		numberOfContacts: '7,369 Contacts', //temporary data to test the layout
		benefitsRating: evaluation?.toFixed(1),
		renewalDate,
		totalEmployees,
		websiteUrl,
		loading,
	};
};
