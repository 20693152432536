import { RADIUS_SEARCH_DEFAULT } from 'app/constants';
import { LocationType, type LocationSelection } from 'common.model/src/types/master/location';
import FormattingUtils from 'utils/FormattingUtils';
export const getLabel = ({ location }: LocationSelection) => {
	switch (location.locationType) {
		case LocationType.Zip: {
			const { zip, city, stateCode } = location;
			return `${zip} (${FormattingUtils.formatUpperCasing(city)}, ${stateCode})`;
		}
		case LocationType.CityState:
		default: {
			const { city, state, stateCode } = location;
			if (city) return `${FormattingUtils.formatUpperCasing(city)}, ${FormattingUtils.formatUpperCasing(state)} (${stateCode})`;
			return `${FormattingUtils.formatUpperCasing(state)} (${stateCode})`;
		}
	}
};

export const getTagLabel = (locationSelection: LocationSelection) => {
	const { radiusMiles, location } = locationSelection;
	switch (location.locationType) {
		case LocationType.Zip: {
			const { zip } = location;
			if (radiusMiles && radiusMiles > RADIUS_SEARCH_DEFAULT) return `${zip} (Within ${radiusMiles} miles)`;
			return `${zip}`;
		}
		case LocationType.CityState:
		default: {
			return getLabel(locationSelection);
		}
	}
};
