export default class RouterUtils {
	public static getEmployerPath(employer_master_id) {
		return `/Employer/${employer_master_id}`;
	}

	public static getBrokerPath(brokerMasterId, masterBrokerName, brokerFilters = null, planUniqueKey = null, brokerLocation = null, employer = null) {
		const params = {
			brokerId: brokerMasterId,
			brokerName: masterBrokerName,
			brokerFilters: JSON.stringify(brokerFilters),
			planUniqueKey,
			brokerLocation,
			employer,
		};

		const paramsUrl = Object.keys(params)
			.filter((param) => !!params[param])
			.map((param) => `${param}=${encodeURIComponent(params[param])}`)
			.join('&');

		return `/Broker?${paramsUrl}`;
	}

	public static getProviderPath(masterProviderId, masterBrokerName) {
		return `/Provider?providerId=${encodeURIComponent(masterProviderId)}&providerName=${encodeURIComponent(masterBrokerName)}`;
	}
}
