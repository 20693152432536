import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import Spinner from 'react-bootstrap/Spinner';

const useStyles = makeStyles((theme) => ({
	buttonBase: {
		padding: '4px',
		margin: '0',
		'&:hover': {
			backgroundColor: 'rgba(27, 233, 52, .1)',
		},
		MuiButtonBase: {
			disableRipple: true,
		},
	},
}));

class CheckBoxProps {
	isChecked: boolean;
	isDisabled?: boolean;
	onChange: (newVal: boolean) => any;
	isLoading?: boolean;
	id?: string;
}

export default function CheckBox(props: CheckBoxProps) {
	const classes = useStyles();

	return (
		<div key={props.id} style={{ width: '100%', textAlign: 'center' }}>
			{props.isLoading ? (
				<Spinner animation="border" size="sm" color="#b7c2cf" />
			) : (
				<Checkbox
					key={props.id}
					className={classes.buttonBase}
					checked={props.isChecked}
					checkedIcon={<Check style={{ color: 'rgb(24 90 219)' }} />}
					onChange={(e, checked) => props.onChange(checked)}
					disableRipple
					disabled={props.isDisabled}
				/>
			)}
		</div>
	);
}
