import { useCallback } from 'react';

import { BrokerProfileTabs } from '../../../BrokerProfile.types';
import { useBrokerProfileStore } from '../../../BrokerProfile.store';

export const useOnContactsClick = () => {
	const { setTab } = useBrokerProfileStore();
	const onContactsClick = useCallback(() => {
		setTab(BrokerProfileTabs.CONTACTS);
	}, [setTab]);

	return onContactsClick;
};
