import cn from 'classnames';
import { PaginationParams, SortKey } from 'common.model/src/types/Pagination';
import { SortableHeader } from 'new-beginning/components/shared/SortableHeader';
import { SetSortParams } from 'new-beginning/hooks/salesforce/useListContacts';

interface ContactTableHeaderProps {
	paginationParams: PaginationParams;
	setSortConfig: (args: SetSortParams) => void;
	lockAllActions: boolean;
}

export const ContactTableHeader = ({ setSortConfig, paginationParams, lockAllActions }: ContactTableHeaderProps) => {
	return (
		<thead className={cn('sticky-header')}>
			<tr>
				<th className={cn('right-border')}>
					<div className={cn('d-flex', 'align-items-center', 'justify-start-between')}>
						<div className={cn('bf-txt', 'txt-neutral-off')}>Actions</div>
					</div>
				</th>
				<th className={cn('text-left')}>
					<SortableHeader
						header="Contact"
						disabled={lockAllActions}
						sortKey={SortKey.contact_name}
						setSort={setSortConfig}
						activeSortDirection={paginationParams?.sortKey === SortKey.contact_name ? paginationParams?.sortOrder : null}
					/>
				</th>
				<th className={cn('right-border')}>
					<SortableHeader
						header="Contact Owner"
						disabled={lockAllActions}
						sortKey={SortKey.contact_owner}
						setSort={setSortConfig}
						activeSortDirection={paginationParams?.sortKey === SortKey.contact_owner ? paginationParams?.sortOrder : null}
						alignRight
					/>
				</th>
				<th>
					<SortableHeader
						header="Account"
						disabled={lockAllActions}
						sortKey={SortKey.account_name}
						setSort={setSortConfig}
						activeSortDirection={paginationParams?.sortKey === SortKey.account_name ? paginationParams?.sortOrder : null}
					/>
				</th>
			</tr>
		</thead>
	);
};
