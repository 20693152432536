import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';
import { listsDetailPageActions } from 'app/containers/ListsPage/containers/ListsDetailPage/slice';

export const useMax = () => {
	const dispatch = useDispatch();
	const { contactFilters: filters } = useSelector(selectListsDetailPage);
	const set = useCallback(
		(max: number) => {
			dispatch(
				listsDetailPageActions.updateContactFilters({
					...filters,
					yearsAtCompany: [filters.yearsAtCompany[0], max],
				}),
			);
		},
		[dispatch, filters, filters.yearsAtCompany[1], listsDetailPageActions],
	);

	const value = useMemo(() => filters.yearsAtCompany[1], [filters.yearsAtCompany[1]]);

	return {
		value,
		set,
	};
};
