import { Box, Skeleton, Tag, Typography } from '@benefitflow/designsystem';

export const TableTitle = ({ isLoading, title, count }: { title: string; count?: number; isLoading: boolean }) => {
	return (
		<>
			{isLoading ? (
				<Box mb="md">
					<Skeleton height="sm" width="xl4" />
				</Box>
			) : (
				<Box display="flex" gap="sm" alignItems="center" mb="md">
					<Typography.Text>{title}</Typography.Text>
					<Tag variant="neutral" label={`${count}`} />
				</Box>
			)}
		</>
	);
};
