import { useEffect } from 'react';
import { LineOfBusinessCategory } from 'common.model/src/types/services/employer-service/LineOfBusinessCategory';

import { useEmployerProfileBenefitsTabStore } from '../../../../../Benefits.store';

export const useClearSelectionOnYearChange = () => {
	const {
		selectedPolicies: { setValues },
		year: { value: year },
	} = useEmployerProfileBenefitsTabStore();

	useEffect(() => {
		setValues([LineOfBusinessCategory.ALL]);
	}, [year, setValues]);
};
