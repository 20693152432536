import React, { useState, useRef, useEffect, useCallback } from 'react';
import './ColoredTextArea.scss';
import { Box, Typography } from '@benefitflow/designsystem';
import { Color } from '@benefitflow/designsystem/dist/components/Typography/Typography.types';

export interface ColoredLine {
	text: string;
	color: Color;
}

interface ColoredTextAreaProps {
	value: ColoredLine[];
	onChange?: (lines: ColoredLine[]) => void;
	className?: string;
	placeholder?: string;
	maxLines?: number;
}

export const ColoredTextArea: React.FC<ColoredTextAreaProps> = ({ value, onChange, placeholder, maxLines = 500 }) => {
	const [text, setText] = useState('');
	const textareaRef = useRef<HTMLTextAreaElement>(null);
	const outputRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		setText(value.map((line) => line.text).join('\n'));
	}, [value]);

	// Sync scroll positions
	const handleScroll = (e: React.UIEvent<HTMLTextAreaElement>) => {
		if (outputRef.current && textareaRef.current) {
			outputRef.current.scrollTop = textareaRef.current.scrollTop;
			outputRef.current.scrollLeft = textareaRef.current.scrollLeft;
		}
	};

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement>) => {
			const newText = e.target.value;
			const lines = newText.split('\n');

			// If we're already at max lines and user is trying to add more, prevent the change
			if (lines.length >= maxLines) {
				return;
			}

			setText(newText);

			if (onChange) {
				const updatedLines = lines.map((line, index) => ({
					text: line,
					color: value[index]?.color || 'neutral-400',
				}));
				onChange(updatedLines);
			}
		},
		[value, maxLines, setText, onChange],
	);

	const handlePaste = useCallback(
		(e: React.ClipboardEvent) => {
			e.preventDefault(); // Prevent default paste to handle it manually

			const pastedText = e.clipboardData.getData('text');
			const currentLines = text.split('\n');
			const pastedLines = pastedText.split('\n');

			// Calculate how many lines we can still accept
			const remainingSlots = maxLines - currentLines.length + 1;

			if (remainingSlots <= 0) return; // No room for new lines

			// Take only the lines that will fit
			const linesToAdd = pastedLines.slice(0, remainingSlots);

			// Create the new text by combining existing text with accepted new lines
			const newText = text + (text ? '\n' : '') + linesToAdd.join('\n');
			setText(newText);

			if (onChange) {
				const updatedLines = newText.split('\n').map((line, index) => ({
					text: line,
					color: value[index]?.color || 'neutral-400',
				}));
				onChange(updatedLines);
			}
		},
		[text, value, maxLines, setText, onChange],
	);

	const handleKeyDown = (e: React.KeyboardEvent) => {
		const currentLines = text.split('\n');

		// If we're at the line limit and user presses Enter, prevent new line
		if (e.key === 'Enter' && currentLines.length >= maxLines) {
			e.preventDefault();
		}
	};

	return (
		<Box display="flex" flexDirection="column" gap="md" mb="lg" mx="lg">
			<div className="colored-textarea-container">
				<textarea
					ref={textareaRef}
					className="ds_typography ds_typography-text lg colored-textarea-input"
					value={text}
					onChange={handleChange}
					onPaste={handlePaste}
					onKeyDown={handleKeyDown}
					onScroll={handleScroll}
					placeholder={placeholder}
				/>
				<div ref={outputRef} className="colored-textarea-output">
					{value.map((line, index) => (
						<Typography.Text size="lg" key={index} color={line.color || 'neutral-400'}>
							{line.text || '\u00A0'}
						</Typography.Text>
					))}
				</div>
			</div>
		</Box>
	);
};
