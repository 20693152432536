import { ObjectMappingTargetType } from '../../../../db/model-decorators/type-extensions';
import { CrmObjectTypeEnum } from './CrmObjectTypeEnum';
import { CrmIntegrationFieldDomainType } from './FieldDomainType';
import IField from './IField';

export const getObjectFields = (objectName: CrmObjectTypeEnum): IField[] => {
	switch (objectName) {
		case CrmObjectTypeEnum.BROKER:
			return brokerFields;
		case CrmObjectTypeEnum.BROKER_CONTACT:
			return brokerContactFields;
		case CrmObjectTypeEnum.BROKER_OFFICE:
			return brokerOfficeFields;
		case CrmObjectTypeEnum.EMPLOYER_CONTACT:
			return employerContactFields;
		default:
			throw new Error(`No fields found for object ${objectName}`);
	}
};

const brokerContactFields: IField[] = [
	{ name: 'employee_profile_url', label: 'LinkedIn Profile Url', type: 'string' },
	{ name: 'first_name', label: 'First Name', type: 'string' },
	{ name: 'last_name', label: 'Last Name', type: 'string' },
	{ name: 'work_email', label: 'Work Email', type: 'string' },
	{ name: 'broker_name', label: 'Broker Name', type: 'string' },
	{ name: 'experience_title', label: 'Experience Title', type: 'string' },
	{ name: 'employee_city', label: 'Employee City', type: 'string' },
	{ name: 'employee_state', label: 'Employee State Long (Ex: Florida)', type: 'string' },
	{ name: 'employee_state_code', label: 'Employee State Code (Ex: FL)', type: 'string' },
	{ name: 'employee_country', label: 'Employee Country Ex: USA', type: 'string' },
	{ name: 'employee_country_long', label: 'Employee Country Long Ex: United States', type: 'string' },
	{ name: 'mobile_phone_1', label: 'Mobile Phone Number 1', type: 'string' },
	{ name: 'mobile_phone_2', label: 'Mobile Phone Number 2', type: 'string' },
	{ name: 'mobile_phone_3', label: 'Mobile Phone Number 3', type: 'string' },
	{ name: 'professional_phone_1', label: 'Professional Phone Number 1', type: 'string' },
	{ name: 'professional_phone_2', label: 'Professional Phone Number 2', type: 'string' },
	{ name: 'professional_phone_3', label: 'Professional Phone Number 3', type: 'string' },
	{ name: 'professional_phone_3', label: 'Professional Phone Number 3', type: 'string' },
	{ name: 'owner_email', label: 'Contact Owner', type: 'string' },
];

const brokerOfficeFields: IField[] = [
	{ name: 'broker_name', label: 'Company Name', type: 'string' },
	{ name: 'website', label: 'Company Website', type: 'string' },
	{ name: 'account_type', label: 'Account Type', type: 'string' },
	{ name: 'broker_office_city', label: 'Office City', type: 'string' },
	{ name: 'broker_office_state', label: 'Office State', type: 'string' },
	{ name: 'office_state_code', label: 'Office State Code (Ex: FL)', type: 'string' },
	{ name: 'office_zip_code', label: 'Office Zip Code', type: 'string' },
	{ name: 'office_country_code', label: 'Office Country Code Ex: US', type: 'string' },
	{ name: 'office_country_long', label: 'Office Country Long Ex: United States', type: 'string' },
];

const brokerFields: IField[] = [
	{ name: 'broker_name', label: 'Broker Name', type: 'string' },
	{ name: 'website', label: 'Broker Website', type: 'string' },
	{ name: 'account_type', label: 'Account Type', type: 'string' },
];

export const BENEFIT_FLOW_CRM_OBJECTS: Record<ObjectMappingTargetType, IField[]> = {
	[ObjectMappingTargetType.PARENT_ACCOUNT]: brokerFields.map((field) => ({ ...field, field_domain_type: CrmIntegrationFieldDomainType.BENEFIT_FLOW })),
	[ObjectMappingTargetType.CHILD_ACCOUNT]: brokerOfficeFields.map((field) => ({ ...field, field_domain_type: CrmIntegrationFieldDomainType.BENEFIT_FLOW })),
	[ObjectMappingTargetType.CONTACT]: brokerContactFields.map((field) => ({ ...field, field_domain_type: CrmIntegrationFieldDomainType.BENEFIT_FLOW })),
};

// TODO: Move the decorateFieldOptions into this file and return those preampped options from here.

const employerContactFields: IField[] = [
	{ name: 'employee_profile_url', label: 'LinkedIn Profile Url', type: 'string' },
	{ name: 'first_name', label: 'First Name', type: 'string' },
	{ name: 'last_name', label: 'Last Name', type: 'string' },
	{ name: 'work_email', label: 'Work Email', type: 'string' },
	{ name: 'experience_title', label: 'Experience Title', type: 'string' },
	{ name: 'employee_city', label: 'Employee City', type: 'string' },
	{ name: 'employee_state', label: 'Employee State Long (Ex: Florida)', type: 'string' },
	{ name: 'employee_state_code', label: 'Employee State Code (Ex: FL)', type: 'string' },
	{ name: 'employee_country', label: 'Employee Country Ex: USA', type: 'string' },
	{ name: 'employee_country_long', label: 'Employee Country Long Ex: United States', type: 'string' },
	{ name: 'mobile_phone_1', label: 'Mobile Phone Number 1', type: 'string' },
	{ name: 'mobile_phone_2', label: 'Mobile Phone Number 2', type: 'string' },
	{ name: 'mobile_phone_3', label: 'Mobile Phone Number 3', type: 'string' },
	{ name: 'professional_phone_1', label: 'Professional Phone Number 1', type: 'string' },
	{ name: 'professional_phone_2', label: 'Professional Phone Number 2', type: 'string' },
	{ name: 'professional_phone_3', label: 'Professional Phone Number 3', type: 'string' },
	{ name: 'professional_phone_3', label: 'Professional Phone Number 3', type: 'string' },
];
