import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';
import { listsDetailPageActions } from 'app/containers/ListsPage/containers/ListsDetailPage/slice';

export const useOnExclude = (radius?: number) => {
	const dispatch = useDispatch();
	const { contactFilters } = useSelector(selectListsDetailPage);

	const onExclude = useCallback(
		({ data }: { data: any }) => {
			dispatch(
				listsDetailPageActions.updateContactFilters({
					...contactFilters,
					contactLocation: {
						exclude: [...contactFilters.contactLocation.exclude, { location: data, radiusMiles: radius }],
						include: contactFilters.contactLocation.include,
					},
				}),
			);
		},
		[dispatch, listsDetailPageActions, contactFilters],
	);

	return onExclude;
};
