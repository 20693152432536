import { useContext } from 'react';
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { PolicyDTO } from 'common.model/src/types/services/employer-service/PolicyDTO';
import { GetFormattedPoliciesRequest, GetFormattedPoliciesResponse } from 'common.model/src/types/services/employer-service/EmployerService';

import { APIContext } from '../services/api/Api.context';
import FormattingUtils from '../../ui-components/utils/FormattingUtils';

export interface Policy {
	benefit: string;
	lives: string;
	renewal: string;
	brokerCommissions?: FillChartData;
	carrierPremiums?: FillChartData;
	pepm: string;
}

export interface FillChartData {
	total: {
		formatted: string;
		raw: number;
	};
	parts: {
		name: string;
		value: {
			formatted: string;
			raw: number;
		};
		companyId: string;
	}[];
}

export const useFormattedPoliciesQuery = ({
	options,
	...request
}: { options?: UseInfiniteQueryOptions<GetFormattedPoliciesResponse, unknown, SelectReturnType> } & Omit<GetFormattedPoliciesRequest, 'page'>) => {
	const {
		employerApi: { getFormattedPolicies },
	} = useContext(APIContext);
	const query = useInfiniteQuery<GetFormattedPoliciesResponse, unknown, SelectReturnType>(
		['formattedPolicies', request],
		async ({ pageParam }) => getFormattedPolicies({ ...request, page: pageParam ?? 1 }),
		{ select, ...options },
	);

	return query;
};

type SelectReturnType = Omit<GetFormattedPoliciesResponse, 'policies'> & {
	policies: Policy[];
	highestValues: { brokerCommission: number; carrierPremium: number };
};

const select = (data: InfiniteData<GetFormattedPoliciesResponse>): InfiniteData<SelectReturnType> => {
	if (!data)
		return {
			pageParams: [],
			pages: [],
		};
	const pages = data.pages.map((page) => ({
		...page,
		policies: format(page.data),
	}));

	return { ...data, pages };
};

const format = (data: PolicyDTO[]): Policy[] => {
	return data.map(({ benefit, lives, pepm, renewal, brokerCommissions, carrierPremiums }) => ({
		benefit: benefit,
		lives: FormattingUtils.formatNumberAbbrev(lives) as string,
		pepm: pepm && `$${pepm?.toFixed(1)}`,
		renewal,
		brokerCommissions: {
			total: {
				raw: brokerCommissions.total,
				formatted: `${FormattingUtils.formatNumberAbbrev(brokerCommissions.total, undefined, undefined, '$')}`,
			},
			parts: brokerCommissions.parts.map(({ companyId, name, value }) => ({
				name,
				value: {
					raw: value,
					formatted: `${FormattingUtils.formatNumberAbbrev(value, undefined, undefined, '$')}`,
				},
				companyId: companyId,
			})),
		},
		carrierPremiums: {
			total: {
				raw: carrierPremiums.total,
				formatted: `$${FormattingUtils.formatNumberAbbrev(carrierPremiums.total)}`,
			},
			parts: carrierPremiums.parts.map(({ companyId, name, value }) => ({
				name,
				value: {
					raw: value,
					formatted: `$${FormattingUtils.formatNumberAbbrev(value)}`,
				},
				companyId: companyId,
			})),
		},
	}));
};
