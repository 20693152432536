import { useContext } from 'react';
import { LineOfBusinessCategory } from 'common.model/src/types/services/employer-service/LineOfBusinessCategory';

import { useEmployerId } from '../../../../../../../../hooks';
import { useEmployerProfileBenefitsTabStore } from '../../../../../Benefits.store';
import { APIContext } from '../../../../../../../../../../../services/api/Api.context';

export const usePolicies = (): { isLoading: boolean; count?: Record<LineOfBusinessCategory, number> } => {
	const employerId = useEmployerId();
	const { year } = useEmployerProfileBenefitsTabStore();
	const {
		employerApi: { useGetPoliciesInsuranceProducts },
	} = useContext(APIContext);
	const { loading, data } = useGetPoliciesInsuranceProducts({ employerId, year: year.value });

	return {
		isLoading: loading,
		count: data,
	};
};
