import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import { resetToInitialState } from '../../../utils/ReduxStateUtils';
import { ContactSearchResponse } from 'common.model/src/types/common/contact/ContactSearchResponse';
import { ISimpleContact } from 'common.model/src/types/common/contact/ISimpleContact';
import { EntityLocation } from 'common.model/src/types/master/location';
import { EmployerContactFilters, getDefaultEmployerContactFilters } from 'common.model/src/types/common/search/filters/ContactFilters';

// The initial state of the EmployerPage container
export const initialState: ContainerState = {
	selectedTab: 0,

	isEmployerContactsLoaded: false,

	employer_master_id: null,

	benefitFilingYears: null,
	allFilingYears: null,

	contactFilters: getDefaultEmployerContactFilters(),
	anonymizedContacts: null,
	anonymizedContactsBreakdown: null,
	selectedContacts: {},
	selectedContactProfileUrl: null,
	isSelectAllContacts: false,
	contactsCheckedMap: {},
	contactsNotCheckedMap: {},
	isContactsSearchInfiniteLoading: false,
	contactSearchInfinitePage: 0,

	gettingSelectedMap: {},
	isGetSelectedLoading: false,
	isDownloading: false,

	showPurchaseModal: false,
	purchaseModalAmount: null,

	filingContacts: null,

	selectedBenefitFilingYear: null,
	selectedAllFilingYear: null,

	showCreateContactListModal: false,
	listAddLoading: false,

	contactLocationSearchOptions: null,
	isContactLocationSearchOptionsLoading: false,
};

const employerPageSlice = createSlice({
	name: 'employerPage',
	initialState,
	reducers: {
		load(state, action: PayloadAction<string>) {
			state.isEmployerContactsLoaded = true;
			const previousContactFilters = state.contactFilters;
			resetToInitialState(state, initialState);
			state.contactFilters = {
				...previousContactFilters,
				...getDefaultEmployerContactFilters(),
				companyMasterIds: [action.payload],
			};
			state.employer_master_id = action.payload;
		},
		setSelectedTab(state, action: PayloadAction<any>) {
			state.selectedTab = action.payload;
		},
		updateContacts(state, action: PayloadAction<ContactSearchResponse>) {
			const existingContacts = state.anonymizedContacts || [];
			state.anonymizedContacts = existingContacts.concat(action.payload.anonymizedContacts);
			state.selectedContacts = action.payload.selectedContacts?.reduce((a, b) => {
				a[b.profile_url] = b;
				return a;
			}, state.selectedContacts || {});

			state.isContactsSearchInfiniteLoading = false;
		},
		updateContactsBreakdown(state, action: PayloadAction<any>) {
			state.anonymizedContactsBreakdown = action.payload;
		},
		getContact(state, action: PayloadAction<any>) {
			state.selectedContactProfileUrl = action.payload;
		},
		gotContact(state, action: PayloadAction<ISimpleContact>) {
			state.selectedContacts[action.payload.profile_url] = action.payload;
			state.selectedContactProfileUrl = null;
		},
		getSelected(state, action: PayloadAction<string[]>) {
			state.gettingSelectedMap = action.payload.reduce((a, b) => {
				a[b] = true;
				return a;
			}, state.gettingSelectedMap);
			state.isGetSelectedLoading = true;
		},
		gotSelected(state, action: PayloadAction<ISimpleContact[]>) {
			state.selectedContacts = action.payload.reduce((a, b) => {
				a[b.profile_url] = b;
				return a;
			}, state.selectedContacts);
			state.gettingSelectedMap = {};
			state.isGetSelectedLoading = false;
		},
		downloadSearchResults(state) {
			state.isDownloading = true;
		},
		downloadedSearchResults(state) {
			state.isDownloading = false;
		},
		toggleCreateContactListModal(state) {
			state.showCreateContactListModal = !state.showCreateContactListModal;
		},
		addContactsToExistingList(state, action: PayloadAction<{ listId: string }>) {
			state.listAddLoading = true;
		},
		addContactsToNewList(state, action: PayloadAction<{ listName: string; listDescription }>) {
			state.listAddLoading = true;
		},
		addContactsToListFinished(state) {
			state.listAddLoading = false;
		},
		showPurchaseModal(state, action: PayloadAction<number>) {
			state.showPurchaseModal = true;
			state.purchaseModalAmount = action.payload;
		},
		hidePurchaseModal(state) {
			state.showPurchaseModal = false;
			state.purchaseModalAmount = null;
		},
		searchContactsInfinite(state) {
			state.isContactsSearchInfiniteLoading = true;
			state.contactSearchInfinitePage += 1;
		},
		updateContactsCheckedMap(state, action: PayloadAction<any>) {
			state.contactsCheckedMap = action.payload;
		},
		updateContactsNotCheckedMap(state, action: PayloadAction<any>) {
			state.contactsNotCheckedMap = action.payload;
		},
		setIsSelectAllContacts(state, action: PayloadAction<any>) {
			state.isSelectAllContacts = action.payload;
		},
		updateContactFilters(state, action: PayloadAction<EmployerContactFilters>) {
			state.contactFilters = {
				...action.payload,
				...(state.employer_master_id ? { companyMasterIds: [state.employer_master_id] } : {}),
			};
			state.isSelectAllContacts = false;
			state.contactsCheckedMap = {};
			state.contactsNotCheckedMap = {};
			state.anonymizedContacts = null;
			state.anonymizedContactsBreakdown = null;
			state.contactSearchInfinitePage = 0;
		},
		resetContactSearchFilters(state) {
			state.contactFilters = getDefaultEmployerContactFilters();
			state.isSelectAllContacts = false;
			state.contactsCheckedMap = {};
			state.contactsNotCheckedMap = {};
			state.anonymizedContacts = null;
			state.anonymizedContactsBreakdown = null;
			state.contactSearchInfinitePage = 0;
			state.employer_master_id = null;
		},
		searchCityStateOptions(state, action: PayloadAction<string>) {
			state.isContactLocationSearchOptionsLoading = true;
		},
		updateContactLocationSearchOptions(state, action: PayloadAction<EntityLocation[]>) {
			state.contactLocationSearchOptions = action.payload;
			state.isContactLocationSearchOptionsLoading = false;
		},
	},
});

export const { actions: employerPageActions, reducer, name: sliceKey } = employerPageSlice;
