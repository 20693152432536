import cn from 'classnames';
import { useState } from 'react';
import { components, OptionProps } from 'react-select';
import { Props as SelectProps } from 'react-select';
import { salesforce, bf_logo_new } from 'assets/index';
import { MdEdit } from 'react-icons/md';
import { FaChevronRight } from 'react-icons/fa';
import { FaArrowRightFromBracket } from 'react-icons/fa6';
import { CrmIntegrationFieldDomainType } from 'common.model/src/types/services/integration/fieldMapping/FieldDomainType';
import { CrmObjectFieldOption } from 'new-beginning/hooks/salesforce/useCrmFieldConfigurationData';

// Add this type extension
interface CustomSelectProps extends SelectProps<any, false> {
	onMenuInputFocus: (focused: boolean) => void;
}

const SourceIconMap = {
	[CrmIntegrationFieldDomainType.SALESFORCE]: salesforce,
	[CrmIntegrationFieldDomainType.SALESFORCE_CONSTANT]: salesforce,
	[CrmIntegrationFieldDomainType.BENEFIT_FLOW]: bf_logo_new,
};

const GroupHeaderIcon = ({ domainType }) => {
	if (domainType === CrmIntegrationFieldDomainType.CUSTOM_CONSTANT) {
		return <MdEdit className={cn('mx-2')} size={20} />;
	}

	return <img className={cn('img-xxxs', 'mx-2')} src={SourceIconMap?.[domainType]} />;
};

export const DomainGroupHeader = ({ data }) => {
	const domainType = data?.field_domain_type;
	const toggleCollapse = () => {
		document.querySelector(`.group-${domainType}`).classList.toggle('group-open');
	};

	return (
		<div
			onClick={toggleCollapse}
			className={cn('custom-select__group-heading', 'd-flex', 'align-items-center', 'justify-content-between', 'py-2', 'pe-2', 'bg-light')}
		>
			<div>
				<GroupHeaderIcon domainType={domainType} />
				<span className={cn('text-dark')}>{data?.label}</span>
			</div>
			<FaChevronRight className={cn('chevron-indicator')} size={14} />
		</div>
	);
};

export const GroupMenuList = (props) => {
	const domainType = props?.data?.field_domain_type;
	const selectedDomain = props?.selectProps?.fieldObjectDomainType;
	return (
		<components.Group
			{...props}
			className={cn('collapsible-group', {
				[`group-${domainType}`]: !!domainType,
				'group-open': domainType === selectedDomain,
			})}
		/>
	);
};

const CustomInputOption = ({ selectProps, ...props }: OptionProps<CrmObjectFieldOption, false>) => {
	const { onMenuInputFocus, onChange: setField, value } = selectProps as CustomSelectProps;
	const [customValue, setCustomValue] = useState<string>(value?.value || '');
	const disableSave = !customValue || customValue === value?.value;

	const setFieldMapping = () => {
		if (disableSave) {
			onMenuInputFocus(false);
			return;
		}
		const newValue = {
			name: 'userDefinedField',
			value: customValue,
			field_domain_type: CrmIntegrationFieldDomainType.CUSTOM_CONSTANT,
			type: 'Custom Constant',
		};
		setField(newValue, { action: 'select-option', option: newValue });
	};

	return (
		<div className={cn('custom-select__option')}>
			<div className={cn('custom-input-option')}>
				<input
					type="text"
					name="customValue"
					id="customCrmValue"
					autoCorrect="off"
					autoComplete="off"
					placeholder={'Enter Custom Value'}
					value={customValue}
					onChange={(e) => setCustomValue(e?.target?.value)}
					onKeyDown={(e) => {
						if (e.code === 'Enter') {
							setFieldMapping();
						}
						e.stopPropagation();
					}}
					onFocus={() => onMenuInputFocus(true)}
					onMouseDown={(e) => {
						e.stopPropagation();
						const targetElement = e.target as HTMLInputElement;
						targetElement?.focus();
					}}
					onTouchEnd={(e) => {
						e.stopPropagation();
						const targetElement = e.target as HTMLInputElement;
						targetElement?.focus();
					}}
				/>
				<FaArrowRightFromBracket className={cn('enter-action', { disabled: disableSave })} onClick={setFieldMapping} />
			</div>
		</div>
	);
};

export const CustomOptions = (props: OptionProps<CrmObjectFieldOption, false>) => {
	const domainType: CrmIntegrationFieldDomainType = props?.data?.field_domain_type;

	if (domainType === CrmIntegrationFieldDomainType.CUSTOM_CONSTANT) {
		return <CustomInputOption {...props} />;
	}

	return <components.Option className={cn('ps-4', 'py-2')} {...props} />;
};
