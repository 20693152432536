import { Box, Tooltip, Typography } from '@benefitflow/designsystem';
import { ReactElement, ReactNode, useState } from 'react';

/**
 * This component is used to render a text with tooltip if the text is overflowed.
 */
export const TextOverflowTooltip = ({
	label,
	children,
}: {
	label: ReactNode;
	children: (overflowedCallback: (overflowed: boolean) => void) => ReactElement;
}) => {
	const [isEllipsed, setIsEllipsed] = useState(false);

	return (
		<Tooltip
			behavior="hover"
			hidden={!isEllipsed}
			strategy="fixed"
			placement="bottom-start"
			content={
				typeof label === 'string' ? (
					<Box m="xs">
						<Typography.Text size="sm" weight="regular" color="neutral-700">
							{label}
						</Typography.Text>
					</Box>
				) : (
					<>{label}</>
				)
			}
		>
			{(ref) => (
				<Box containerRef={ref} width="full" overflow="hidden" display="flex" justifyContent="start" alignItems="center" gap="xxs">
					{children(setIsEllipsed)}
				</Box>
			)}
		</Tooltip>
	);
};
