import { Select } from '@benefitflow/designsystem';

import { MaxYears } from '../YearsAtCompany.types';

export const End = ({ setValue, value }: { value: number; setValue: (value: number) => void }) => {
	return (
		<Select testId="years-at-company-filter-end-input" value={value} onChange={(e) => setValue(Number(e.target.value))}>
			{MaxYears.map(({ label, value }) => (
				<Select.Option key={value} value={value}>
					{label}
				</Select.Option>
			))}
		</Select>
	);
};
